import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { LexicalEditorProvider } from "@components/editor/LexicalEditorContext"
import {
  EDITOR_CONFIG,
  LexicalConfigType,
  LexicalEditorUsageData,
} from "@components/editor/config/LexicalConfig"
import { getNodesFromConfig } from "@components/editor/config/LexicalNodes"
import LexicalTheme, {
  useLexicalThemeStyles,
} from "@components/editor/config/LexicalTheme"
import { LexicalUtils } from "@components/editor/plugins/LexicalUtils"
import AttachBlockPlugin from "@components/editor/plugins/attach-block/AttachBlockPlugin"
import BlockActionsPlugin from "@components/editor/plugins/block-actions/BlockActionsPlugin"
import BlockToolboxPlugin from "@components/editor/plugins/block-toolbox/BlockToolboxPlugin"
import ButtonPlugin from "@components/editor/plugins/button/ButtonPlugin"
import { EditorButtonLinkTo } from "@components/editor/plugins/button/EditorButton"
import { CalloutPlugin } from "@components/editor/plugins/callout/CalloutPlugin"
import CodeHighlightPlugin from "@components/editor/plugins/code/CodeHighlightPlugin"
import { EmbedKind } from "@components/editor/plugins/embeds/EmbedNode"
import EmbedPlugin from "@components/editor/plugins/embeds/EmbedPlugin"
import ImagePlugin from "@components/editor/plugins/image/ImagePlugin"
import AutoLinkPlugin from "@components/editor/plugins/links/AutoLinkPlugin"
import FloatingLinkEditorPlugin from "@components/editor/plugins/links/FloatingLinkEditorPlugin"
import ListMaxIndentLevelPlugin from "@components/editor/plugins/lists/ListMaxIndentLevelPlugin"
import MarkdownPlugin from "@components/editor/plugins/markdown/MarkdownPlugin"
import { EditorMentionData } from "@components/editor/plugins/mentions/EditorMention"
import EditorMentionsProvider, {
  getInitialStateFromMentions,
} from "@components/editor/plugins/mentions/EditorMentionsProvider"
import MentionsPlugin from "@components/editor/plugins/mentions/MentionsPlugin"
import { EditorMentionsProviderFragment$key } from "@components/editor/plugins/mentions/__generated__/EditorMentionsProviderFragment.graphql"
import FilePastePlugin from "@components/editor/plugins/paste/FilePastePlugin"
import { PollPlugin } from "@components/editor/plugins/poll/PollPlugin"
import TableActionMenuPlugin from "@components/editor/plugins/table/TableActionMenuPlugin"
import TableCellResizerPlugin from "@components/editor/plugins/table/TableCellResizerPlugin"
import TextPlaceholderPlugin from "@components/editor/plugins/text-placeholder/TextPlaceholderPlugin"
import TextToolbarPlugin from "@components/editor/plugins/text-toolbar/TextToolbarPlugin"
import PreserveParagraphPlugin from "@components/editor/plugins/utils/PreserveParagraphPlugin"
import VideoPlugin from "@components/editor/plugins/video/VideoPlugin"
import { DiscoText } from "@disco-ui"
import { $convertFromMarkdownString, TRANSFORMERS } from "@lexical/markdown"
import { CheckListPlugin } from "@lexical/react/LexicalCheckListPlugin"
import LexicalClickableLinkPlugin from "@lexical/react/LexicalClickableLinkPlugin"
import { LexicalComposer } from "@lexical/react/LexicalComposer"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { ContentEditable } from "@lexical/react/LexicalContentEditable"
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary"
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin"
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin"
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin"
import { ListPlugin } from "@lexical/react/LexicalListPlugin"
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin"
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin"
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin"
import { TablePlugin } from "@lexical/react/LexicalTablePlugin"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  EditorState,
  LexicalEditor as LexicalEditorType,
  SerializedEditorState,
} from "lexical"
import { MutableRefObject, useEffect, useMemo, useState } from "react"
import { v4 as uuidv4 } from "uuid"

export type EditorInstance = LexicalEditorType

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error: any) {
  console.error(error)
}

type LexicalEditorProps = {
  className?: string
  readOnly?: boolean
  placeholder?: string
  /* Whether to show text placeholder on all new lines */
  placeholderOnNewLines?: boolean
  defaultMentions?: Omit<EditorMentionData, "id">[]
  mentionsContentKey?: EditorMentionsProviderFragment$key | null
  defaultValue?: string | null
  defaultEmbedKind?: EmbedKind | null
  buttonLinkTo?: EditorButtonLinkTo
  onChange?: (value: string) => void
  config?: LexicalConfigType
  editorUsageData?: LexicalEditorUsageData
  onMount?: LexicalChildProps["onMount"]
  markdown?: string | null
  addBlockButtonsAnchorRef?: MutableRefObject<HTMLDivElement | null>
  disableMediaModal?: boolean
  disableScroll?: boolean
} & Partial<StyleProps> &
  TestIDProps

function LexicalEditor({
  testid,
  className,
  readOnly = false,
  placeholder = "Write something or type '/' for commands...",
  placeholderOnNewLines = false,
  config = "default",
  mentionsContentKey,
  defaultValue,
  defaultEmbedKind,
  buttonLinkTo,
  backgroundColor,
  minHeight,
  textColor,
  fontSize,
  disableGutter,
  disableMarginRight,
  showOutline = false,
  hasError,
  variant = "default",
  editorUsageData = {},
  onMount,
  markdown,
  addBlockButtonsAnchorRef,
  disableMediaModal,
  disableScroll,
  ...props
}: LexicalEditorProps) {
  const defaultMentions = useDefaultMentions(props.defaultMentions)
  const { blocks, inlineTools } = EDITOR_CONFIG[config]

  const editorState = getInitialState()

  const theme = useTheme()

  const nodes = useMemo(
    () => getNodesFromConfig({ blocks, inlineTools }),
    [blocks, inlineTools]
  )

  const classes = useStyles({
    readOnly,
    backgroundColor,
    minHeight,
    textColor,
    fontSize,
    disableGutter,
    showOutline,
    disableMarginRight,
    hasError,
    variant,
    disableScroll,
  })

  const themeClasses = useLexicalThemeStyles()

  function onChange(state: EditorState) {
    if (props.onChange) {
      const updatedState = JSON.stringify(state.toJSON())
      props.onChange(updatedState)
    }
  }

  const [editorAnchorElem, setEditorAnchorElem] = useState<HTMLDivElement>()
  const onRef = (_editorAnchorElem: HTMLDivElement) => {
    if (_editorAnchorElem !== null) {
      setEditorAnchorElem(_editorAnchorElem)
    }
  }

  return (
    <LexicalComposer
      initialConfig={{
        namespace: "DiscoEditor",
        theme: LexicalTheme,
        nodes,
        editable: !readOnly,
        onError,
        editorState,
      }}
    >
      <LexicalEditorProvider
        config={{ blocks, inlineTools }}
        editorUsageData={editorUsageData}
        disableMediaModal={disableMediaModal}
      >
        <LexicalChild onMount={onMount} />
        <EditorMentionsProvider
          defaultMentions={defaultMentions}
          contentKey={mentionsContentKey}
        >
          <div data-testid={testid} className={classNames(classes.editor, className)}>
            <div ref={onRef} className={classes.contentWrapper}>
              <RichTextPlugin
                contentEditable={
                  <ContentEditable
                    id={"editor-content-editable"}
                    className={classNames(classes.contentEditable, themeClasses.root)}
                  />
                }
                placeholder={(isEditable) => {
                  if (!isEditable) return null
                  return (
                    <DiscoText
                      color={
                        theme.palette.type === "dark"
                          ? "groovy.onDark.200"
                          : "groovy.neutral.400"
                      }
                      className={classes.placeholder}
                    >
                      {placeholder}
                    </DiscoText>
                  )
                }}
                ErrorBoundary={LexicalErrorBoundary}
              />
            </div>
          </div>
          {/* Block Plugins */}
          {blocks.has("list") && (
            <>
              <ListPlugin />
              <CheckListPlugin />
              <ListMaxIndentLevelPlugin maxDepth={6} />
            </>
          )}
          {blocks.has("divider") && <HorizontalRulePlugin />}
          {inlineTools.has("link") && (
            <>
              <LinkPlugin validateUrl={LexicalUtils.validateUrl} />
              <AutoLinkPlugin />
              {readOnly && <LexicalClickableLinkPlugin newTab={false} />}
              {editorAnchorElem && !readOnly && (
                <FloatingLinkEditorPlugin anchorElem={editorAnchorElem} />
              )}
            </>
          )}
          {inlineTools.has("code") && <CodeHighlightPlugin />}
          {blocks.has("table") && (
            <>
              <TablePlugin />
              <TableCellResizerPlugin />
              <TableActionMenuPlugin anchorElem={editorAnchorElem} />
            </>
          )}
          {blocks.has("callout") && <CalloutPlugin />}
          {blocks.has("image") && <ImagePlugin />}
          {blocks.has("video") && <VideoPlugin />}
          {blocks.has("poll") && <PollPlugin />}
          {blocks.has("embed") && <EmbedPlugin />}
          {blocks.has("button") && <ButtonPlugin />}
          {blocks.has("attachBlock") && <AttachBlockPlugin />}

          {/* Toolbar Plugins */}
          {editorAnchorElem && !readOnly && (
            <>
              <BlockToolboxPlugin
                anchorElem={editorAnchorElem}
                buttonLinkTo={buttonLinkTo}
                defaultEmbedKind={defaultEmbedKind}
                addBlockButtonsAnchorRef={addBlockButtonsAnchorRef}
              />
              <BlockActionsPlugin anchorElem={editorAnchorElem} />
              <TextToolbarPlugin anchorElem={editorAnchorElem} />
              {placeholderOnNewLines && (
                <TextPlaceholderPlugin
                  anchorElem={editorAnchorElem}
                  placeholder={placeholder}
                />
              )}
            </>
          )}
          {/* Utility Plugins */}

          {(inlineTools.has("userMention") || inlineTools.has("attachBlockMention")) && (
            <MentionsPlugin
              hasUserMention={inlineTools.has("userMention")}
              hasAttachBlockMention={inlineTools.has("attachBlockMention")}
            />
          )}
          <FilePastePlugin />
          <MarkdownPlugin />
          <HistoryPlugin />
          <TabIndentationPlugin />
          <PreserveParagraphPlugin />
          <OnChangePlugin onChange={onChange} />
        </EditorMentionsProvider>
      </LexicalEditorProvider>
    </LexicalComposer>
  )

  function getInitialState() {
    if (markdown) return () => $convertFromMarkdownString(markdown, TRANSFORMERS)
    if (!defaultMentions.length) {
      if (defaultValue) {
        const defaultState = JSON.parse(defaultValue) as SerializedEditorState
        // Ensure the default state root node has children
        if (defaultState?.root?.children?.length > 0) {
          return (editor: EditorInstance) => {
            const state = editor.parseEditorState(defaultState)
            editor.setEditorState(state)
          }
        }
      }

      // Initialize the editor with an empty paragraph node
      return () => {
        const root = $getRoot()
        const paragraph = $createParagraphNode()
        const text = $createTextNode("")
        paragraph.append(text)
        root.append(paragraph)
      }
    }

    // If default mentions are provided and the `mention` inline tool is
    // enabled, create the initial editor state from them.
    const areMentionsEnabled =
      inlineTools.has("attachBlockMention") || inlineTools.has("userMention")
    if (!areMentionsEnabled)
      throw new Error(
        "You must use an editor configuration that supports `mention` to use the `defaultMentions` prop."
      )
    return getInitialStateFromMentions(defaultMentions)
  }
}

export interface LexicalChildProps {
  /** Run when the lexical editor gets mounted */
  onMount?: (editor: EditorInstance) => void
}

function LexicalChild({ onMount }: LexicalChildProps) {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (onMount) {
      onMount(editor)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

function useDefaultMentions(mentions?: Omit<EditorMentionData, "id">[]) {
  // Generate a unique id for each mention.
  const [defaultMentions] = useState<EditorMentionData[]>(
    mentions?.map((mention) => ({
      id: Relay.toGlobalId("ContentMention", uuidv4()),
      ...mention,
    })) || []
  )

  return defaultMentions
}

interface StyleProps {
  readOnly: boolean
  backgroundColor?: string
  textColor?: string
  fontSize?: string
  minHeight?: number
  disableGutter?: boolean
  showOutline?: boolean
  disableMarginRight?: boolean
  hasError?: boolean
  variant?: "default" | "input"
  disableScroll?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  editor: (props: StyleProps) => {
    const fontSize = props.fontSize
      ? props.fontSize.endsWith("px")
        ? props.fontSize
        : `${props.fontSize}px`
      : theme.typography["body-md"].fontSize

    return {
      backgroundColor: props.backgroundColor || theme.palette.background.paper,
      color: props.textColor || theme.palette.text.primary,
      borderRadius: theme.measure.borderRadius.default,
      minHeight: props.minHeight,
      height: "100%",
      width: "100%",
      ...styleIf(fontSize, {
        "& p span, & blockquote span, & li span": {
          fontSize,
          lineHeight: 1.4,
        },
      }),
      ...styleIf(props.showOutline, {
        border: `1px dashed ${theme.palette.groovy.grey[200]}`,
        padding: theme.spacing(2),
      }),
      // "input" variant matches the styles of DiscoInput
      ...styleIf(props.variant === "input", {
        paddingLeft: "3px",
        borderRadius: theme.measure.borderRadius.big,
        border: "1.5px solid transparent",
        backgroundColor:
          theme.palette.type === "dark"
            ? theme.palette.groovy.onDark[500]
            : theme.palette.groovy.neutral[100],
        ...styleIf(!props.readOnly, {
          "&:focus-within": {
            backgroundColor: theme.palette.background.paper,
            boxShadow:
              theme.palette.type === "dark"
                ? `0 0 0 1.5px ${theme.palette.primary.main}`
                : `0 0 0 1.5px ${theme.palette.primary.main}, 0 0 0 5px ${theme.palette.primary.light}`,
          },
          "&:hover:not(:focus-within)": {
            backgroundColor:
              theme.palette.type === "dark"
                ? theme.palette.groovy.onDark[400]
                : theme.palette.groovy.neutral[200],
            boxShadow: `0 0 0 1.5px ${theme.palette.groovy.grey[400]}`,
          },
        }),
        ...styleIf(props.hasError, {
          border: `1.5px solid ${theme.palette.error.dark} !important`,
          boxShadow: "none !important",
        }),
      }),
    }
  },
  contentWrapper: {
    display: "grid",
    position: "relative",
    height: "100%",
    width: "100%",
  },
  contentEditable: {
    height: "100%",
    width: "100%",
    outline: "none",
    overflowX: (props: StyleProps) => (props.disableScroll ? "hidden" : "auto"),
    overflowY: (props: StyleProps) => (props.disableScroll ? "hidden" : "auto"),
    paddingLeft: (props: StyleProps) => (props.disableGutter ? 0 : theme.spacing(8)),
    paddingRight: (props: StyleProps) =>
      props.disableGutter || props.disableMarginRight ? 0 : theme.spacing(4),
    paddingTop: (props: StyleProps) => (props.readOnly ? 0 : theme.spacing(1)),
    paddingBottom: (props: StyleProps) => (props.readOnly ? 0 : theme.spacing(1)),

    [theme.breakpoints.down("xs")]: {
      paddingLeft: (props: StyleProps) => (props.disableGutter ? 0 : theme.spacing(5.5)),
      paddingRight: (props: StyleProps) =>
        props.disableGutter || props.disableMarginRight ? 0 : theme.spacing(2),
    },
  },
  placeholder: {
    position: "absolute",
    top: theme.spacing(1.5),
    left: theme.spacing(8),
    pointerEvents: "none",

    [theme.breakpoints.down("xs")]: {
      left: theme.spacing(5.5),
    },
  },
}))

export default LexicalEditor
