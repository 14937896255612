import { AssetFileType } from "@/admin/media-library/__generated__/AdminMediaLibraryListPagePaginationQuery.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import MediaLibraryButton, {
  MediaLibraryButtonProps,
} from "@/media/add/MediaLibraryButton"
import styleIf from "@assets/style/util/styleIf"
import CircularProgressWithLabel from "@components/circular-progress-with-label/CircularProgressWithLabel"
import WithEntitlement from "@components/entitlement/WithEntitlement"
import ImageCropModal from "@components/image/crop-modal/ImageCropModal"
import useMultipartUploadMediaToS3, {
  MediaResult,
} from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { DiscoButton, DiscoIcon, DiscoText } from "@disco-ui"
import { Grid, lighten, useTheme } from "@material-ui/core"
import {
  convertFileToBase64,
  getFileExtensionsFromAccept,
  urlToFile,
} from "@utils/file/fileUtils"
import classNames from "classnames"
import { JssStyle } from "jss"
import React, { MouseEventHandler, useRef, useState } from "react"
import { CropperProps } from "react-advanced-cropper"
import { DropzoneOptions, FileError, FileRejection, useDropzone } from "react-dropzone"

export type FileDropzoneProps = {
  onChange?(files: File[]): Promise<void>
  onUpload?: (result: MediaResult, file: File) => void
  /** Use this if you want the features of the dropzone but
   * still want to do something else when clicked on
   */
  onClick?: MouseEventHandler<Element>
  onMediaSelect?: (result: MediaResult) => void
  message?: React.ReactElement | string
  icon?: React.ReactNode
  dropzoneOptions: DropzoneOptions
  className?: string
  testid?: string
  suggestedDimensions?: FileDropzoneSuggestedDimensions
  enforceSuggestedDimensions?: boolean
  style?: React.CSSProperties
  variant?: FileDropzoneVariant
  showSupportedFiles?: boolean
  showBorderOnHover?: boolean
  messageIcon?: React.ReactNode
  title?: string
  cropperProps?: Omit<CropperProps, "crop" | "ref">
  privateObject?: boolean
  temporary?: boolean
  buttonText?: React.ReactElement | string
  allowedFileTypes?: AssetFileType[]
  disabled?: boolean
  parentSelector?: () => HTMLElement
  /** Hides ability to pick an asset from the media library when uploading. Will just open native file selector */
  hideMediaLibrary?: boolean
  /** Determines whether the organization_id on the asset created should have an organization_id on it or not */
  includeOrganizationIdOnAsset?: boolean
  /** Determines if the is_visible flag is set on the asset to potentially hide the asset from the media library */
  includeInMediaLibrary?: boolean
  allowedMimeTypes?: MimeTypes[]
  mediaLibraryButtonProps?: Partial<MediaLibraryButtonProps>
}

export type FileDropzoneSuggestedDimensions = { width: number; height?: number }

type FileDropzoneVariant =
  | "default"
  | "disco-blue"
  | "disco-blue-small"
  | "icon"
  | "upload-icon"

type VariantSettings = {
  variant: FileDropzoneVariant
  messageIcon: React.ReactNode
  showSupportedFiles: boolean
  message: React.ReactElement | string
}

export type MimeTypes = keyof typeof ALL_FILE_TYPES

export const STATIC_IMAGE_FILE_TYPES = {
  "image/png": [".png"],
  "image/jpeg": [".jpg", "jpeg"],
}

/* eslint-disable complexity */
export const IMAGE_FILE_TYPES = {
  "image/png": [".png"],
  "image/jpeg": [".jpg", "jpeg"],
  "image/gif": [".gif"],
}
export const VIDEO_FILE_TYPES = { "video/mp4": [".mp4"] }
export const IMAGE_AND_VIDEO_FILE_TYPES = {
  ...IMAGE_FILE_TYPES,
  ...VIDEO_FILE_TYPES,
}
export const ZIP_FILE_TYPES = {
  "application/zip": [".zip"],
  "application/x-zip-compressed": [".zip"],
}
export const DOCUMENT_FILE_TYPES = {
  "text/csv": [".csv"],
  "text/plain": [".txt"],
  "text/rtf": [".rtf"],
  "text/markdown": [".md"],
  "application/pdf": [".pdf"],
  "application/vnd.ms-powerpoint": [".ppt"],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
  "application/vnd.ms-excel": [".xls"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
}
export const SUBTITLE_FILE_TYPES = {
  "text/vtt": [".vtt"],
  "application/x-subrip": [".srt"],
}
export const PDF_FILE_TYPES = {
  "application/pdf": [".pdf"],
}
export const AI_GENERATION_REFERENCE_TYPES = {
  "application/pdf": [".pdf"],
  "video/mp4": [".mp4"],
}
export const ALL_FILE_TYPES = {
  ...IMAGE_FILE_TYPES,
  ...VIDEO_FILE_TYPES,
  ...DOCUMENT_FILE_TYPES,
  ...ZIP_FILE_TYPES,
  ...SUBTITLE_FILE_TYPES,
}

export const initialCropModalState = {
  isOpen: false,
  imgSrc: "",
  file: undefined as File | undefined,
}

const FileDropzone: React.FC<FileDropzoneProps> = ({
  onChange,
  onUpload,
  onClick,
  message = "Drag files or click to upload",
  icon,
  dropzoneOptions,
  className,
  style,
  testid,
  suggestedDimensions,
  enforceSuggestedDimensions,
  variant = "default",
  showSupportedFiles,
  showBorderOnHover = true,
  messageIcon,
  title,
  cropperProps,
  privateObject,
  temporary,
  buttonText,
  onMediaSelect,
  allowedFileTypes,
  disabled,
  parentSelector,
  hideMediaLibrary,
  includeOrganizationIdOnAsset = true,
  includeInMediaLibrary,
  allowedMimeTypes,
  mediaLibraryButtonProps: customMediaLibraryButtonProps,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const { uploadMediaToS3, uploadProgress } = useMultipartUploadMediaToS3({
    privateObject: Boolean(privateObject),
    temporary: Boolean(temporary),
    includeOrganizationId: includeOrganizationIdOnAsset,
  })
  const [uploadError, setUploadError] = useState<string | null>(null)
  const [isProcessing, setIsProcessing] = useState(false)
  const hasNotStartedUpload = uploadProgress == null

  const activeOrganization = useActiveOrganization()

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...dropzoneOptions,
    // Don't ask...
    // https://github.com/react-dropzone/react-dropzone/issues/1191#issuecomment-1121245916
    useFsAccessApi: false,
    onDrop: dropzoneOptions.onDrop || handleDrop,
  })
  const [cropModalState, setCropModalState] = useState(initialCropModalState)

  const classes = useStyles({
    isDragActive,
    hasError: Boolean(uploadError),
    isIcon: Boolean(icon),
    variant,
    disabled: Boolean(disabled),
    showBorderOnHover: Boolean(showBorderOnHover),
  })
  const theme = useTheme()
  const variantSettings = getVariantSettings()

  const showMediaButton =
    !hideMediaLibrary &&
    onUpload &&
    onMediaSelect &&
    variantSettings.variant === "default" &&
    activeOrganization?.viewerPermissions.has("assets.read")

  async function wrappedOnMediaSelect(selectedMedia: MediaResult) {
    if (!(await checkImageScaling({ selectedMedia }))) return
    if (onMediaSelect) {
      onMediaSelect(selectedMedia)
    }
  }

  const rootProps = getRootProps()
  const mediaLibraryButtonProps: Omit<MediaLibraryButtonProps, "children"> = {
    ...customMediaLibraryButtonProps,
    testid,
    dropzoneOptions,
    cropperProps,
    suggestedDimensions,
    enforceSuggestedDimensions,
    message,
    allowedFileTypes,
    disabled,
    onUpload: onUpload!,
    onMediaSelect: wrappedOnMediaSelect!,
    className: classes.button,
    PopoverProps: {
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    },
    includeInMediaLibrary,
    allowedMimeTypes,
  }

  return (
    <div
      data-testid={testid ? `${testid}.wrapper` : ""}
      className={classNames(classes.dropzone, classes[variant], className)}
    >
      <div
        {...(disabled ? [] : { ...rootProps, onClick: onClick ?? rootProps.onClick })}
        className={classes.dropzoneRoot}
      />
      {showMediaButton &&
        /* The button cannot be a child of the FileDropzone root, or else click events
      cause infnite re-renders which have been a pain in the ass to debug. Feel free to try
       but for now this works! */
        (hasNotStartedUpload ? (
          <div className={classes.mediaButtonsContainer}>
            <MediaLibraryButton {...mediaLibraryButtonProps}>
              {"Select Media"}
            </MediaLibraryButton>
            {allowedFileTypes?.includes("image") && (
              <WithEntitlement entitlement={"ai_image_generation"}>
                {({ hasEntitlement }) => (
                  <MediaLibraryButton
                    {...mediaLibraryButtonProps}
                    defaultTab={"ai-image"}
                    rightIcon={"stars"}
                    disabled={!hasEntitlement}
                    testid={`${testid}.generate-button`}
                  >
                    {"Generate"}
                  </MediaLibraryButton>
                )}
              </WithEntitlement>
            )}
          </div>
        ) : (
          <CircularProgressWithLabel
            color={theme.palette.primary.main}
            value={uploadProgress}
          />
        ))}
      <div className={classes.infoContainer} style={style}>
        {/* Error message or prompt to upload */}
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          wrap={"wrap"}
          className={classes.messageContainer}
        >
          {variantSettings.messageIcon && <Grid item>{variantSettings.messageIcon}</Grid>}
          {title && (
            <DiscoText
              variant={"body-lg-600"}
              className={classNames(classes.textColor, classes.titleText)}
            >
              {title}
            </DiscoText>
          )}
          {variantSettings.message && hasNotStartedUpload && (
            <DiscoText
              className={classNames(classes.paragraphText, classes.textColor, {
                [classes.errorText]: Boolean(uploadError),
              })}
            >
              {uploadError || message}
            </DiscoText>
          )}
        </Grid>
        {/* List of supported file types when set */}
        {variantSettings.showSupportedFiles &&
          dropzoneOptions.accept &&
          hasNotStartedUpload && (
            <DiscoText
              variant={"body-sm"}
              className={classNames(classes.paragraphText, classes.textColor)}
            >
              {`Supported files: ${getFileExtensionsFromAccept(dropzoneOptions.accept)}`}
            </DiscoText>
          )}
        {/* Suggested Dimensions */}
        {suggestedDimensions && hasNotStartedUpload && (
          <DiscoText
            marginTop={0.5}
            variant={"body-sm"}
            fontStyle={"italic"}
            className={classNames(classes.dimensionsText, classes.textColor)}
          >
            {`Ideal dimensions - ${suggestedDimensions.width}px ${
              suggestedDimensions.height ? `x ${suggestedDimensions.height}px` : "wide"
            }`}
          </DiscoText>
        )}
        {/* Display icon as button or text button instead of drag and drop*/}
        {renderButton()}
        {/* Invisible input to handle actual file upload */}
        <input
          ref={(e) => (inputRef.current = e)}
          style={{ display: "none" }}
          data-testid={`${testid}.FileDropzone.input`}
          {...getInputProps()}
        />
        {cropperProps && cropModalState.isOpen && (
          <ImageCropModal
            isOpen={cropModalState.isOpen}
            onClose={() => setCropModalState(initialCropModalState)}
            imageSrc={cropModalState.imgSrc}
            cropperProps={cropperProps}
            onCrop={handleCrop}
            parentSelector={parentSelector}
          />
        )}
      </div>
    </div>
  )

  function getImageSize(url: string): Promise<{ width: number; height: number }> {
    return new Promise((resolve, reject) => {
      const img = new Image()

      img.addEventListener("load", () => {
        resolve({ width: img.naturalWidth, height: img.naturalHeight })
      })

      img.addEventListener("error", (event) => {
        reject(new Error(`${event.type}: ${event.message}`))
      })

      img.src = url
    })
  }

  async function checkImageScaling(props: {
    selectedMedia?: MediaResult
    selectedFile?: File
    base64?: string
  }): Promise<boolean> {
    const { selectedMedia, selectedFile, base64 } = props
    if (!suggestedDimensions || !enforceSuggestedDimensions) return true
    if (!selectedMedia && !selectedFile && !base64) {
      setUploadError(`Missing image source, please try again later.`)
      return false
    }

    let dimensions: { width: number; height: number }

    if (base64) {
      dimensions = await getImageSize(`data:image/jpeg;base64,${base64}`)
    } else if (selectedMedia) {
      // If selecting an image from media, download and check the image size
      dimensions = await getImageSize(selectedMedia.url)
    } else {
      dimensions = await getImageSize(await convertFileToBase64(selectedFile))
    }

    // If uploading an image for the first time, check image size
    if (dimensions.width > suggestedDimensions.width) {
      setUploadError(
        `Selected image too large, must be a maximum of ${suggestedDimensions.width}px wide.`
      )
      return false
    } else if (
      suggestedDimensions.height &&
      dimensions.height > suggestedDimensions.height
    ) {
      setUploadError(
        `Selected image too large, must be a maximum of ${suggestedDimensions.height}px high.`
      )
      return false
    }
    return true
  }

  async function handleDrop(acceptedFiles: File[], rejectedFiles: FileRejection[]) {
    setUploadError(null)
    if (rejectedFiles.length > 0) {
      const error = rejectedFiles[0]?.errors[0]
      setUploadError(DROPZONE_ERROR_TO_MESSAGE[error?.code] || "Invalid file")
      return
    }

    setIsProcessing(true)
    if (onChange) {
      onChange(acceptedFiles).finally(() => {
        setIsProcessing(false)
      })
    }

    if (onUpload && acceptedFiles[0]) {
      try {
        if (cropperProps && acceptedFiles[0].type !== "image/gif") {
          const base64 = await convertFileToBase64(acceptedFiles[0])
          setCropModalState({
            isOpen: true,
            imgSrc: base64,
            file: acceptedFiles[0],
          })
          return
        }

        if (!(await checkImageScaling({ selectedFile: acceptedFiles[0] }))) return
        const result = await uploadMediaToS3({
          mediaFile: acceptedFiles[0],
          includeInMediaLibrary,
        })
        onUpload(result, acceptedFiles[0])
      } catch (error) {
        setUploadError("Upload failed, please try again later.")
      } finally {
        setIsProcessing(false)
      }
    }
  }

  async function handleCrop(base64: string) {
    if (!(await checkImageScaling({ base64 }))) return

    const result = await uploadMediaToS3({
      mediaFile: await urlToFile(
        base64,
        `${cropModalState.file!.name}-cropper-image`,
        cropModalState.file!.type
      ),
      includeInMediaLibrary,
    })
    // we don't show cropModal unless we also provide onUpload, but ts compiler doesn't know that
    if (!onUpload) return
    onUpload(result, cropModalState.file!)
  }

  function renderButton() {
    if (showMediaButton) return null
    if (variant === "default" || buttonText) {
      return (
        <DiscoButton
          className={classes.button}
          // Don't show the generic spinner if upload progress is available
          shouldDisplaySpinner={hasNotStartedUpload && isProcessing}
        >
          {hasNotStartedUpload ? (
            buttonText || "Click to upload"
          ) : (
            <CircularProgressWithLabel color={"white"} value={uploadProgress} />
          )}
        </DiscoButton>
      )
    }
    if (variantSettings.variant === "icon") {
      return hasNotStartedUpload ? (
        <div>{icon}</div>
      ) : (
        <CircularProgressWithLabel
          color={theme.palette.primary.main}
          value={uploadProgress}
        />
      )
    }
    if (variantSettings.variant === "upload-icon" && !hasNotStartedUpload) {
      return (
        <CircularProgressWithLabel
          color={theme.palette.primary.main}
          value={uploadProgress}
        />
      )
    }
    return null
  }

  // Derive the variant settings from the component props
  function getVariantSettings(): VariantSettings {
    switch (variant) {
      case "disco-blue":
      case "disco-blue-small":
        return {
          variant,
          messageIcon: messageIcon ?? (
            <DiscoIcon
              icon={"camera"}
              height={20}
              color={theme.palette.groovy.blue[400]}
            />
          ),
          message,
          showSupportedFiles: showSupportedFiles ?? false,
        }
      case "icon":
        return {
          variant: "icon",
          messageIcon: false,
          message: "",
          showSupportedFiles: false,
        }
      case "upload-icon":
        return {
          variant: "upload-icon",
          messageIcon: messageIcon ?? <DiscoIcon icon={"upload"} height={20} />,
          message,
          showSupportedFiles: showSupportedFiles ?? false,
        }
      default:
        return {
          variant,
          messageIcon,
          message,
          showSupportedFiles: showSupportedFiles ?? true,
        }
    }
  }
}

interface StyleProps {
  isDragActive: boolean
  hasError: boolean
  isIcon: boolean
  variant: FileDropzoneProps["variant"]
  disabled: boolean
  showBorderOnHover: boolean
}

const useStyles = makeUseStyles((theme) => ({
  dropzone: (props: StyleProps) => ({
    width: "100%",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    zIndex: 0,
    ...styleIf(props.hasError, {
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
      backgroundColor: "unset",
    }),
    ...styleIf(props.isDragActive, {
      borderColor: theme.palette.primary.main,
    }),
    "&:hover": {
      borderColor: props.disabled
        ? undefined
        : props.showBorderOnHover
        ? theme.palette.primary.main
        : undefined,
      cursor: props.disabled ? "default" : "pointer",
    },
  }),
  messageContainer: {
    display: "flex",
    flexDirection: "column",
  },
  button: ({ isDragActive }: StyleProps) => ({
    width: "auto",
    padding: theme.spacing(3, 3),
    margin: theme.spacing(2, 0),
    ...styleIf(isDragActive, {
      pointerEvents: "none",
    }),
  }),
  // variants
  default: {
    padding: theme.spacing(3, 4),
    border: "2px dashed",
    borderRadius: theme.measure.borderRadius.medium,
    borderColor: theme.palette.constants.divider,
    backgroundColor: "transparent",
  },
  icon: {
    padding: theme.spacing(3, 4),
    border: "1px dashed",
    borderRadius: theme.measure.borderRadius.medium,
    borderColor: theme.palette.constants.divider,
    backgroundColor: "transparent",
  },
  "disco-blue": {
    padding: theme.spacing(2, 1),
    border: "1px dashed",
    borderRadius: "16px",
    borderColor: lighten(theme.palette.primary.main, 0.1),
    backgroundColor: theme.palette.primary.light,
  },
  "disco-blue-small": {
    padding: "unset",
    border: "1px dashed",
    borderRadius: "16px",
    borderColor: lighten(theme.palette.primary.main, 0.1),
    backgroundColor: theme.palette.primary.light,
  },
  "upload-icon": {
    padding: theme.spacing(2, 1),
    border: "2px dashed",
    borderRadius: theme.measure.borderRadius.big,
    borderColor: theme.palette.constants.divider,
  },
  paragraphText: (props: StyleProps) =>
    styleIf(props.variant === "disco-blue-small" || props.variant === "disco-blue", {
      fontSize: "10px",
      lineHeight: "12px",
      width: "100%",
    }) as JssStyle,
  dimensionsText: (props: StyleProps) =>
    styleIf(props.variant === "upload-icon", {
      fontSize: "12px",
      lineHeight: "12px",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        fontSize: "11px",
        lineHeight: "11px",
      },
    }) as JssStyle,

  titleText: (props: StyleProps) =>
    props.variant === "disco-blue-small"
      ? {
          fontSize: "12px",
          lineHeight: "12px",
        }
      : {
          paddingLeft: theme.spacing(1.25),
        },
  textColor: (props: StyleProps) => {
    return props.variant === "disco-blue-small" || props.variant === "disco-blue"
      ? {
          color: theme.palette.groovy.blue[400],
        }
      : {
          color: theme.palette.text.secondary,
        }
  },
  errorText: {
    color: `${theme.palette.error.main} !important`,
  },
  dropzoneRoot: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
  },
  infoContainer: {
    zIndex: -1,
  },
  mediaButtonsContainer: {
    gap: theme.spacing(1.5),
    display: "flex",
    zIndex: theme.zIndex.raise1,
  },
}))

export default FileDropzone

/** Maps error codes from react-dropzone to a user-friendly message */
const DROPZONE_ERROR_TO_MESSAGE: Record<FileError["code"], string> = {
  "file-invalid-type": "The file type is invalid",
  "file-too-large": "The file is too large",
  "too-many-files": "Over the maximum file limit",
  "file-too-small": "The file is too small",
}
/* eslint-enable complexity */
