import { $isAtNodeEnd } from "@lexical/selection"
import { ElementNode, RangeSelection, TextNode } from "lexical"

export namespace LexicalUtils {
  export function getSelectedNode(selection: RangeSelection): TextNode | ElementNode {
    const { anchor, focus } = selection
    const anchorNode = selection.anchor.getNode()
    const focusNode = selection.focus.getNode()
    if (anchorNode === focusNode) {
      return anchorNode
    }
    const isBackward = selection.isBackward()
    if (isBackward) {
      return $isAtNodeEnd(focus) ? anchorNode : focusNode
    }
    return $isAtNodeEnd(anchor) ? anchorNode : focusNode
  }

  export function getDOMRangeRect(
    nativeSelection: Selection,
    rootElement: HTMLElement
  ): DOMRect {
    const domRange = nativeSelection.getRangeAt(0)

    let rect

    if (nativeSelection.anchorNode === rootElement) {
      let inner = rootElement
      while (inner.firstElementChild !== null) {
        inner = inner.firstElementChild as HTMLElement
      }
      rect = inner.getBoundingClientRect()
    } else {
      rect = domRange.getBoundingClientRect()
    }

    return rect
  }

  const VERTICAL_GAP = 10
  const HORIZONTAL_OFFSET = 5

  export function setFloatingElemPosition(
    targetRect: ClientRect | null,
    floatingElem: HTMLElement,
    anchorElem: HTMLElement,
    opts?: { verticalGap?: number; horizontalOffset?: number; isLinkEditor?: boolean }
  ): void {
    const verticalGap = opts?.verticalGap ?? VERTICAL_GAP
    const horizontalOffset = opts?.horizontalOffset ?? HORIZONTAL_OFFSET

    const scrollerElem = anchorElem.parentElement

    if (targetRect === null || !scrollerElem) {
      floatingElem.style.opacity = "0"
      floatingElem.style.transform = "translate(-10000px, -10000px)"
      return
    }

    const floatingElemRect = floatingElem.getBoundingClientRect()
    const anchorElementRect = anchorElem.getBoundingClientRect()
    const editorScrollerRect = scrollerElem.getBoundingClientRect()

    let top =
      targetRect.top - verticalGap - (opts?.isLinkEditor ? 0 : floatingElemRect.height)
    let left = targetRect.left - horizontalOffset

    if (top < editorScrollerRect.top) {
      top += floatingElemRect.height + targetRect.height + verticalGap * 2
    }

    if (left + floatingElemRect.width > editorScrollerRect.right) {
      left = editorScrollerRect.right - floatingElemRect.width - horizontalOffset
    }

    top -= anchorElementRect.top
    left -= anchorElementRect.left

    floatingElem.style.opacity = "1"
    floatingElem.style.transform = `translate(${left}px, ${top}px)`
  }

  const urlRegExp = new RegExp(
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/
  )

  export const EDITOR_LINK_PLACEHOLDER = "about:blank"

  export function validateUrl(url: string): boolean {
    // Allow the insertion of the placeholder link
    if (url === EDITOR_LINK_PLACEHOLDER) return true

    try {
      const parsedUrl = new URL(url)
      const urlString = parsedUrl.toString()
      return urlRegExp.test(urlString)
    } catch {
      return false
    }
  }
}
