import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { getEventFilters } from "@/organization/occurrence/OrganizationOccurrenceList"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { AttachBlockEntity } from "@components/editor/plugins/attach-block/AttachBlockNode"
import { AttachBlockToolbarState } from "@components/editor/plugins/attach-block/AttachBlockSelectModal"
import AttachBlockEventModalListItem from "@components/editor/plugins/attach-block/types/event/AttachBlockEventModalListItem"
import { AttachBlockEventSearchBodyPaginationQuery } from "@components/editor/plugins/attach-block/types/event/__generated__/AttachBlockEventSearchBodyPaginationQuery.graphql"
import { AttachBlockEventSearchBodyQuery } from "@components/editor/plugins/attach-block/types/event/__generated__/AttachBlockEventSearchBodyQuery.graphql"
import { AttachBlockEventSearchBody_PaginationFragment$key } from "@components/editor/plugins/attach-block/types/event/__generated__/AttachBlockEventSearchBody_PaginationFragment.graphql"
import { DiscoButton, DiscoSection, DiscoText, DiscoTooltip } from "@disco-ui"
import { Skeleton } from "@material-ui/lab"
import { TestIDProps } from "@utils/typeUtils"
import pluralize from "pluralize"
import { useEffect } from "react"
import { graphql, useLazyLoadQuery, usePaginationFragment } from "react-relay"

interface AttachBlockEventSearchBodyProps extends TestIDProps {
  onClose: VoidFunction
  toolbarState: AttachBlockToolbarState
  onEventSelect?: (attachBlockEntity: AttachBlockEntity, entityId: string) => void
  inline: boolean
}

function AttachBlockEventSearchBody({
  onClose,
  onEventSelect,
  toolbarState,
  inline,
}: AttachBlockEventSearchBodyProps) {
  const activeOrganization = useActiveOrganization()!
  const classes = useStyles()
  const numberOfSearchResults = 10

  const { datetimeFilter, hasRecording } = getEventFilters({
    filter: toolbarState.eventFilter,
  })

  const { organization } = useLazyLoadQuery<AttachBlockEventSearchBodyQuery>(
    graphql`
      query AttachBlockEventSearchBodyQuery(
        $id: ID!
        $first: Int
        $datetimeFilter: OrganizationOccurrencesDatetimeFilter
        $hasRecording: Boolean
        $after: String
        $search: String
      ) {
        organization: node(id: $id) {
          ... on Organization {
            ...AttachBlockEventSearchBody_PaginationFragment
              @arguments(
                first: $first
                after: $after
                search: $search
                datetimeFilter: $datetimeFilter
                hasRecording: $hasRecording
              )
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
      search: toolbarState.search,
      first: numberOfSearchResults,
      datetimeFilter,
      hasRecording,
    }
  )

  const { data, loadNext, hasNext, isLoadingNext, refetch } = usePaginationFragment<
    AttachBlockEventSearchBodyPaginationQuery,
    AttachBlockEventSearchBody_PaginationFragment$key
  >(
    graphql`
      fragment AttachBlockEventSearchBody_PaginationFragment on Organization
      @refetchable(queryName: "AttachBlockEventSearchBodyPaginationQuery")
      @argumentDefinitions(
        first: { type: "Int" }
        after: { type: "String" }
        search: { type: "String" }
        hasRecording: { type: "Boolean" }
        datetimeFilter: { type: "OrganizationOccurrencesDatetimeFilter" }
      ) {
        id
        occurrences(
          first: $first
          after: $after
          search: $search
          datetimeFilter: $datetimeFilter
          hasRecording: $hasRecording
        ) @connection(key: "AttachBlockEventSearchBody_occurrences") {
          __id
          totalCount
          edges {
            node {
              id
              ...AttachBlockEventModalListItem_OccurrenceFragment
            }
          }
        }
      }
    `,
    organization
  )
  const occurrences = Relay.connectionToArray(data?.occurrences)

  useEffect(() => {
    refetch(
      {
        first: numberOfSearchResults,
        search: toolbarState.search,
        datetimeFilter,
        hasRecording,
      },
      { fetchPolicy: "store-and-network" }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, toolbarState.eventFilter, toolbarState.search])

  function renderMoreCount() {
    if (!data?.occurrences.totalCount) return null
    if (data.occurrences.totalCount <= occurrences.length) return null

    const totalMoreResults = data.occurrences.totalCount - occurrences.length

    const handleLoadMore = () => {
      if (hasNext && !isLoadingNext) {
        loadNext(numberOfSearchResults)
      }
    }

    if (isLoadingNext) return <AttachBlockEventSearchBodySkeleton />

    return (
      <DiscoTooltip
        content={`(${totalMoreResults} more ${pluralize("event", totalMoreResults)})`}
      >
        <div className={classes.loadMoreContainer}>
          <DiscoButton
            color={"grey"}
            variant={"outlined"}
            onClick={handleLoadMore}
            disabled={isLoadingNext}
          >
            {"Load more"}
          </DiscoButton>
        </div>
      </DiscoTooltip>
    )
  }

  return (
    <DiscoSection className={classes.searchHits}>
      {occurrences && occurrences.length > 0 ? (
        occurrences.map((occurrence) => (
          <AttachBlockEventModalListItem
            key={occurrence.id}
            inline={inline}
            occurrenceKey={occurrence}
            onClose={handleClose}
            onEventSelect={onEventSelect}
          />
        ))
      ) : (
        <DiscoText className={classes.empty} variant={"body-sm"} color={"text.secondary"}>
          {"No events"}
        </DiscoText>
      )}
      {renderMoreCount()}
    </DiscoSection>
  )

  function handleClose() {
    onClose()
  }
}

function AttachBlockEventSearchBodySkeleton() {
  const classes = useStyles()

  return (
    <DiscoSection className={classes.searchHits}>
      <Skeleton
        className={classes.searchHit}
        variant={"text"}
        width={"95%"}
        height={80}
      />
      <Skeleton
        className={classes.searchHit}
        variant={"text"}
        width={"95%"}
        height={80}
      />
      <Skeleton
        className={classes.searchHit}
        variant={"text"}
        width={"95%"}
        height={80}
      />
      <Skeleton
        className={classes.searchHit}
        variant={"text"}
        width={"95%"}
        height={80}
      />
      <Skeleton
        className={classes.searchHit}
        variant={"text"}
        width={"95%"}
        height={80}
      />
    </DiscoSection>
  )
}

const useStyles = makeUseStyles((theme) => ({
  empty: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(1),
  },
  searchHits: {
    maxHeight: "250px",
    overflowY: "auto",
    padding: 0,
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
  },
  loadMoreContainer: {
    cursor: "pointer",
    textAlign: "center",
    padding: theme.spacing(1),
  },
  searchHit: {
    margin: theme.spacing(1),
  },
}))

export default Relay.withSkeleton({
  component: AttachBlockEventSearchBody,
  skeleton: AttachBlockEventSearchBodySkeleton,
})
