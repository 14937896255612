import { GlobalID } from "@/relay/RelayTypes"
import { TextFormatType } from "lexical"

export type EditorNodeType =
  | "paragraph"
  | "heading"
  | "list"
  | "quote"
  | "callout"
  | "code"
  | "divider"
  | "table"
  | "image"
  | "video"
  | "embed"
  | "button"
  | "ai"
  | "attachBlock"
  | "poll"

export type EditorInlineTool =
  | TextFormatType
  | "link"
  | "color"
  | "alignment"
  | "attachBlockMention"
  | "userMention"

export type LexicalConfig = {
  blocks: Set<EditorNodeType>
  inlineTools: Set<EditorInlineTool>
}

export type LexicalEditorUsageData = {
  // The content the lexical editor is being rendered in
  contentId?: GlobalID
  // The content usage the lexical editor is being rendered in
  contentUsageId?: GlobalID
  // The module the content is being created in
  moduleContentUsageId?: GlobalID
}

export type LexicalConfigType =
  | "default"
  | "comment"
  | "email"
  | "automation_email"
  | "automation_slack"
  | "automation_chat"
  | "question"
  | "web_form_question"
  | "basic_formatting"
  | "headless"
  | "content"
  | "post"
  | "confirmation_modal"
  | "video_summary"

const CONTENT_EDITOR_CONFIG: LexicalConfig = {
  blocks: new Set([
    "paragraph",
    "heading",
    "list",
    "quote",
    "callout",
    "code",
    "divider",
    "table",
    "image",
    "video",
    "embed",
    "ai",
    "attachBlock",
  ]),
  inlineTools: new Set([
    "link",
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "code",
    "color",
    "alignment",
    "attachBlockMention",
  ]),
}

export const EDITOR_CONFIG: Record<LexicalConfigType, LexicalConfig> = {
  default: {
    blocks: new Set([
      "paragraph",
      "heading",
      "list",
      "quote",
      "callout",
      "code",
      "divider",
      "table",
      "image",
      "video",
      "embed",
      "ai",
      "attachBlock",
    ]),
    inlineTools: new Set([
      "link",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "code",
      "color",
      "alignment",
    ]),
  },
  content: CONTENT_EDITOR_CONFIG,
  post: {
    blocks: new Set([...CONTENT_EDITOR_CONFIG.blocks, "poll"]),
    inlineTools: CONTENT_EDITOR_CONFIG.inlineTools,
  },
  confirmation_modal: {
    blocks: new Set(["paragraph", "list"]),
    inlineTools: new Set(["link", "bold", "italic", "alignment", "underline"]),
  },
  comment: {
    blocks: new Set(["paragraph", "list", "image"]),
    inlineTools: new Set(["link", "bold", "italic", "attachBlockMention", "userMention"]),
  },
  email: {
    blocks: new Set(["paragraph", "list", "image", "ai"]),
    inlineTools: new Set(["link", "bold", "italic", "alignment"]),
  },
  automation_email: {
    blocks: new Set(["paragraph", "list", "image", "button", "ai"]),
    inlineTools: new Set(["link", "bold", "italic", "alignment"]),
  },
  automation_slack: {
    blocks: new Set(["paragraph", "list", "image", "ai"]),
    inlineTools: new Set(["link", "bold", "italic"]),
  },
  automation_chat: {
    blocks: new Set(["paragraph", "list", "image", "video", "ai"]),
    inlineTools: new Set(["link", "bold", "italic"]),
  },
  basic_formatting: {
    blocks: new Set(["paragraph", "list", "image"]),
    inlineTools: new Set([
      "link",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "color",
    ]),
  },
  question: {
    blocks: new Set(["paragraph"]),
    inlineTools: new Set(["link", "bold", "italic"]),
  },
  web_form_question: {
    blocks: new Set(["paragraph", "list"]),
    inlineTools: new Set(["link", "bold", "italic"]),
  },
  headless: {
    blocks: new Set([
      "paragraph",
      "heading",
      "list",
      "quote",
      "callout",
      "code",
      "divider",
      "table",
      "image",
      "video",
      "embed",
      "button",
      "ai",
      "attachBlock",
    ]),
    inlineTools: new Set(["link", "code", "userMention", "attachBlockMention"]),
  },
  video_summary: {
    blocks: new Set(["paragraph", "ai"]),
    inlineTools: new Set(["link", "bold", "italic"]),
  },
}
