import { INTEGRATION_EMBED_PLACEHOLDER } from "@/apps/util/appUtil"
import WistiaLogo from "@/core/ui/disco/icons/color-logo/logo-wistia.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { EmbedConfig } from "@components/editor/plugins/embeds/EmbedNode"
import EmbedReactEditorToolFormLabel from "@components/editor/plugins/embeds/EmbedReactEditorToolFormLabel"
import { GENERICIFRAME_REGEX } from "@components/editor/plugins/embeds/types/EditorGenericIframeEmbed"
import { DiscoFormControl, DiscoInput, DiscoText } from "@disco-ui"

const EditorWistiaEmbed: EmbedConfig = {
  title: "Wistia",
  icon: <WistiaLogo height={18} width={18} />,
  keywords: ["wistia", "embed"],
  validateSetup: (data) => {
    if (!GENERICIFRAME_REGEX.test(data.html || ""))
      return new Error("Please provide a valid iFrame code.")
    return null
  },
  resizeData: null,
  Component({ data }) {
    const classes = useStyles()
    if (!data.html || !GENERICIFRAME_REGEX.test(data.html)) return null
    return (
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: data.html }}
        className={classes.iframeContainer}
      />
    )
  },
  SetupForm({ data, setData }) {
    return (
      <DiscoFormControl
        label={
          <EmbedReactEditorToolFormLabel
            icon={<WistiaLogo height={18} width={18} />}
            label={"Wistia Embed Code"}
          />
        }
      >
        <DiscoInput
          placeholder={INTEGRATION_EMBED_PLACEHOLDER.wistia}
          value={data.html}
          onChange={handleChangeHtml}
          multiline
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </DiscoFormControl>
    )

    function handleChangeHtml(e: React.ChangeEvent<HTMLInputElement>) {
      setData({ html: e.target.value })
    }
  },
  SetupFormHelp() {
    return (
      <DiscoText variant={"body-sm-500"} color={"text.secondary"}>
        {
          "Only the Fallback (iframe) inline embed type is supported, which can be selected under Advanced Settings. We also recommend enabling the Responsive Sizing setting."
        }
      </DiscoText>
    )
  },
}

const useStyles = makeUseStyles({
  iframeContainer: {
    width: "100%",
    height: "100%",
  },
})

export default EditorWistiaEmbed
