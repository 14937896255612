import makeUseStyles from "@assets/style/util/makeUseStyles"
import { $isButtonNode } from "@components/editor/plugins/button/ButtonNode"
import {
  DiscoButton,
  DiscoTooltip,
  useDiscoButtonColorStyle,
  useDiscoButtonStyles,
} from "@disco-ui"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { $getNodeByKey, NodeKey } from "lexical"
import { useEffect, useRef } from "react"

export type EditorButtonLinkTo = "dashboard" | "home" | "profile"
export type EditorButtonComponent = "cta_button"

interface EditorButtonProps extends TestIDProps {
  nodeKey: NodeKey
  text: string
  linkTo: EditorButtonLinkTo
  component: EditorButtonComponent
}

function EditorButton({ nodeKey, text, linkTo }: EditorButtonProps) {
  const DiscoButtonColor = useDiscoButtonColorStyle("primary")
  const colorStyle = DiscoButtonColor
  const isMobile = useIsMobile()

  const [editor] = useLexicalComposerContext()

  const classes = useStyles()
  const buttonStyles = useDiscoButtonStyles({
    colorStyle,
    width: "max-content",
    color: "primary",
    variant: "contained",
    noHover: false,
    hasText: true,
    noPadding: false,
    size: "medium",
    leftIconOnly: isMobile,
  })

  const buttonRef = useRef<HTMLDivElement>(null)

  const handleTextChange = (e: React.ChangeEvent<HTMLDivElement>) => {
    if (!e.currentTarget.textContent) return
    if (!buttonRef.current) return

    const newText = e.currentTarget.textContent

    editor.update(() => {
      const node = $getNodeByKey(nodeKey)
      if ($isButtonNode(node)) {
        node.setText(newText)
      }
    })
  }

  useEffect(() => {
    if (text && buttonRef.current) {
      buttonRef.current.textContent = text
    }
  }, [text])

  return <div className={classes.buttonWrapper}>{renderContent()}</div>

  function renderContent() {
    if (!editor.isEditable()) {
      return <DiscoButton>{text}</DiscoButton>
    }

    return (
      <DiscoTooltip
        // FUTURE: make this smart and show relevant product or community dashboard URL
        content={getTooltipContent()}
      >
        <div
          ref={buttonRef}
          contentEditable
          className={classNames(classes.button, buttonStyles.root)}
          onBlur={handleTextChange}
        />
      </DiscoTooltip>
    )
  }

  function getTooltipContent() {
    switch (linkTo) {
      case "profile":
        return "This will link to the user's profile"
      case "dashboard":
      default:
        return "This will link to the dashboard"
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  button: {
    ...theme.typography["body-md"],
    ...theme.typography.modifiers.fontWeight[600],
  },
}))

export default EditorButton
