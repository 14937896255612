import { INTEGRATION_URL_PLACEHOLDER } from "@/apps/util/appUtil"
import AirtableLogo from "@/core/ui/images/logos/airtable.svg"
import { EmbedConfig } from "@components/editor/plugins/embeds/EmbedNode"
import EmbedReactEditorToolFormLabel from "@components/editor/plugins/embeds/EmbedReactEditorToolFormLabel"
import { DiscoFormControl, DiscoInput, DiscoLink, DiscoText } from "@disco-ui"

const AIRTABLE_REGEX = /https:\/\/airtable\.com\/.*(shr\w+).*$/i
const AIRTABLE_ID_MATCH_INDEX = 1

const EditorAirtableEmbed: EmbedConfig = {
  title: "Airtable",
  icon: <AirtableLogo height={18} width={18} />,
  keywords: ["airtable", "embed", "database"],
  validateSetup: (data) => {
    if (!AIRTABLE_REGEX.test(data.url || ""))
      return new Error("Please provide a valid Airtable share URL")
    return null
  },
  resizeData: {
    defaultHeight: "530px",
    width: "100%",
  },
  Component({ data }) {
    const matches = data.url?.match(AIRTABLE_REGEX)
    const airtableId = matches && matches[AIRTABLE_ID_MATCH_INDEX]

    if (!airtableId || !data.url) return null

    const qsParams = new URL(data.url).searchParams
    const height = qsParams.get("height") ?? "100%"
    const width = qsParams.get("width") ?? "100%"

    return (
      <iframe
        src={`https://airtable.com/embed/${airtableId}`}
        title={"Airtable"}
        frameBorder={"0"}
        allow={"autoplay; fullscreen; picture-in-picture"}
        height={height}
        width={width}
      />
    )
  },
  SetupForm({ data, setData }) {
    return (
      <DiscoFormControl
        label={
          <EmbedReactEditorToolFormLabel
            icon={<AirtableLogo height={18} width={18} />}
            label={"Airtable Sharing Link"}
          />
        }
      >
        <DiscoInput
          placeholder={INTEGRATION_URL_PLACEHOLDER.airtable}
          value={data.url}
          onChange={handleChangeUrl}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </DiscoFormControl>
    )

    function handleChangeUrl(e: React.ChangeEvent<HTMLInputElement>) {
      setData({ url: e.target.value })
    }
  },
  SetupFormHelp() {
    return (
      <DiscoText variant={"body-sm-500"} color={"text.secondary"}>
        {"Follow the "}
        <DiscoLink
          textVariant={"body-sm-500"}
          href={"https://support.airtable.com/docs/embedding-a-view-or-base"}
          target={"_blank"}
          rel={"noreferrer noopener"}
        >
          {"instructions in the Airtable Support Center"}
        </DiscoLink>
        {" to get an embed link for your Airtable."}
      </DiscoText>
    )
  },
}

export default EditorAirtableEmbed
