import { EmbedNode } from "@components/editor/plugins/embeds/EmbedNode"
import { UPDATE_EMBED_COMMAND } from "@components/editor/plugins/embeds/EmbedPlugin"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { TestIDProps } from "@utils/typeUtils"

interface EmbedBlockOptionsProps extends TestIDProps {
  embedNode: EmbedNode
}

function EmbedBlockOptions({ embedNode }: EmbedBlockOptionsProps) {
  const [editor] = useLexicalComposerContext()

  return (
    <DiscoDropdownItem
      testid={"EmbedBlockOptions.dropdown.edit"}
      onClick={openSettings}
      title={"Edit Embed"}
      icon={"settings"}
    />
  )

  function openSettings() {
    editor.dispatchCommand(UPDATE_EMBED_COMMAND, {
      key: embedNode.getKey(),
    })
  }
}

export default EmbedBlockOptions
