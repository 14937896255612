import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { AttachBlockEntity } from "@components/editor/plugins/attach-block/AttachBlockNode"
import { INSERT_ATTACH_BLOCK_COMMAND } from "@components/editor/plugins/attach-block/AttachBlockPlugin"
import { DiscoButton } from "@disco-ui"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { useTheme } from "@material-ui/core"

interface AttachBlockSelectModalListItemProps {
  attachBlockEntity: AttachBlockEntity
  entityId: GlobalID
  onClose: VoidFunction
  children: React.ReactNode
  name: string | null
  onSelect?: (attachBlockEntity: AttachBlockEntity, entityId: string) => void
  inline: boolean
}

function useOptionalLexicalComposerContext() {
  try {
    const [editor] = useLexicalComposerContext() // Will throw error if not in lexical editor context
    return editor
  } catch {
    return null
  }
}

function AttachBlockSelectModalListItem({
  attachBlockEntity,
  entityId,
  onClose,
  children,
  name,
  onSelect,
  inline,
}: AttachBlockSelectModalListItemProps) {
  const classes = useStyles({ attachBlockEntity })

  const theme = useTheme()
  const editor = useOptionalLexicalComposerContext()

  return (
    <DiscoButton
      key={entityId}
      data-testid={`attach-block-list-item.${name}`}
      className={classes.item}
      customButtonColor={{
        color: theme.palette.groovy.neutral[400],
        backgroundColor: theme.palette.background.paper,
        hover: {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.groovy.neutral[200],
        },
      }}
      onClick={handleAdd}
    >
      {children}
    </DiscoButton>
  )
  function handleAdd() {
    if (inline) return

    onClose()

    if (onSelect) {
      onSelect(attachBlockEntity, entityId)
    }
    if (editor) {
      editor.dispatchCommand(INSERT_ATTACH_BLOCK_COMMAND, {
        attachBlockEntity,
        entityId,
      })
    }
  }
}

type StyleProps = {
  attachBlockEntity?: AttachBlockEntity
}

const useStyles = makeUseStyles((theme) => ({
  item: {
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    height: ({ attachBlockEntity }: StyleProps) =>
      attachBlockEntity === "product" ? theme.spacing(5) : theme.spacing(7),
    width: "100%",
    justifyContent: "flex-start",
  },
}))

export default AttachBlockSelectModalListItem
