/**
 * @generated SignedSource<<2a786e91a55eac135d20d7cfa605f91e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentUsageEntity = "content" | "collection" | "curriculum" | "member_onboarding" | "product_app" | "dashboard_block_content" | "organization" | "content_usage_confirmation" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AttachBlockContentUsageModalListItem_ContentUsageFragment$data = {
  readonly id: string;
  readonly entity: ContentUsageEntity;
  readonly viewerPermissions: ReadonlyArray<string>;
  readonly content: {
    readonly name: string | null;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ContentThumbnail_ContentFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ContentUsageUtils_useContentUsageLocationLabelContentUsageFragment">;
  readonly " $fragmentType": "AttachBlockContentUsageModalListItem_ContentUsageFragment";
};
export type AttachBlockContentUsageModalListItem_ContentUsageFragment$key = {
  readonly " $data"?: AttachBlockContentUsageModalListItem_ContentUsageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AttachBlockContentUsageModalListItem_ContentUsageFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AttachBlockContentUsageModalListItem_ContentUsageFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerPermissions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ContentThumbnail_ContentFragment"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentUsageUtils_useContentUsageLocationLabelContentUsageFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};
})();

(node as any).hash = "6fe97252b3f6f1bb6bb830c089a6e41d";

export default node;
