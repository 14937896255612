import AiApi from "@/common/AiApi"
import GenerateContentWithAIForm, {
  GenerateContentWithAISubmitProps,
} from "@/content/ai/GenerateContentWithAIForm"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import RestfulUtil from "@/core/restful/RestfulUtil"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { useLexicalEditorContext } from "@components/editor/LexicalEditorContext"
import { useEditorElementAnchor } from "@components/editor/utils/useEditorElementAnchor"
import {
  displayErrorToast,
  displayRestfulErrorToast,
} from "@components/toast/ToastProvider"
import { useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import { $convertFromMarkdownString, TRANSFORMERS } from "@lexical/markdown"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { Popover } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { $createParagraphNode, $getSelection } from "lexical"
import { observer } from "mobx-react-lite"
import { useRef } from "react"

interface EditorGenerateAITextPopoverProps extends TestIDProps {
  isOpen: boolean
  onClose: VoidFunction
  anchorElem: HTMLElement
}

function EditorGenerateAITextPopover({
  isOpen,
  onClose,
  anchorElem,
  testid = "EditorGenerateAITextPopover",
}: EditorGenerateAITextPopoverProps) {
  const [params, setParams] = useQueryParamState<{ brIds?: string }>()
  const [editor] = useLexicalComposerContext()
  const { editorUsageData } = useLexicalEditorContext() // NOTE: This component must be used within the editor!
  const activeOrganization = useActiveOrganization()!
  const activeProduct = useActiveProduct()
  const popoverAnchorRef = useRef<HTMLDivElement | null>(null)
  const classes = useStyles()

  useEditorElementAnchor({
    editorAnchorElem: anchorElem,
    elementAnchorRef: popoverAnchorRef,
  })

  return (
    <>
      <div ref={popoverAnchorRef} className={classes.anchor} />
      {isOpen && popoverAnchorRef.current && (
        <Popover
          open={isOpen}
          onClose={onClose}
          anchorEl={anchorElem}
          getContentAnchorEl={null}
          keepMounted={Boolean(popoverAnchorRef.current)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          classes={{ paper: classes.paper }}
          data-testid={`${testid}.popover`}
        >
          <GenerateContentWithAIForm
            onSubmit={handleSubmit}
            testid={`${testid}.form`}
            hideReferenceKinds={[
              "asset",
              "content",
              "training_data",
              "upload",
              "web_search",
            ]} // Don't show these reference kinds for now
            moduleContentUsageId={editorUsageData.moduleContentUsageId}
          />
        </Popover>
      )}
    </>
  )

  async function handleSubmit(context: GenerateContentWithAISubmitProps) {
    const response = await AiApi.generateText({
      organizationId: activeOrganization.id,
      productId: activeProduct?.id,
      responseType: "description",
      ...context,
    })

    const hasError = RestfulUtil.hasError(response)
    if (hasError) {
      await displayRestfulErrorToast(response)
      return
    }

    editor.focus(() => {
      editor.update(async () => {
        onClose() // Close the popover and then begin piping response into editor if no errors

        const selection = $getSelection()
        if (!selection) return

        const paragraphNode = $createParagraphNode()
        selection.insertNodes([paragraphNode])

        if (!response.ok || !response.body) return

        let generatedDescription = ""
        await RestfulUtil.handleStream(
          response.body,
          (decodedChunk) => {
            generatedDescription += decodedChunk
            editor.update(() =>
              $convertFromMarkdownString(
                generatedDescription,
                TRANSFORMERS,
                paragraphNode
              )
            )
            return true
          },
          {
            onEnd: (data) => {
              // For any request to generate text or an image via AI (entire content generation or using /write with AI in the editor)
              // append the returned botResponseId to an array in the query params. Then when we submit, extract the botResponseIds
              // from the query params and submit it in the botResponseIds field when we are creating/updating any content/content usage
              // this param can be used to link bot reponses to other entities as well in the future

              const decodedBotResponseIds = params.brIds
                ? JSON.parse(decodeURIComponent(params.brIds))
                : undefined

              setParams({
                brIds: encodeURIComponent(
                  JSON.stringify(
                    decodedBotResponseIds
                      ? [...decodedBotResponseIds, data.botResponseId]
                      : [data.botResponseId]
                  )
                ),
              })
            },
          }
        )

        if (!generatedDescription.trim()) {
          displayErrorToast("Unable to generate anything based on the prompt")
        }
      })
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  anchor: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  paper: {
    padding: theme.spacing(2),
    width: "600px",
    maxWidth: "50vw",
    borderRadius: theme.measure.borderRadius.medium,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "95vw",
    },
  },
}))

export default observer(EditorGenerateAITextPopover)
