import UploadMediaModal, { MEDIA_LIBRARY_TABS } from "@/media/add/UploadMediaModal"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { IMAGE_FILE_TYPES } from "@components/dropzone/FileDropzone"
import { INSERT_IMAGE_COMMAND } from "@components/editor/plugins/image/ImagePlugin"
import { useEditorElementAnchor } from "@components/editor/utils/useEditorElementAnchor"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useRef } from "react"

interface EditorImageUploadModalProps extends TestIDProps {
  isOpen: boolean
  onClose: VoidFunction
  anchorElem: HTMLElement
  defaultTab?: MEDIA_LIBRARY_TABS
}

function EditorImageUploadModal({
  isOpen,
  onClose,
  anchorElem,
  defaultTab = "my-files",
}: EditorImageUploadModalProps) {
  const [editor] = useLexicalComposerContext()

  const modalAnchorRef = useRef<HTMLDivElement | null>(null)

  useEditorElementAnchor({
    editorAnchorElem: anchorElem,
    elementAnchorRef: modalAnchorRef,
  })

  const classes = useStyles()

  return (
    <>
      <div ref={modalAnchorRef} className={classes.anchor} />
      {isOpen && modalAnchorRef.current && (
        <UploadMediaModal
          onClose={onClose}
          onUpload={handleResult}
          onMediaSelect={handleResult}
          dropzoneOptions={{ accept: IMAGE_FILE_TYPES }}
          allowedFileTypes={["image"]}
          defaultTab={defaultTab}
          PopoverProps={{
            anchorEl: modalAnchorRef.current,
            anchorOrigin: {
              vertical: "center",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
        />
      )}
    </>
  )

  function handleResult(result: MediaResult) {
    editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
      altText: result.name,
      src: result.url,
    })
    onClose()
  }
}

const useStyles = makeUseStyles({
  anchor: {
    position: "absolute",
    top: 0,
    left: 0,
  },
})

export default observer(EditorImageUploadModal)
