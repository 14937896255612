import { INTEGRATION_URL_PLACEHOLDER } from "@/apps/util/appUtil"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { EmbedConfig } from "@components/editor/plugins/embeds/EmbedNode"
import EmbedReactEditorToolFormLabel from "@components/editor/plugins/embeds/EmbedReactEditorToolFormLabel"
import { DiscoFormControl, DiscoIcon, DiscoInput, DiscoText } from "@disco-ui"
import ReactDOM from "react-dom"
import QRCode from "react-qr-code"

const EditorQRCodeEmbed: EmbedConfig = {
  title: "QR Code",
  icon: <DiscoIcon icon={"scan-barcode"} height={18} width={18} />,
  keywords: ["qrcode", "embed"],
  resizeData: {
    defaultHeight: "200px",
    width: "100%",
  },
  Component({ data }) {
    const classes = useStyles()

    // Remove the `px` from the height string and remove the
    // additional 2 * `6px` padding from the EditorEmbed component
    const height = parseInt(data.height?.replace("px", "") || "200") - 12

    return (
      <div className={classes.container}>
        <QRCode size={height} value={data.url || ""} className={classes.qrCode} />
      </div>
    )
  },
  SetupForm({ data, setData }) {
    const classes = useStyles()

    return (
      <DiscoFormControl
        label={
          <EmbedReactEditorToolFormLabel
            icon={<DiscoIcon icon={"scan-barcode"} />}
            label={"QR Code URL"}
          />
        }
      >
        <DiscoInput
          placeholder={INTEGRATION_URL_PLACEHOLDER.qr_code}
          value={data.url}
          onChange={handleChangeUrl}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </DiscoFormControl>
    )

    function renderQRCodeHtml() {
      const qrCode = <QRCode value={data.url || ""} className={classes.qrCode} />
      const div = document.createElement("div")
      ReactDOM.render(qrCode, div)
      return div.innerHTML
    }

    function handleChangeUrl(e: React.ChangeEvent<HTMLInputElement>) {
      setData({ url: e.target.value, html: renderQRCodeHtml() })
    }
  },
  SetupFormHelp: () => (
    <DiscoText variant={"body-sm-500"} color={"text.secondary"}>
      {"Enter the URL to generate a QR code."}
    </DiscoText>
  ),
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    padding: theme.spacing(1),
  },
  qrCode: {
    padding: theme.spacing(1),
    border: `4px solid ${theme.palette.common.black}`,
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.measure.borderRadius.big,
  },
}))

export default EditorQRCodeEmbed
