// Reference: https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/plugins/ComponentPickerPlugin/index.tsx
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { EDITOR_BLOCKS, EditorBlockType } from "@components/editor/config/LexicalNodes"
import { DiscoIcon, DiscoIconKinds, DiscoText } from "@disco-ui"
import { MenuOption } from "@lexical/react/LexicalTypeaheadMenuPlugin"
import React from "react"

export class BlockToolboxOption extends MenuOption {
  title: string
  icon?: DiscoIconKinds | React.ReactElement
  keywords: Array<string>
  onSelect: (queryString: string) => void
  badge?: React.ReactElement

  constructor(
    blockType: EditorBlockType,
    options: {
      badge?: React.ReactElement
      title?: string
      icon?: DiscoIconKinds | React.ReactElement
      keywords?: Array<string>
      onSelect: (queryString: string) => void
    }
  ) {
    const title = options.title ?? EDITOR_BLOCKS[blockType].title
    const icon = options.icon ?? EDITOR_BLOCKS[blockType].icon
    const badge = options.badge ?? EDITOR_BLOCKS[blockType].badge
    super(title)
    this.title = title
    this.icon = icon
    this.keywords = options.keywords || []
    this.onSelect = options.onSelect.bind(this)
    this.badge = badge
  }
}

function BlockToolboxItem({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option,
}: {
  index: number
  isSelected: boolean
  onClick: () => void
  onMouseEnter: () => void
  option: BlockToolboxOption
}) {
  const classes = useStyles({ isSelected })

  return (
    <li
      key={option.key}
      ref={option.setRefElement}
      id={`typeahead-item-${index}`}
      data-testid={`BlockToolboxPlugin.popover.item.${option.title.toLowerCase()}`}
      tabIndex={-1}
      className={classes.item}
      role={"option"}
      onKeyDown={handleKeyDown}
      aria-selected={isSelected}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
    >
      {option.badge ? (
        option.badge
      ) : (
        <div className={classes.iconWrapper}>
          {typeof option.icon === "string" ? (
            <DiscoIcon icon={option.icon} height={18} width={18} />
          ) : (
            option.icon
          )}
        </div>
      )}
      <DiscoText variant={"body-sm"}>{option.title}</DiscoText>
    </li>
  )

  function handleKeyDown(e: React.KeyboardEvent<HTMLLIElement>) {
    if (!onClick || (e.key !== "Enter" && e.key !== " ")) return
    option.ref?.current?.click()
  }
}

type StyleProps = {
  isSelected: boolean
}

const useStyles = makeUseStyles((theme) => ({
  item: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
    borderRadius: theme.measure.borderRadius.big,
    padding: theme.spacing(0.5),
    margin: theme.spacing(0, 0.5),

    background: ({ isSelected }: StyleProps) =>
      isSelected ? theme.palette.groovy.neutral[100] : theme.palette.background.paper,

    "& svg": {
      color: ({ isSelected }: StyleProps) =>
        isSelected ? theme.palette.text.primary : theme.palette.constants.icon,
    },
  },
  iconWrapper: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ${theme.palette.groovy.neutral[300]}`,
    borderRadius: theme.measure.borderRadius.default,
    background: theme.palette.background.paper,
    padding: theme.spacing(0.5),
  },
}))

export default BlockToolboxItem
