import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoSelect, DiscoText, SelectOption } from "@disco-ui"
import { getTimezoneDropdownOptions } from "@utils/timezone/timezoneConstants"
import { useMemo } from "react"

export type TimezoneDropdownOption = SelectOption<string, { subtitle: string }>
interface TimezoneDropdownProps {
  testid: string
  selectedOption: TimezoneDropdownOption | null
  onSelect: (option: TimezoneDropdownOption | null) => void
  disabled?: boolean
  className?: string
}

function TimezoneDropdown({
  testid,
  selectedOption,
  onSelect,
  disabled,
  className,
}: TimezoneDropdownProps) {
  const classes = useStyles()

  const options = useMemo(() => getTimezoneDropdownOptions(), [])

  return (
    <DiscoSelect
      testid={testid}
      placeholder={"Select Timezone"}
      disableClearable
      classes={{
        root: className,
        popper: classes.popper,
      }}
      autoWidth
      value={selectedOption!.value}
      onChange={(value) => onSelect(options.find((o) => o.value === value)!)}
      options={options}
      filterOptions={{ maxVisible: 500 }} // No limit to how many options we show
      renderOption={(option) => {
        return (
          <div className={classes.renderOption}>
            <DiscoText>{option.title}</DiscoText>
            <DiscoText color={"text.secondary"}>{option.context!.subtitle}</DiscoText>
          </div>
        )
      }}
      disabled={disabled}
    />
  )
}

const useStyles = makeUseStyles({
  popper: {
    minWidth: "300px !important",
  },
  renderOption: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
})

export default TimezoneDropdown
