import AttachmentListItem, {
  AttachmentListItemProps,
} from "@/content/attachment/list/item/AttachmentListItem"
import { useFormStore } from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import FileDropzone, { ZIP_FILE_TYPES } from "@components/dropzone/FileDropzone"
import { UploadScormFileDropzoneMutation } from "@components/editor/plugins/embeds/scorm/__generated__/UploadScormFileDropzoneMutation.graphql"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { DiscoFormControl } from "@disco-ui"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import { graphql } from "react-relay"

export interface UploadScormFileDropzoneProps {
  onUpload: (scormFileId: GlobalID) => void
  onRemove?: () => void
  disabled?: boolean
}

export const DEFAULT_SCORM_TEXT = {
  uploadDescription:
    "Your SCORM file will need to be proccessed before it can be used. This may take a few minutes.",
}

function UploadScormFileDropzone({
  onUpload,
  onRemove,
  disabled,
}: UploadScormFileDropzoneProps) {
  const [attachment, setAttachment] = useState<
    AttachmentListItemProps["attachment"] | null
  >(null)
  const classes = useStyles()

  const form = useFormStore<UploadScormFileDropzoneMutation>(
    graphql`
      mutation UploadScormFileDropzoneMutation($input: CreateScormFileInput!) {
        response: createScormFile(input: $input) {
          node {
            id
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      zipFileAssetId: "",
    }
  )

  return (
    <DiscoFormControl>
      {attachment ? (
        <AttachmentListItem
          testid={`ZipFile`}
          showDragIcon
          attachment={attachment}
          onClickDelete={handleRemoveZipFile}
          isCompact
        />
      ) : (
        <FileDropzone
          className={classes.dropzone}
          dropzoneOptions={{ accept: ZIP_FILE_TYPES }}
          onUpload={handleUploadZipFile}
          onMediaSelect={handleUploadZipFile}
          message={"Upload your SCORM zip file"}
          testid={"UploadScormFileDropzone"}
          disabled={disabled}
        />
      )}
    </DiscoFormControl>
  )

  async function handleUploadZipFile(result: MediaResult) {
    setAttachment({
      name: result.name,
      mediaUrl: result.url,
    })

    // Take the result (with the assetId) and createScormFile
    const { didSave, response } = await form.submit({
      zipFileAssetId: result.id!,
    })

    // Something went wrong when trying to create the scorm file, make them re-upload
    if (!didSave || !response?.node) {
      handleRemoveZipFile()
    } else {
      onUpload(response.node.id)
    }
  }

  function handleRemoveZipFile() {
    setAttachment(null)
    if (onRemove) onRemove()
  }
}

const useStyles = makeUseStyles({
  dropzone: {
    width: "100%",
  },
})

export default observer(UploadScormFileDropzone)
