/**
 * @generated SignedSource<<94a0e3c2ade0d941d999c503c5bee4ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateScormCompletionInput = {
  scormCompletionId: string;
  scormData: string;
};
export type EditorScormEmbed_updateScormCompletionMutation$variables = {
  input: UpdateScormCompletionInput;
};
export type EditorScormEmbed_updateScormCompletionMutation$data = {
  readonly response: {
    readonly node: {
      readonly id: string;
      readonly scormData: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type EditorScormEmbed_updateScormCompletionMutation = {
  variables: EditorScormEmbed_updateScormCompletionMutation$variables;
  response: EditorScormEmbed_updateScormCompletionMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateScormCompletionResponse",
    "kind": "LinkedField",
    "name": "updateScormCompletion",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ScormCompletion",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "scormData",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditorScormEmbed_updateScormCompletionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditorScormEmbed_updateScormCompletionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "76ee57053c23f4bc2bdcbdd7be5377f5",
    "id": null,
    "metadata": {},
    "name": "EditorScormEmbed_updateScormCompletionMutation",
    "operationKind": "mutation",
    "text": "mutation EditorScormEmbed_updateScormCompletionMutation(\n  $input: UpdateScormCompletionInput!\n) {\n  response: updateScormCompletion(input: $input) {\n    node {\n      id\n      scormData\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f95109e723c2e6e29244982b6deb8adf";

export default node;
