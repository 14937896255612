import { GlobalID } from "@/relay/RelayTypes"
import UploadScormFileDropzone from "@components/editor/plugins/embeds/scorm/UploadScormFileDropzone"
import { DiscoButton, DiscoModal } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"
import { useMemo, useState } from "react"

type Props = {
  children: OverridableDiscoButtonProps["children"]
  onSubmit: (scormFileId: GlobalID) => void
}

function UploadScormFileButton({ children, onSubmit }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [scormFileId, setScormFileId] = useState<GlobalID | null>(null)

  const button = useMemo(() => {
    return (
      <OverridableDiscoButton onClick={handleOpenModal}>
        {children}
      </OverridableDiscoButton>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {button}

      <DiscoModal
        isOpen={isModalOpen}
        modalContentLabel={"Upload scorm file button"}
        testid={"UploadScormFileButton"}
        title={"Upload SCORM File"}
        minHeight={"unset"}
        body={<UploadScormFileDropzone onUpload={handleUpload} onRemove={handleRemove} />}
        buttons={[
          <DiscoButton
            key={"cancel"}
            color={"grey"}
            variant={"outlined"}
            onClick={handleCloseModal}
            testid={"UploadScormFileButton.cancel-button"}
          >
            {"Cancel"}
          </DiscoButton>,
          <DiscoButton
            key={"submit"}
            disabled={!scormFileId}
            onClick={handleSubmit}
            testid={"UploadScormFileButton.submit-button"}
          >
            {"Submit"}
          </DiscoButton>,
        ]}
        onClose={handleCloseModal}
      />
    </>
  )

  function handleSubmit() {
    onSubmit(scormFileId!)
  }

  function handleUpload(id: GlobalID) {
    setScormFileId(id)
  }

  function handleRemove() {
    setScormFileId(null)
  }

  function handleCloseModal() {
    setIsModalOpen(false)
  }
  function handleOpenModal() {
    setIsModalOpen(true)
  }
}

export default UploadScormFileButton
