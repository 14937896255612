import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import EditorEmbedSetupModal from "@components/editor/plugins/embeds/EditorEmbedSetupModal"
import {
  $isEmbedNode,
  EMBED_CONFIG,
  EmbedData,
  EmbedKind,
} from "@components/editor/plugins/embeds/EmbedNode"
import {
  UPDATE_EMBED_COMMAND,
  UpdateEmbedPayload,
} from "@components/editor/plugins/embeds/EmbedPlugin"
import DiscoResizableContainer from "@components/resizable/DiscoResizableContainer"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import useDisclosure from "@utils/hook/useDisclosure"
import usePermissions from "@utils/hook/usePermissions"
import { $getNodeByKey, COMMAND_PRIORITY_EDITOR, NodeKey } from "lexical"
import React, { useEffect } from "react"

interface EditorEmbedProps {
  nodeKey: NodeKey
  kind: EmbedKind
  data: EmbedData
}

function EditorEmbed({ nodeKey, kind, data }: EditorEmbedProps) {
  const { height } = data
  const config = EMBED_CONFIG[kind]
  const [editor] = useLexicalComposerContext()
  const activeOrganization = useActiveOrganization()!
  const permissions = usePermissions(activeOrganization)
  const canManage = permissions.has("content.manage")
  const classes = useStyles()
  const isMobile = useIsMobile()
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    return editor.registerCommand<UpdateEmbedPayload>(
      UPDATE_EMBED_COMMAND,
      (payload) => {
        if (payload.key === nodeKey) onOpen()
        return false
      },
      COMMAND_PRIORITY_EDITOR
    )
  }, [editor, nodeKey, onOpen])

  // Unrecognized block kind - show nothing instead of an error
  if (!config) return null

  const component = React.createElement(config.Component, { nodeKey, data })

  return (
    <>
      {config.resizeData && !(isMobile && config.noResizeOnMobile) ? (
        <DiscoResizableContainer
          initialHeight={height || config.resizeData.defaultHeight}
          width={config.resizeData.width}
          onResizeEnd={handleUpdateBlockHeight}
          disableResize={!canManage}
          className={classes.resizable}
        >
          {/* -8px is needed to account for space that the resize bar takes up. Without this an extra scrollbar shows up */}
          <span
            style={{ height: "calc(100% - 8px)", display: "inline-block", width: "100%" }}
          >
            {component}
          </span>
        </DiscoResizableContainer>
      ) : (
        component
      )}
      {isOpen && (
        <EditorEmbedSetupModal
          kind={kind}
          initialData={data}
          onClose={onClose}
          onSubmit={(payload) => {
            editor.update(() => {
              const node = $getNodeByKey(nodeKey)
              if ($isEmbedNode(node)) {
                node.setData(payload.data)
              }
            })
          }}
        />
      )}
    </>
  )

  function handleUpdateBlockHeight(newHeight: string) {
    editor.update(() => {
      const node = $getNodeByKey(nodeKey)
      if ($isEmbedNode(node)) {
        node.setData({ height: newHeight })
      }
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  resizable: {
    [theme.breakpoints.down("xs")]: {
      maxHeight: "60vh",
      border: theme.palette.constants.borderSmall,
    },
  },
}))

export default EditorEmbed
