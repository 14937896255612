import {
  $createButtonNode,
  ButtonNode,
  ButtonPayload,
} from "@components/editor/plugins/button/ButtonNode"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $insertNodeToNearestRoot, mergeRegister } from "@lexical/utils"
import { COMMAND_PRIORITY_EDITOR, LexicalCommand, createCommand } from "lexical"
import { useEffect } from "react"

export type InsertButtonPayload = Readonly<ButtonPayload>

export const INSERT_BUTTON_COMMAND: LexicalCommand<InsertButtonPayload> = createCommand(
  "INSERT_BUTTON_COMMAND"
)

export default function ButtonPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (!editor.hasNodes([ButtonNode])) {
      throw new Error("ButtonPlugin: ButtonNode not registered on editor")
    }

    return mergeRegister(
      editor.registerCommand<InsertButtonPayload>(
        INSERT_BUTTON_COMMAND,
        (payload) => {
          const buttonNode = $createButtonNode(payload)
          $insertNodeToNearestRoot(buttonNode)
          return true
        },
        COMMAND_PRIORITY_EDITOR
      )
    )
  }, [editor])

  return null
}
