import {
  $createCalloutNode,
  CalloutNode,
} from "@components/editor/plugins/callout/CalloutNode"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $setBlocksType } from "@lexical/selection"
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_LOW,
  createCommand,
} from "lexical"

export const INSERT_CALLOUT_COMMAND = createCommand("insertCallout")

export function CalloutPlugin(): null {
  const [editor] = useLexicalComposerContext()
  if (!editor.hasNodes([CalloutNode])) {
    throw new Error("CalloutPlugin: CalloutNode not registered on editor")
  }
  editor.registerCommand(
    INSERT_CALLOUT_COMMAND,
    () => {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, $createCalloutNode)
      }
      return true
    },
    COMMAND_PRIORITY_LOW
  )
  return null
}
