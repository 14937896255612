import { INTEGRATION_URL_PLACEHOLDER } from "@/apps/util/appUtil"
import FigmaLogo from "@/core/ui/images/logos/figma.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { EmbedConfig } from "@components/editor/plugins/embeds/EmbedNode"
import EmbedReactEditorToolFormLabel from "@components/editor/plugins/embeds/EmbedReactEditorToolFormLabel"
import { DiscoFormControl, DiscoInput, DiscoText } from "@disco-ui"

const FIGMA_REGEX =
  /https:\/\/([w.-]+\.)?figma.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/i

const EditorFigmaEmbed: EmbedConfig = {
  title: "Figma",
  icon: <FigmaLogo height={18} width={18} />,
  keywords: ["figma", "embed"],
  validateSetup: (data) => {
    if (!FIGMA_REGEX.test(data.url || ""))
      return new Error("Please provide a valid Figma file URL")
    return null
  },
  resizeData: {
    defaultHeight: "500px",
    width: "100%",
  },
  Component({ data }) {
    const classes = useStyles()

    const matches = data.url?.match(FIGMA_REGEX)
    if (!matches) return null

    return (
      <iframe
        className={classes.figmaFrame}
        src={`https://www.figma.com/embed?embed_host=disco&url=${encodeURIComponent(
          data.url!
        )}`}
        title={"Figma"}
        frameBorder={"0"}
        allow={"autoplay; fullscreen; picture-in-picture"}
      />
    )
  },
  SetupForm({ data, setData }) {
    return (
      <DiscoFormControl
        label={
          <EmbedReactEditorToolFormLabel
            icon={<FigmaLogo height={18} width={18} />}
            label={"Figma Link"}
          />
        }
      >
        <DiscoInput
          placeholder={INTEGRATION_URL_PLACEHOLDER.figma}
          value={data.url}
          onChange={handleChangeUrl}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </DiscoFormControl>
    )

    function handleChangeUrl(e: React.ChangeEvent<HTMLInputElement>) {
      setData({ url: e.target.value })
    }
  },
  SetupFormHelp() {
    return (
      <DiscoText variant={"body-sm-500"} color={"text.secondary"}>
        {
          "Copy the URL of the Figma from your browser's address bar and paste it above. In the Share settings, please ensure that Anyone with the link can view."
        }
      </DiscoText>
    )
  },
}

const useStyles = makeUseStyles({
  figmaFrame: {
    height: "100%",
    width: "100%",
  },
})

export default EditorFigmaEmbed
