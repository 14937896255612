import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { AttachBlockEntity } from "@components/editor/plugins/attach-block/AttachBlockNode"
import EditorAttachBlock from "@components/editor/plugins/attach-block/EditorAttachBlock"
import { Popover, PopoverProps } from "@material-ui/core"

interface Props {
  anchorEl: Element | null
  entityId: GlobalID
  onClose: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => void
  open?: boolean
}

function AttachBlockPopover(props: Props) {
  const { anchorEl, entityId, onClose, open } = props
  const classes = useStyles()
  const { type } = Relay.fromGlobalId(entityId)
  let attachBlockEntity: AttachBlockEntity | null = null

  switch (type) {
    case "Product":
      attachBlockEntity = "product"
      break
    case "Occurrence":
      attachBlockEntity = "occurrence"
      break
    case "ContentUsage":
      attachBlockEntity = "contentUsage"
      break
  }
  if (!attachBlockEntity) return null
  return (
    <Popover
      anchorEl={anchorEl}
      open={typeof open === "boolean" ? open : Boolean(anchorEl)}
      onClose={onClose as PopoverProps["onClose"]}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      PaperProps={{ classes: { root: classes.popoverPaper } }}
    >
      <EditorAttachBlock
        onClose={onClose}
        attachBlockEntity={attachBlockEntity}
        entityId={entityId}
      />
    </Popover>
  )
}

const useStyles = makeUseStyles((theme) => ({
  popoverPaper: {
    padding: 0,
    margin: 0,
    background: "transparent",
    borderRadius: theme.measure.borderRadius.big,
    boxShadow: "none",
    minWidth: 400,
    maxWidth: 900,

    [theme.breakpoints.down("sm")]: {
      minWidth: 200,
      maxWidth: "90%",
    },
  },
}))

export default AttachBlockPopover
