import {
  $createEmbedNode,
  EmbedNode,
  EmbedPayload,
} from "@components/editor/plugins/embeds/EmbedNode"
import {
  $createEmbedUploadFileNode,
  EmbedUploadFilePayload,
} from "@components/editor/plugins/embeds/EmbedUploadFileNode"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $insertNodeToNearestRoot, mergeRegister } from "@lexical/utils"
import { COMMAND_PRIORITY_EDITOR, LexicalCommand, NodeKey, createCommand } from "lexical"
import { useEffect } from "react"

export type InsertEmbedPayload = Readonly<EmbedPayload>

export type InsertEmbedFilePayload = Readonly<EmbedUploadFilePayload>

export const INSERT_EMBED_COMMAND: LexicalCommand<InsertEmbedPayload> =
  createCommand("INSERT_EMBED_COMMAND")

export const INSERT_EMBED_FILE_COMMAND: LexicalCommand<InsertEmbedFilePayload> =
  createCommand("INSERT_EMBED_FILE_COMMAND")

export type UpdateEmbedPayload = Readonly<{
  key: NodeKey
}>

export const UPDATE_EMBED_COMMAND: LexicalCommand<UpdateEmbedPayload> =
  createCommand("UPDATE_EMBED_COMMAND")

export default function EmbedPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (!editor.hasNodes([EmbedNode])) {
      throw new Error("EmbedPlugin: EmbedNode not registered on editor")
    }

    return mergeRegister(
      editor.registerCommand<InsertEmbedPayload>(
        INSERT_EMBED_COMMAND,
        (payload) => {
          const embedNode = $createEmbedNode(payload)
          $insertNodeToNearestRoot(embedNode)
          return true
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand<InsertEmbedFilePayload>(
        INSERT_EMBED_FILE_COMMAND,
        (payload) => {
          const embedFileNode = $createEmbedUploadFileNode(payload)
          $insertNodeToNearestRoot(embedFileNode)
          return true
        },
        COMMAND_PRIORITY_EDITOR
      )
    )
  }, [editor])

  return null
}
