import makeUseStyles from "@assets/style/util/makeUseStyles"
import { useTheme } from "@material-ui/core/styles"

export enum EditorTextColor {
  grey = "grey",
  orange = "orange",
  yellow = "yellow",
  green = "green",
  cyan = "cyan",
  blue = "blue",
  purple = "purple",
  pink = "pink",
  red = "red",
}

interface EditorColorPickerProps {
  variant: "font" | "highlight"
  onChange: (hex: string | null) => void
}

export function EditorColorPicker({ onChange, variant }: EditorColorPickerProps) {
  const theme = useTheme()
  const classes = useStyles()

  const colors = Object.values(EditorTextColor)

  return (
    <div className={classes.swatch}>
      {/* Clear Styling */}
      <button
        style={
          variant === "font"
            ? { background: theme.palette.text.primary }
            : {
                background: "transparent",
                border: `1px solid ${theme.palette.groovy.neutral[300]}`,
              }
        }
        className={classes.button}
        onClick={() => onChange(null)}
      />
      {/* Color Options */}
      {colors.map((color) => {
        const value = theme.palette.groovy[color][variant === "font" ? 500 : 300]

        return (
          <button
            key={color}
            style={value ? { background: value } : undefined}
            className={classes.button}
            onClick={() => onChange(value)}
          />
        )
      })}
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  swatch: {
    display: "flex",
    gap: theme.spacing(0.5),
    padding: theme.spacing(0.5),
  },
  button: {
    border: "none",
    width: 24,
    height: 24,
    borderRadius: theme.measure.borderRadius.default,
    cursor: "pointer",

    "&:hover": {
      boxShadow: theme.palette.groovyDepths.raisedBoxShadow,
    },
  },
}))

export default EditorColorPicker
