import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import { useFormStore } from "@/core/form/store/FormStore"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { $isEmbedNode, EmbedConfig } from "@components/editor/plugins/embeds/EmbedNode"
import UploadScormFileButton from "@components/editor/plugins/embeds/scorm/UploadScormFileButton"
import UploadScormFileDropzone, {
  DEFAULT_SCORM_TEXT,
} from "@components/editor/plugins/embeds/scorm/UploadScormFileDropzone"
import { EditorScormEmbedQuery } from "@components/editor/plugins/embeds/types/__generated__/EditorScormEmbedQuery.graphql"
import { EditorScormEmbed_startScormFileMutation } from "@components/editor/plugins/embeds/types/__generated__/EditorScormEmbed_startScormFileMutation.graphql"
import {
  EditorScormEmbed_updateScormCompletionMutation,
  UpdateScormCompletionInput,
} from "@components/editor/plugins/embeds/types/__generated__/EditorScormEmbed_updateScormCompletionMutation.graphql"
import { DiscoButton, DiscoIcon, DiscoIconButton, DiscoText } from "@disco-ui"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { useTheme } from "@material-ui/core"
import useDebounce from "@utils/hook/useDebounce"
import useHasEntitlement from "@utils/hook/useHasEntitlement"
import useInterval from "@utils/hook/useInterval"
import { SECOND_IN_MS } from "@utils/time/timeConstants"
import { $getNodeByKey } from "lexical"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { graphql } from "relay-runtime"

declare global {
  interface Window {
    // API Signature for SCORM 1.2
    API: {
      LMSInitialize(): string
      LMSFinish(): string
      LMSGetValue(key: string): string
      LMSSetValue(key: string, value: string): string
      LMSCommit(): string
      LMSGetLastError(): string // CMIErrorCode
      LMSGetErrorString(errorCode: string): string
      LMSGetDiagnostic(errorCode: string): string
    }
    // API Signature for SCORM 2004
    API_1484_11: {
      Initialize(): string
      Terminate(): string
      GetValue(key: string): string
      SetValue(key: string, value: string): string
      Commit(): string
      GetLastError(): string // CMIErrorCode
      GetErrorString(errorCode: string): string
      GetDiagnostic(errorCode: string): string
    }
  }
}

export type UpdateScormCompletionFormState = Omit<
  UpdateScormCompletionInput,
  "scormData"
> & {
  scormData: Record<string, any>
}

// Poll for updated Scorm file if not ready every 30 seconds
const SCORM_FILE_POLL_INTERVAL_MS = 30 * SECOND_IN_MS

const EditorSCORMEmbed: EmbedConfig = {
  title: "SCORM",
  icon: "icon-scorm",
  keywords: ["embed", "scorm"],
  validateSetup: (data) => {
    if (!data.scormFileId) return new Error("Please upload a zipped SCORM file.")
    return null
  },
  resizeData: {
    defaultHeight: "500px",
    width: "100%",
  },
  Component: observer(({ data, nodeKey }) => {
    const { scormFileId } = data

    const [containerEl, setContainerEl] = useState<HTMLSpanElement | null>(null)

    const [isFullscreen, setIsFullscreen] = useState(false)
    const [iframeSrc, setIframeSrc] = useState("")

    const [editor] = useLexicalComposerContext()

    const contentUsageDrawer = useContentUsageDrawer()
    const contentUsageId = contentUsageDrawer.params.drawerContentUsageId

    const theme = useTheme()
    const classes = componentUseStyles()

    const [{ node }, refetch] = Relay.useRefetchableQuery<EditorScormEmbedQuery>(
      graphql`
        query EditorScormEmbedQuery($scormFileId: ID!) {
          node(id: $scormFileId) {
            ... on ScormFile {
              __typename
              id
              status
              indexFileKey
              failedErrorMessage
            }
          }
        }
      `,
      { scormFileId: scormFileId || "" },
      { fetchPolicy: "network-only", refetchInBackground: true }
    )
    const scormFile = Relay.narrowNodeType(node, "ScormFile")

    // Poll for updated Scorm file if not ready
    useInterval(() => {
      if (!scormFile || scormFile.status === "processing") {
        refetch({ scormFileId: scormFileId || "" }, { inBackground: true })
      }
    }, SCORM_FILE_POLL_INTERVAL_MS)

    useEffect(() => {
      // If the scormFileId of the block changes, refetch the scormFile
      if (scormFile && scormFile.id !== scormFileId) {
        refetch({ scormFileId: scormFileId || "" })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scormFileId, scormFile?.id])

    const startScormFileForm = useFormStore<EditorScormEmbed_startScormFileMutation>(
      graphql`
        mutation EditorScormEmbed_startScormFileMutation($input: StartScormFileInput!) {
          response: startScormFile(input: $input) {
            node {
              id
              scormData
              status
            }
            errors {
              field
              message
            }
          }
        }
      `,
      {
        scormFileId: scormFileId || "",
        contentUsageId,
      },
      {
        showErrorToast: false,
      }
    )

    const updateScormCompletionForm = useFormStore<
      EditorScormEmbed_updateScormCompletionMutation,
      UpdateScormCompletionFormState
    >(
      graphql`
        mutation EditorScormEmbed_updateScormCompletionMutation(
          $input: UpdateScormCompletionInput!
        ) {
          response: updateScormCompletion(input: $input) {
            node {
              id
              scormData
            }
            errors {
              field
              message
            }
          }
        }
      `,
      {
        scormCompletionId: "",
        scormData: {},
      },
      {
        showErrorToast: false,
      }
    )

    useEffect(() => {
      if (scormFile?.status === "ready") {
        startScormFile()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scormFile?.id, scormFile?.status])

    useEffect(() => {
      const handleFullscreenChange = () => {
        if (document.fullscreenElement) {
          setIsFullscreen(true)
        } else {
          setIsFullscreen(false)
        }
      }
      document.addEventListener("fullscreenchange", handleFullscreenChange)
      return () => {
        document.removeEventListener("fullscreenchange", handleFullscreenChange)
      }
    }, [])

    const updateScormCompletion = useDebounce(() => {
      const { scormCompletionId, scormData } = updateScormCompletionForm.state
      if (!scormCompletionId) return

      updateScormCompletionForm.submit({
        scormCompletionId,
        scormData: JSON.stringify(scormData),
      })
    }, 1000)

    if (!scormFile || scormFile.status === "processing") {
      return (
        <div className={classes.processingContainer}>
          <DiscoIcon
            className={classes.rotateIcon}
            icon={"refresh-cw-02"}
            color={theme.palette.primary.main}
          />
          <div>
            <DiscoText variant={"body-md-600"}>{"Processing File..."}</DiscoText>
            <DiscoText variant={"body-sm"}>
              {
                "Your SCORM file is currently being processed. Check back in a few minutes!"
              }
            </DiscoText>
          </div>
        </div>
      )
    } else if (scormFile.status === "failed") {
      return (
        <div className={classes.failedContainer}>
          <DiscoIcon icon={"info"} color={theme.palette.groovy.red[700]} />
          <div className={classes.failedContentContainer}>
            <div>
              <DiscoText variant={"body-md-600"} color={"groovy.red.700"}>
                {"Something went wrong"}
              </DiscoText>
              <DiscoText variant={"body-sm"} color={"groovy.red.700"}>
                {scormFile.failedErrorMessage ||
                  "An error occurred while trying to process your SCORM file. Please try uploading the file again."}
              </DiscoText>
            </div>
            <UploadScormFileButton onSubmit={handleReuploadScormFile}>
              {(btnProps) => {
                return (
                  <DiscoButton color={"grey"} variant={"outlined"} {...btnProps}>
                    {"Upload File"}
                  </DiscoButton>
                )
              }}
            </UploadScormFileButton>
          </div>
        </div>
      )
    }

    if (!iframeSrc) return null

    return (
      <span ref={(el) => setContainerEl(el)}>
        <iframe
          title={"SCORM Content"}
          className={classes.iframe}
          src={iframeSrc}
          frameBorder={"0"}
          allow={
            "allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          }
          style={{ height: "100%" }}
        />

        {containerEl?.requestFullscreen && (
          <DiscoIconButton
            className={classes.fullscreenButton}
            size={"small"}
            variant={"outlined"}
            onClick={() => {
              if (isFullscreen) {
                document.exitFullscreen()
              } else {
                containerEl.requestFullscreen()
              }
            }}
            height={40}
            width={40}
          >
            <DiscoIcon icon={isFullscreen ? "reduce" : "expand"} />
          </DiscoIconButton>
        )}
      </span>
    )

    async function startScormFile() {
      if (contentUsageDrawer.isOpen) {
        // Completions not tracked when viewing from admin drawer
        const response = await startScormFileForm.submit(startScormFileForm.state)
        if (!response?.response?.node) return

        updateScormCompletionForm.state.scormCompletionId = response.response.node.id
        updateScormCompletionForm.state.scormData = JSON.parse(
          response.response.node.scormData
        )
      }

      //* API for SCORM 1.2
      window.API = {
        LMSInitialize() {
          return handleInitialize()
        },
        LMSFinish() {
          return handleExit()
        },
        LMSGetValue(key: string) {
          return handleGetValue(key)
        },
        LMSSetValue(key: string, value: string) {
          return handleSetValue(key, value)
        },
        LMSCommit() {
          return handleCommit()
        },
        LMSGetLastError() {
          return handleGetLastError()
        },
        LMSGetErrorString(errorCode: string) {
          return handleLogError(errorCode)
        },
        LMSGetDiagnostic(errorCode: string) {
          return handleLogError(errorCode)
        },
      }

      //* API for SCORM 2004
      window.API_1484_11 = {
        Initialize() {
          return handleInitialize()
        },
        Terminate() {
          return handleExit()
        },
        GetValue(key: string) {
          return handleGetValue(key)
        },
        SetValue(key: string, value: string) {
          return handleSetValue(key, value)
        },
        Commit() {
          return handleCommit()
        },
        GetLastError() {
          return handleGetLastError()
        },
        GetErrorString(errorCode: string) {
          return handleLogError(errorCode)
        },
        GetDiagnostic(errorCode: string) {
          return handleLogError(errorCode)
        },
      }

      const src = `${window.location.origin}${scormFile?.indexFileKey}`
      setIframeSrc(src)
    }

    function handleInitialize() {
      return "true"
    }

    function handleExit() {
      return "true"
    }

    function handleGetValue(key: string) {
      const value = updateScormCompletionForm.state.scormData[key]
      return value || ""
    }

    function handleSetValue(key: string, value: string) {
      const { scormData } = updateScormCompletionForm.state
      scormData[key] = value
      updateScormCompletion()
      return value
    }

    function handleCommit() {
      updateScormCompletion()
      return "true"
    }

    function handleGetLastError() {
      return "0"
    }

    function handleLogError(errorCode: string) {
      if (errorCode) console.log(`got error: ${errorCode}`)
      return ""
    }

    function handleReuploadScormFile(id: GlobalID) {
      editor.update(() => {
        const scormNode = $getNodeByKey(nodeKey)
        if ($isEmbedNode(scormNode)) {
          scormNode.setData({ scormFileId: id })
        }
      })
    }
  }),
  SetupForm({ setData }) {
    const hasScormEntitlement = useHasEntitlement("scorm")
    return (
      <UploadScormFileDropzone
        onUpload={handleUpload}
        onRemove={handleRemove}
        disabled={!hasScormEntitlement}
      />
    )

    function handleUpload(scormFileId: GlobalID) {
      setData({ scormFileId, height: "500px" })
    }

    function handleRemove() {
      setData({ scormFileId: undefined, height: undefined })
    }
  },
  SetupFormHelp() {
    return (
      <DiscoText variant={"body-sm-500"} color={"text.secondary"}>
        {DEFAULT_SCORM_TEXT.uploadDescription}
      </DiscoText>
    )
  },
}

const componentUseStyles = makeUseStyles((theme) => ({
  processingContainer: {
    display: "grid",
    gridTemplateColumns: "24px 1fr",
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.grey[700] : theme.palette.grey[100],
    borderRadius: theme.measure.borderRadius.big,
  },
  failedContainer: {
    display: "grid",
    gridTemplateColumns: "24px 1fr",
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.groovy.red[100],
    borderRadius: theme.measure.borderRadius.big,
  },
  failedContentContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(1),
  },
  iframe: {
    width: "100%",
  },
  fullscreenButton: {
    position: "absolute",
    top: theme.spacing(1.5),
    right: theme.spacing(2),
  },
  rotateIcon: {
    animation: "$spin 3s linear infinite",
  },
  // eslint-disable-next-line local-rules/disco-unused-classes
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
}))

export default EditorSCORMEmbed
