import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoSelect, DiscoText, SelectOption } from "@disco-ui"
import { useEffect, useState } from "react"
import { generateTimeDropdownOptions } from "./util/timeDropdownUtils"

export type TimeDropdownOption = SelectOption<
  string,
  { subtitle: string; date: Date; differenceInMinutes: number }
>

interface TimeDropdownProps {
  testid: string
  startTimeString: string
  selectedOption: null | TimeDropdownOption
  onSelect: (option: null | TimeDropdownOption) => void
  isDisabled?: boolean
  className?: string
}

function TimeDropdown({
  testid,
  startTimeString,
  selectedOption,
  onSelect,
  isDisabled,
  className,
}: TimeDropdownProps) {
  const [options, setOptions] = useState<TimeDropdownOption[]>(
    generateTimeDropdownOptions({ startTime: startTimeString })
  )

  useEffect(() => {
    setOptions(generateTimeDropdownOptions({ startTime: startTimeString }))
  }, [startTimeString])

  const classes = useStyles()

  return (
    <DiscoSelect
      testid={testid}
      placeholder={"Select Time"}
      disableClearable
      classes={{
        root: className,
        popper: classes.popper,
      }}
      autoWidth
      value={selectedOption!.value}
      onChange={(value) => onSelect(options.find((o) => o.value === value)!)}
      options={options}
      filterOptions={{ maxVisible: 500 }} // No limit to how many options we show
      renderOption={(option) => {
        return (
          <div className={classes.renderOption}>
            <DiscoText>{option.title}</DiscoText>
            <DiscoText color={"groovy.grey.400"}>{option.context!.subtitle}</DiscoText>
          </div>
        )
      }}
      disabled={isDisabled}
    />
  )
}

const useStyles = makeUseStyles({
  popper: {
    minWidth: "200px !important",
  },
  renderOption: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
})

export default TimeDropdown
