import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoTooltip, DiscoTooltipProps } from "@disco-ui"

function EditorTooltip({
  children,
  content,
  enterDelay = 100,
  ...props
}: Omit<DiscoTooltipProps, "classes">) {
  const classes = useStyles()

  return (
    <DiscoTooltip
      content={<div className={classes.tooltipContent}>{content}</div>}
      classes={{ tooltip: classes.tooltip }}
      enterDelay={enterDelay}
      {...props}
    >
      <div>{children}</div>
    </DiscoTooltip>
  )
}

const useStyles = makeUseStyles((theme) => ({
  tooltip: {
    padding: theme.spacing(0.75),
    borderRadius: theme.measure.borderRadius.default,
  },
  tooltipContent: {
    textAlign: "center",
    display: "grid",
    gap: theme.spacing(0.5),
  },
}))

export default EditorTooltip
