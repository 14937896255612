import Badge from "@/admin/experiences/badges/Badge"
import defaultThumbnail from "@/core/ui/images/public-pages/default-thumbnail.png"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import CoverPhoto from "@components/cover-photo/CoverPhoto"
import { AttachBlockEntity } from "@components/editor/plugins/attach-block/AttachBlockNode"
import AttachBlockSelectModalListItem from "@components/editor/plugins/attach-block/AttachBlockSelectModalListItem"
import { AttachBlockProductModalListItem_ProductFragment$key } from "@components/editor/plugins/attach-block/types/product/__generated__/AttachBlockProductModalListItem_ProductFragment.graphql"
import { DiscoText } from "@disco-ui"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface AttachBlockProductModalListItemProps {
  productKey: AttachBlockProductModalListItem_ProductFragment$key
  onClose: VoidFunction
  onProductSelect?: (attachBlockEntity: AttachBlockEntity, entityId: string) => void
  inline: boolean
}

function AttachBlockProductModalListItem({
  productKey,
  onClose,
  onProductSelect,
  inline,
}: AttachBlockProductModalListItemProps) {
  const classes = useStyles()

  const product = useFragment<AttachBlockProductModalListItem_ProductFragment$key>(
    graphql`
      fragment AttachBlockProductModalListItem_ProductFragment on Product {
        __typename
        id
        name
        type
        cover
        type
        badge {
          ...BadgeFragment
        }
      }
    `,
    productKey
  )

  const getThumbnail = () => {
    if (!product.badge) return null
    if (product.type === "course") {
      return <Badge badgeKey={product.badge} size={24} />
    }

    return (
      <CoverPhoto
        coverPhoto={product.cover || defaultThumbnail}
        customClassName={classes.cover}
      />
    )
  }

  return (
    <AttachBlockSelectModalListItem
      attachBlockEntity={"product"}
      onClose={onClose}
      entityId={product.id}
      name={product.name}
      onSelect={onProductSelect}
      inline={inline}
    >
      {getThumbnail()}
      <div className={classes.textContainer}>
        <DiscoText
          variant={"body-sm-700"}
          noWrap
          truncateText={1}
          className={classes.text}
        >
          {product.name}
        </DiscoText>
      </div>
    </AttachBlockSelectModalListItem>
  )
}

const useStyles = makeUseStyles((theme) => ({
  text: {
    paddingLeft: theme.spacing(1),
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    overflow: "hidden",
  },
  cover: {
    width: "32px",
    borderRadius: theme.measure.borderRadius.default,
    objectFit: "cover",
  },
}))

export default AttachBlockProductModalListItem
