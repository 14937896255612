import { ContentUsageUtils } from "@/content-usage/ContentUsageUtils"
import { useLabel } from "@/core/context/LabelsContext"
import { useUnsavedChangesModalContext } from "@/core/context/UnsavedChangesModalProvider"
import DefaultThumbnail from "@/core/ui/images/public-pages/default-thumbnail.png"
import useIsWebView from "@/product/util/hook/useIsWebView"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import BookIcon from "@/core/ui/iconsax/linear/book-1.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import CoverPhoto from "@components/cover-photo/CoverPhoto"
import { AttachBlockConfig } from "@components/editor/plugins/attach-block/AttachBlockNode"
import {
  MissingAccessAttachBlock,
  PlaceholderAttachBlock,
} from "@components/editor/plugins/attach-block/EditorAttachBlock"
import { EditorContentUsageAttachBlockQuery } from "@components/editor/plugins/attach-block/types/content-usage/__generated__/EditorContentUsageAttachBlockQuery.graphql"
import { DiscoButton, DiscoChip, DiscoIcon, DiscoText, DiscoTooltip } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import useActiveProductOrOrganizationPermissions from "@utils/hook/useActiveProductOrOrganizationPermissions"
import { setSearchParams } from "@utils/url/urlUtils"
import { useLazyLoadQuery } from "react-relay"
import { useHistory } from "react-router-dom"
import { graphql } from "relay-runtime"

const EditorContentUsageAttachBlock: AttachBlockConfig = {
  title: "Content",
  icon: <BookIcon height={18} width={18} />,
  keywords: ["link", "content", "lesson", "module"],
  Component({ entityId, onClose, attachBlockEntity = "contentUsage" }) {
    const classes = useStyles()
    const history = useHistory()
    const { handleLeave } = useUnsavedChangesModalContext()
    const testid = "attach-block-editor.content"
    const isMobile = useIsMobile()
    const isWebView = useIsWebView()
    const experienceLabel = useLabel("admin_experience")
    const membersLabel = useLabel("admin_member")

    const { contentUsage } = useLazyLoadQuery<EditorContentUsageAttachBlockQuery>(
      graphql`
        query EditorContentUsageAttachBlockQuery($id: ID!) {
          contentUsage: node(id: $id) {
            ... on ContentUsage {
              id
              entity
              isLocked
              content {
                id
                type
                label
                name
                type
                coverPhoto
                ...ContentThumbnail_ContentFragment
              }
              product {
                status
                name
                registrationAvailability
                slug
              }
              productApp {
                kind
                customAppTitle
              }
              module {
                name
              }
              ...ContentUsageUtils_useContentUsageLocationLabelContentUsageFragment
              ...ContentUsageUtils_useContentUsagePathContentUsageFragment
            }
          }
        }
      `,
      {
        id: entityId as GlobalID,
      }
    )

    const pathname = ContentUsageUtils.useContentUsagePath(contentUsage)
    const permissions = useActiveProductOrOrganizationPermissions()
    const locationLabel = ContentUsageUtils.useContentUsageLocationLabel(contentUsage)
    const isProductInDraftMode = contentUsage?.product?.status === "draft"

    if (!contentUsage?.content) {
      if (!permissions.has("content.manage"))
        return <MissingAccessAttachBlock attachBlockEntity={attachBlockEntity} />
      return <PlaceholderAttachBlock attachBlockEntity={attachBlockEntity} />
    }
    if (isProductInDraftMode && !permissions.has("content.manage")) {
      return <MissingAccessAttachBlock attachBlockEntity={attachBlockEntity} />
    }

    return (
      <div className={classes.root}>
        <DiscoContainerButton
          onClick={(e) => handleClick(e)}
          testid={`${testid}.${contentUsage.content.name}`}
          className={classes.attachBlockLink}
        >
          <div className={classes.infoWrapper}>
            <div className={classes.iconWrapper}>
              <CoverPhoto
                coverPhoto={contentUsage?.content.coverPhoto || DefaultThumbnail}
              />
            </div>
            <div className={classes.details}>
              <div className={classes.header}>
                <DiscoText
                  truncateText={1}
                  variant={"body-xs-600-uppercase"}
                  color={"primary.main"}
                >
                  {contentUsage.content.label}
                </DiscoText>
                {isProductInDraftMode && (
                  <DiscoTooltip
                    content={`This ${contentUsage.content.label} is hidden to ${membersLabel.plural} because its ${experienceLabel.singular} is in draft mode`}
                  >
                    <div
                      data-testid={`${testid}.${contentUsage.content.name}.draftButton`}
                    >
                      <DiscoChip
                        leftIcon={<DiscoIcon icon={"eye-off"} height={16} width={16} />}
                        color={"yellow"}
                        label={"Draft"}
                      />
                    </div>
                  </DiscoTooltip>
                )}
              </div>
              <DiscoText truncateText={1} variant={"body-lg-600"}>
                {contentUsage.content.name}
              </DiscoText>
              <DiscoText variant={"body-xs"} noWrap truncateText={1}>
                {locationLabel}
              </DiscoText>
            </div>
          </div>

          <div className={classes.actionButtonWrapper}>
            <DiscoButton
              data-testid={`${testid}.${contentUsage.content.name}.CTAButton`}
              color={"grey"}
              variant={"outlined"}
              onClick={handleClick}
              width={isMobile || isWebView ? "100%" : undefined}
            >
              {`View ${contentUsage.content.label}`}
            </DiscoButton>
          </div>
        </DiscoContainerButton>
      </div>
    )

    function handleClick(e: React.MouseEvent<Element, MouseEvent>) {
      e.stopPropagation()
      if (onClose) onClose()
      handleLeave({
        onLeave: () => {
          if (!contentUsage?.id) return
          // If a folder or module, scroll the content usage into view
          if (
            contentUsage.entity === "collection" ||
            contentUsage.content?.type === "module"
          ) {
            history.push({
              ...location,
              pathname,
              hash: contentUsage.id,
            })
          }
          // Otherwise, just navigate to the content usage
          else {
            history.push({
              ...location,
              pathname,
              search: setSearchParams(location.search, {
                contentId: undefined,
                u: Relay.fromGlobalId(contentUsage.id).id,
              }),
            })
          }
        },
      })
    }
  },
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.big,
    border: theme.palette.constants.borderSmallDarkOnly,
    position: "relative",
    margin: theme.spacing(2, 0.5, 2, 0.5),
    boxShadow: theme.palette.groovyDepths.insideCard,
    "&:hover": {
      boxShadow: theme.palette.groovyDepths.boxShadow,
    },
  },
  attachBlockLink: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    height: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "0%",
    overflow: "hidden",
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  iconWrapper: {
    padding: "auto",
    width: "120px",
    height: "60px",
    margin: theme.spacing(2, 0, 2, 2),
    flexShrink: 0,
    flexGrow: 0,
    [theme.breakpoints.down("md")]: {
      width: "80px",
      height: "40px",
    },
  },
  details: {
    padding: theme.spacing(2),
    textAlign: "start",
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    maxWidth: "100%",
    textOverflow: "ellipsis",
  },
  actionButtonWrapper: {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flexShrink: 0,
    flexBasis: "auto",
  },
}))
export default EditorContentUsageAttachBlock
