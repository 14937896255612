import { ContentUsageUtils } from "@/content-usage/ContentUsageUtils"
import ContentThumbnail from "@/content/detail/ContentThumbnail"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { AttachBlockEntity } from "@components/editor/plugins/attach-block/AttachBlockNode"
import AttachBlockSelectModalListItem from "@components/editor/plugins/attach-block/AttachBlockSelectModalListItem"
import { AttachBlockContentUsageModalListItem_ContentUsageFragment$key } from "@components/editor/plugins/attach-block/types/content-usage/__generated__/AttachBlockContentUsageModalListItem_ContentUsageFragment.graphql"
import { DiscoText } from "@disco-ui"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface AttachBlockContentUsageModalListItemProps {
  contentUsageKey: AttachBlockContentUsageModalListItem_ContentUsageFragment$key
  onClose: VoidFunction
  onContentUsageSelect?: (attachBlockEntity: AttachBlockEntity, entityId: string) => void
  inline: boolean
  attachBlockEntity: AttachBlockEntity
}

function AttachBlockContentUsageModalListItem({
  contentUsageKey,
  onClose,
  onContentUsageSelect,
  inline,
  attachBlockEntity,
}: AttachBlockContentUsageModalListItemProps) {
  const classes = useStyles()

  const contentUsage =
    useFragment<AttachBlockContentUsageModalListItem_ContentUsageFragment$key>(
      graphql`
        fragment AttachBlockContentUsageModalListItem_ContentUsageFragment on ContentUsage {
          id
          entity
          viewerPermissions
          content {
            ...ContentThumbnail_ContentFragment
            name
            id
          }

          ...ContentUsageUtils_useContentUsageLocationLabelContentUsageFragment
        }
      `,
      contentUsageKey
    )
  const content = contentUsage?.content

  const locationLabel = ContentUsageUtils.useContentUsageLocationLabel(contentUsage)
  const permissions = contentUsage.viewerPermissions
  if (!permissions.includes("content.read")) {
    return null
  }
  return (
    <AttachBlockSelectModalListItem
      attachBlockEntity={attachBlockEntity}
      onClose={onClose}
      entityId={contentUsage.id}
      name={content.name}
      onSelect={onContentUsageSelect}
      inline={inline}
    >
      <div className={classes.customImageContainer}>
        <ContentThumbnail contentKey={content} />
      </div>
      <div className={classes.textContainer}>
        <DiscoText
          variant={"body-sm-700"}
          noWrap
          truncateText={1}
          className={classes.text}
        >
          {content.name}
        </DiscoText>

        <DiscoText variant={"body-xs"} noWrap truncateText={1} className={classes.text}>
          {locationLabel}
        </DiscoText>
      </div>
    </AttachBlockSelectModalListItem>
  )
}

const useStyles = makeUseStyles((theme) => ({
  customImageContainer: {
    width: "40px",
    height: "40px",
    minWidth: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: theme.measure.borderRadius.default,
  },
  text: {
    paddingLeft: theme.spacing(1),
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    overflow: "hidden",
  },
}))
export default AttachBlockContentUsageModalListItem
