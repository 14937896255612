import makeUseStyles from "@assets/style/util/makeUseStyles"
import DaySquare from "@components/date/day-square/DaySquare"
import { AttachBlockEntity } from "@components/editor/plugins/attach-block/AttachBlockNode"
import AttachBlockSelectModalListItem from "@components/editor/plugins/attach-block/AttachBlockSelectModalListItem"
import { AttachBlockEventModalListItem_OccurrenceFragment$key } from "@components/editor/plugins/attach-block/types/event/__generated__/AttachBlockEventModalListItem_OccurrenceFragment.graphql"
import { DiscoText } from "@disco-ui"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface AttachBlockEventModalListItemProps {
  occurrenceKey: AttachBlockEventModalListItem_OccurrenceFragment$key
  onClose: VoidFunction
  onEventSelect?: (attachBlockEntity: AttachBlockEntity, entityId: string) => void
  inline: boolean
}

function AttachBlockEventModalListItem({
  occurrenceKey,
  onClose,
  onEventSelect,
  inline,
}: AttachBlockEventModalListItemProps) {
  const classes = useStyles()

  const occurrence = useFragment<AttachBlockEventModalListItem_OccurrenceFragment$key>(
    graphql`
      fragment AttachBlockEventModalListItem_OccurrenceFragment on Occurrence {
        __typename
        id
        name
        datetimeRange
        product {
          type
          name
        }
      }
    `,
    occurrenceKey
  )

  if (!occurrence) return null

  const date = new Date(occurrence.datetimeRange[0])

  return (
    <AttachBlockSelectModalListItem
      attachBlockEntity={"occurrence"}
      onClose={onClose}
      entityId={occurrence.id}
      name={occurrence.name}
      onSelect={onEventSelect}
      inline={inline}
    >
      <DaySquare
        testid={"attach-block-list-occurrence-day"}
        date={date}
        customClassName={classes.daySquare}
        customSquareMonthClassName={classes.squareMonthClassName}
        customSquareDayClassName={classes.squareDayClassName}
      />
      <div className={classes.textContainer}>
        <DiscoText
          variant={"body-sm-700"}
          noWrap
          truncateText={1}
          className={classes.text}
        >
          {occurrence.name}
        </DiscoText>
        {occurrence.product?.type === "course" && (
          <DiscoText variant={"body-xs"} noWrap truncateText={1} className={classes.text}>
            {`Hosted in `}
            <DiscoText component={"span"} variant={"body-xs-500"} color={"primary.main"}>
              {occurrence.product.name}
            </DiscoText>
          </DiscoText>
        )}
      </div>
    </AttachBlockSelectModalListItem>
  )
}

const useStyles = makeUseStyles((theme) => ({
  text: {
    paddingLeft: theme.spacing(1),
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    overflow: "hidden",
  },
  daySquare: {
    flexShrink: 0,
    width: "40px",
    height: "40px",
  },
  squareMonthClassName: {
    marginTop: theme.spacing(0.25),
    fontSize: "10px",
    lineHeight: "10px",
  },
  squareDayClassName: {
    marginTop: 0,
    fontSize: "14px",
    lineHeight: "14px",
  },
}))
export default AttachBlockEventModalListItem
