import LexicalEditor, { LexicalChildProps } from "@components/editor/LexicalEditor"
import { DiscoEditorMentionsFragment$key } from "@components/editor/__generated__/DiscoEditorMentionsFragment.graphql"
import {
  LexicalConfigType,
  LexicalEditorUsageData,
} from "@components/editor/config/LexicalConfig"
import { EditorButtonLinkTo } from "@components/editor/plugins/button/EditorButton"
import { EmbedKind } from "@components/editor/plugins/embeds/EmbedNode"
import { EditorMentionData } from "@components/editor/plugins/mentions/EditorMention"
import { TestIDProps } from "@utils/typeUtils"
import { MutableRefObject } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface DiscoEditorProps extends TestIDProps {
  className?: string
  defaultValue?: string | null
  onChange?: (value: string) => void
  readOnly?: boolean
  config?: LexicalConfigType
  editorUsageData?: LexicalEditorUsageData
  defaultEmbedKind?: EmbedKind | null
  buttonLinkTo?: EditorButtonLinkTo
  defaultMentions?: Omit<EditorMentionData, "id">[]
  mentionsContentKey?: DiscoEditorMentionsFragment$key | null
  placeholder?: string
  placeholderOnNewLines?: boolean
  hasError?: boolean
  variant?: "input" | "default"
  markdown?: string | null
  // Style Props
  backgroundColor?: string
  textColor?: string
  fontSize?: string
  minHeight?: number
  disableGutter?: boolean
  height?: React.CSSProperties["height"]
  disableMarginRight?: boolean
  showOutline?: boolean
  onMount?: LexicalChildProps["onMount"]
  addBlockButtonsAnchorRef?: MutableRefObject<HTMLDivElement | null>
  disableMediaModal?: boolean
  disableScroll?: boolean
}

function DiscoEditor(props: DiscoEditorProps) {
  const {
    className,
    defaultValue,
    onChange,
    readOnly,
    config,
    defaultMentions,
    defaultEmbedKind,
    buttonLinkTo,
    mentionsContentKey,
    placeholder,
    placeholderOnNewLines,
    hasError,
    variant,
    editorUsageData,
    onMount,
    markdown,
    addBlockButtonsAnchorRef,
    disableMediaModal,
    disableScroll,
    ...styleProps
  } = props

  // Read existing mentions into the editor state.
  const content = useFragment<DiscoEditorMentionsFragment$key>(
    graphql`
      fragment DiscoEditorMentionsFragment on Content {
        id
        ...EditorMentionsProviderFragment
      }
    `,
    mentionsContentKey ?? null
  )

  return (
    <LexicalEditor
      className={className}
      defaultValue={defaultValue}
      onChange={onChange}
      config={config}
      placeholder={placeholder}
      placeholderOnNewLines={placeholderOnNewLines}
      readOnly={readOnly}
      defaultMentions={defaultMentions}
      defaultEmbedKind={defaultEmbedKind}
      buttonLinkTo={buttonLinkTo}
      mentionsContentKey={content}
      hasError={hasError}
      variant={variant}
      editorUsageData={editorUsageData}
      onMount={onMount}
      markdown={markdown}
      addBlockButtonsAnchorRef={addBlockButtonsAnchorRef}
      disableMediaModal={disableMediaModal}
      disableScroll={disableScroll}
      {...styleProps}
    />
  )
}

export default DiscoEditor
