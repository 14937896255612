import makeUseStyles from "@assets/style/util/makeUseStyles"
import { useLexicalEditorContext } from "@components/editor/LexicalEditorContext"
import { EDITOR_BLOCKS } from "@components/editor/config/LexicalNodes"
import { DiscoIcon, DiscoIconButton } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { MutableRefObject } from "react"
import { createPortal } from "react-dom"

/**
 * Use a portal to render the add block buttons so they can be added outside the editor
 * context (i.e. in a modal footer).
 */
type Props = TestIDProps & {
  anchorRef?: MutableRefObject<HTMLDivElement | null>
  onImageUploadOpen: () => void
  onVideoUploadOpen: () => void
  onPollSetupOpen: () => void
}

export default function EditorAddBlockButtons(props: Props) {
  const { testid = "EditorAddBlockButtons", anchorRef } = props
  const { config } = useLexicalEditorContext()
  const classes = useStyles()

  if (!anchorRef?.current) return null

  return createPortal(
    <div className={classes.container}>
      {config.blocks.has("image") && (
        <DiscoIconButton
          testid={`${testid}.image`}
          className={classes.button}
          tooltip={`Add ${EDITOR_BLOCKS.image.title}`}
          onClick={props.onImageUploadOpen}
        >
          <DiscoIcon icon={EDITOR_BLOCKS.image.icon!} />
        </DiscoIconButton>
      )}
      {config.blocks.has("video") && (
        <DiscoIconButton
          testid={`${testid}.video`}
          className={classes.button}
          tooltip={`Add ${EDITOR_BLOCKS.video.title}`}
          onClick={props.onVideoUploadOpen}
        >
          <DiscoIcon icon={EDITOR_BLOCKS.video.icon!} />
        </DiscoIconButton>
      )}
      {config.blocks.has("poll") && (
        <DiscoIconButton
          testid={`${testid}.poll`}
          className={classes.button}
          tooltip={`Add ${EDITOR_BLOCKS.poll.title}`}
          onClick={props.onPollSetupOpen}
        >
          <DiscoIcon icon={EDITOR_BLOCKS.poll.icon!} />
        </DiscoIconButton>
      )}
    </div>,
    anchorRef.current
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
  },
  button: {
    padding: theme.spacing(1),
  },
}))
