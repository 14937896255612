import {
  LexicalConfig,
  LexicalEditorUsageData,
} from "@components/editor/config/LexicalConfig"
import { createContext, useContext, useState } from "react"

type LexicalEditorContextValue = {
  isBlockListOpen: boolean
  setIsBlockListOpen: React.Dispatch<React.SetStateAction<boolean>>
  isBlockOptionsOpen: boolean
  setIsBlockOptionsOpen: React.Dispatch<React.SetStateAction<boolean>>
  config: LexicalConfig
  editorUsageData: LexicalEditorUsageData
  disableMediaModal?: boolean
}

const LexicalEditorContext = createContext<LexicalEditorContextValue>({
  isBlockListOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsBlockListOpen: () => {},
  isBlockOptionsOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsBlockOptionsOpen: () => {},
  config: { blocks: new Set(), inlineTools: new Set() },
  editorUsageData: {},
  disableMediaModal: false,
})

interface LexicalEditorProviderProps {
  children: React.ReactNode
  config: LexicalConfig
  editorUsageData: LexicalEditorUsageData
  disableMediaModal?: boolean
}

export function LexicalEditorProvider({
  children,
  config,
  editorUsageData,
  disableMediaModal,
}: LexicalEditorProviderProps) {
  const [isBlockListOpen, setIsBlockListOpen] = useState<boolean>(false)
  const [isBlockOptionsOpen, setIsBlockOptionsOpen] = useState<boolean>(false)

  return (
    <LexicalEditorContext.Provider
      value={{
        isBlockListOpen,
        setIsBlockListOpen,
        isBlockOptionsOpen,
        setIsBlockOptionsOpen,
        config,
        editorUsageData,
        disableMediaModal,
      }}
    >
      {children}
    </LexicalEditorContext.Provider>
  )
}

export function useLexicalEditorContext(): LexicalEditorContextValue {
  const context = useContext(LexicalEditorContext)
  return context
}
