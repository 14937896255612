import { GlobalID } from "@/relay/RelayTypes"
import EditorEmbed from "@components/editor/plugins/embeds/EditorEmbed"
import EditorAirtableEmbed from "@components/editor/plugins/embeds/types/EditorAirtableEmbed"
import EditorCalendlyEmbed from "@components/editor/plugins/embeds/types/EditorCalendlyEmbed"
import EditorDiscordEmbed from "@components/editor/plugins/embeds/types/EditorDiscordEmbed"
import EditorEmbedCodeEmbed from "@components/editor/plugins/embeds/types/EditorEmbedCodeEmbed"
import EditorFigmaEmbed from "@components/editor/plugins/embeds/types/EditorFigmaEmbed"
import EditorGenericIframeEmbed from "@components/editor/plugins/embeds/types/EditorGenericIframeEmbed"
import EditorGoogleDocsEmbed from "@components/editor/plugins/embeds/types/EditorGoogleDocsEmbed"
import EditorGoogleFormsEmbed from "@components/editor/plugins/embeds/types/EditorGoogleFormsEmbed"
import EditorGoogleSheetsEmbed from "@components/editor/plugins/embeds/types/EditorGoogleSheetsEmbed"
import EditorGoogleSlidesEmbed from "@components/editor/plugins/embeds/types/EditorGoogleSlidesEmbed"
import EditorLoomEmbed from "@components/editor/plugins/embeds/types/EditorLoomEmbed"
import EditorMiroEmbed from "@components/editor/plugins/embeds/types/EditorMiroEmbed"
import EditorPdfEmbed from "@components/editor/plugins/embeds/types/EditorPdfEmbed"
import EditorQRCodeEmbed from "@components/editor/plugins/embeds/types/EditorQRCodeEmbed"
import EditorSCORMEmbed from "@components/editor/plugins/embeds/types/EditorScormEmbed"
import EditorTypeformEmbed from "@components/editor/plugins/embeds/types/EditorTypeformEmbed"
import EditorVimeoEmbed from "@components/editor/plugins/embeds/types/EditorVimeoEmbed"
import EditorWherebyEmbed from "@components/editor/plugins/embeds/types/EditorWherebyEmbed"
import EditorWistiaEmbed from "@components/editor/plugins/embeds/types/EditorWistiaEmbed"
import EditorYouTubeEmbed from "@components/editor/plugins/embeds/types/EditorYouTubeEmbed"
import { DiscoIconKinds } from "@disco-ui"
import {
  DecoratorBlockNode,
  SerializedDecoratorBlockNode,
} from "@lexical/react/LexicalDecoratorBlockNode"
import { ElementFormatType, LexicalNode, NodeKey, Spread } from "lexical"
import React from "react"

export type EmbedKind =
  | "airtable"
  | "calendly"
  | "discord"
  | "figma"
  | "google_docs"
  | "google_forms"
  | "google_sheets"
  | "google_slides"
  | "loom"
  | "miro"
  | "pdf"
  | "typeform"
  | "vimeo"
  | "whereby"
  | "youtube"
  | "scorm"
  | "generic_iframe"
  | "embed_code"
  | "qr_code"
  | "wistia"

export interface EmbedData {
  url?: string
  fileName?: string
  html?: string
  scormFileId?: GlobalID
  height?: string
}

export interface EmbedPayload {
  key?: NodeKey
  kind: EmbedKind
  data: EmbedData
}

export type SerializedEmbedNode = Spread<
  {
    kind: EmbedKind
    data: EmbedData
  },
  SerializedDecoratorBlockNode
>

export class EmbedNode extends DecoratorBlockNode {
  __kind: EmbedKind
  __data: EmbedData

  static getType(): string {
    return "embed"
  }

  static clone(node: EmbedNode): EmbedNode {
    return new EmbedNode(
      {
        kind: node.__kind,
        data: node.__data,
      },
      node.__key,
      node.__format
    )
  }

  static importJSON(serializedNode: SerializedEmbedNode): EmbedNode {
    const { kind, data } = serializedNode
    const node = $createEmbedNode({ kind, data })
    return node
  }

  constructor(embed: EmbedPayload, key?: NodeKey, format?: ElementFormatType) {
    super(format, key)
    this.__kind = embed.kind
    this.__data = embed.data
  }

  exportJSON(): SerializedEmbedNode {
    return {
      ...super.exportJSON(),
      version: 1,
      type: "embed",
      kind: this.getKind(),
      data: this.getData(),
    }
  }

  setData(data: EmbedData): void {
    const writable = this.getWritable()
    writable.__data = { ...writable.__data, ...data }
  }

  // View
  updateDOM(): false {
    return false
  }

  getKind(): EmbedKind {
    return this.__kind
  }

  getData(): EmbedData {
    return this.__data
  }

  decorate(): JSX.Element {
    return (
      <EditorEmbed nodeKey={this.getKey()} kind={this.getKind()} data={this.getData()} />
    )
  }
}

export function $createEmbedNode({ key, ...data }: EmbedPayload): EmbedNode {
  return new EmbedNode(data, key)
}

export function $isEmbedNode(node: LexicalNode | null | undefined): node is EmbedNode {
  return node instanceof EmbedNode
}

export const DEFAULT_EMBED_HEIGHT = "500px"

export interface EmbedConfig {
  title: string
  icon: DiscoIconKinds | React.ReactElement
  keywords: string[]
  validateSetup?: (data: EmbedData) => Error | null
  noResizeOnMobile?: boolean
  resizeData: {
    defaultHeight?: string
    width?: string
  } | null
  Component: React.FC<{ data: EmbedData; nodeKey: NodeKey }>
  SetupFormHelp: React.FC
  SetupForm: React.FC<{ data: EmbedData; setData: (data: EmbedData) => void }>
}

export const EMBED_CONFIG: Record<EmbedKind, EmbedConfig> = {
  embed_code: EditorEmbedCodeEmbed,
  generic_iframe: EditorGenericIframeEmbed,
  airtable: EditorAirtableEmbed,
  calendly: EditorCalendlyEmbed,
  discord: EditorDiscordEmbed,
  figma: EditorFigmaEmbed,
  google_docs: EditorGoogleDocsEmbed,
  google_forms: EditorGoogleFormsEmbed,
  google_sheets: EditorGoogleSheetsEmbed,
  google_slides: EditorGoogleSlidesEmbed,
  loom: EditorLoomEmbed,
  miro: EditorMiroEmbed,
  pdf: EditorPdfEmbed,
  typeform: EditorTypeformEmbed,
  vimeo: EditorVimeoEmbed,
  whereby: EditorWherebyEmbed,
  youtube: EditorYouTubeEmbed,
  scorm: EditorSCORMEmbed,
  qr_code: EditorQRCodeEmbed,
  wistia: EditorWistiaEmbed,
}
