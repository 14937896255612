import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { EmbedConfig } from "@components/editor/plugins/embeds/EmbedNode"
import EmbedReactEditorToolFormLabel from "@components/editor/plugins/embeds/EmbedReactEditorToolFormLabel"
import { DiscoFormControl, DiscoIcon, DiscoInput, DiscoText } from "@disco-ui"

const EditorEmbedCodeEmbed: EmbedConfig = {
  title: "Embed HTML",
  icon: "code",
  keywords: ["embed", "code", "html"],
  validateSetup: (data) => {
    if (!data.html?.trim()) return new Error("Please provide valid HTML code to embed")
    return null
  },
  resizeData: {
    defaultHeight: "500px",
    width: "100%",
  },
  Component({ data }) {
    const classes = useStyles()

    return (
      <div className={classes.container}>
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: data.html! }}
        />
      </div>
    )
  },
  SetupForm({ data, setData }) {
    return (
      <DiscoFormControl
        label={
          <EmbedReactEditorToolFormLabel
            icon={<DiscoIcon icon={"code"} height={18} width={18} />}
            label={"Embed HTML"}
          />
        }
      >
        <DiscoInput
          data-testid={"EditorEmbedCodeEmbed.SetupForm.Input"}
          placeholder={"<h1>Code me up!</h1>"}
          value={data.html}
          onChange={handleChangeHtml}
          multiline
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </DiscoFormControl>
    )

    function handleChangeHtml(e: React.ChangeEvent<HTMLInputElement>) {
      setData({ html: e.target.value })
    }
  },
  SetupFormHelp() {
    return (
      <DiscoText variant={"body-sm-500"} color={"text.secondary"}>
        {
          "Code submitted for embedding undergoes sanitization, modifications may occur for security."
        }
      </DiscoText>
    )
  },
}

const useStyles = makeUseStyles({
  container: {
    position: "relative",
    width: "100%",
  },
})

export default EditorEmbedCodeEmbed
