import { INTEGRATION_EMBED_PLACEHOLDER } from "@/apps/util/appUtil"
import MiroLogo from "@/core/ui/disco/icons/color-logo/logo-miro.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { EmbedConfig } from "@components/editor/plugins/embeds/EmbedNode"
import EmbedReactEditorToolFormLabel from "@components/editor/plugins/embeds/EmbedReactEditorToolFormLabel"
import {
  GENERICIFRAME_REGEX,
  GENERIC_IFRAME_HELP_TEXT,
} from "@components/editor/plugins/embeds/types/EditorGenericIframeEmbed"
import { DiscoFormControl, DiscoInput, DiscoText } from "@disco-ui"

const EditorMiroEmbed: EmbedConfig = {
  title: "Miro",
  icon: <MiroLogo height={18} width={18} />,
  keywords: ["miro", "embed"],
  validateSetup: (data) => {
    if (!GENERICIFRAME_REGEX.test(data.html || ""))
      return new Error("Please provide a valid iFrame code.")
    return null
  },
  resizeData: {
    defaultHeight: "500px",
    width: "100%",
  },
  Component({ data }) {
    const classes = useStyles()

    const matches =
      data.html?.match(GENERICIFRAME_REGEX) || data.url?.match(GENERICIFRAME_REGEX)
    if (!matches) return null

    return (
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: (data.html || data.url)! }}
        className={classes.iframeContainer}
      />
    )
  },
  SetupForm({ data, setData }) {
    return (
      <DiscoFormControl
        label={
          <EmbedReactEditorToolFormLabel
            icon={<MiroLogo height={18} width={18} />}
            label={"Miro iFrame Code"}
          />
        }
      >
        <DiscoInput
          placeholder={INTEGRATION_EMBED_PLACEHOLDER.miro}
          value={data.html}
          onChange={handleChangeHtml}
          multiline
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </DiscoFormControl>
    )

    function handleChangeHtml(e: React.ChangeEvent<HTMLInputElement>) {
      setData({ html: e.target.value })
    }
  },
  SetupFormHelp() {
    return (
      <DiscoText variant={"body-sm-500"} color={"text.secondary"}>
        {GENERIC_IFRAME_HELP_TEXT}
      </DiscoText>
    )
  },
}

const useStyles = makeUseStyles({
  iframeContainer: {
    width: "100%",
    height: "100%",
  },
})

export default EditorMiroEmbed
