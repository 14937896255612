import addMinutes from "date-fns/addMinutes"
import formatDate from "date-fns/format"
import parse from "date-fns/parse"
import startOfDay from "date-fns/startOfDay"
import startOfToday from "date-fns/startOfToday"
import {
  DATE_FORMAT,
  DAY_IN_S,
  HOUR_IN_MINS,
  HOUR_IN_S,
  MINUTE_IN_S,
} from "../../../../utils/time/timeConstants"
import { formatTimeStringTo12hFormatWithMeridiem } from "../../../input/time/util/timeInputUtils"
import { TimeDropdownOption } from "../TimeDropdown"
import { TIME_DROPDOWN_OPTIONS_INTERVAL } from "./timeDropdownConstants"

function generateTimeDropdownOptionFromStartTimeString(
  startTimeString: string,
  differenceInMinutes: number,
  startDate: Date
) {
  const startTimeDate = parse(
    formatTimeStringTo12hFormatWithMeridiem(startTimeString),
    DATE_FORMAT.LONG_TIME_FORMAT,
    startDate
  )
  const date = addMinutes(startTimeDate, differenceInMinutes)
  const timeString = formatDate(date, DATE_FORMAT.LONG_TIME_FORMAT)

  return {
    id: timeString,
    title: timeString,
    subtitle: generateTimeDropdownOptionSubtitle(differenceInMinutes),
    context: {
      date,
      differenceInMinutes,
    },
  }
}

function generateTimeDropdownOptionFromStartTimeDate(
  startTimeDate: Date,
  differenceInMinutes: number
): TimeDropdownOption {
  const date = addMinutes(startTimeDate, differenceInMinutes)
  const timeString = formatDate(date, DATE_FORMAT.LONG_TIME_FORMAT)

  return {
    value: timeString,
    title: timeString,
    context: {
      subtitle: generateTimeDropdownOptionSubtitle(differenceInMinutes),
      date,
      differenceInMinutes,
    },
  }
}

function generateTimeDropdownDates(
  startTimeDate: Date,
  {
    arrayLength,
    interval = TIME_DROPDOWN_OPTIONS_INTERVAL,
    startTimeOffsetInMins = 0,
  }: { arrayLength: number; interval?: number; startTimeOffsetInMins?: number }
) {
  return new Array(arrayLength)
    .fill(0)
    .map<TimeDropdownOption>((_item, index) =>
      generateTimeDropdownOptionFromStartTimeDate(
        startTimeDate,
        startTimeOffsetInMins + index * interval
      )
    )
}

function generateTimeDropdownOptions(options?: { startDate?: Date; startTime?: string }) {
  let dropdownOptions: TimeDropdownOption[] = []
  const { startDate, startTime } = options || {}

  if (startTime) {
    const startTimeDate = parse(
      formatTimeStringTo12hFormatWithMeridiem(startTime),
      DATE_FORMAT.LONG_TIME_FORMAT,
      startDate || new Date()
    )

    // First four options are separated by only 15 mins
    const firstHourOptions = generateTimeDropdownDates(startTimeDate, {
      arrayLength: 4,
      interval: TIME_DROPDOWN_OPTIONS_INTERVAL,
    })
      // Remove the first item as difference would be 0 mins from the startTimeDate
      .slice(1)

    // Remaining options are separated by TIME_DROPDOWN_OPTIONS_INTERVAL mins
    const remainingOptions = generateTimeDropdownDates(startTimeDate, {
      arrayLength: (24 * HOUR_IN_S) / (MINUTE_IN_S * TIME_DROPDOWN_OPTIONS_INTERVAL) - 3,
      startTimeOffsetInMins: HOUR_IN_MINS,
    })

    dropdownOptions = [...firstHourOptions, ...remainingOptions]
  } else if (startDate) {
    dropdownOptions = generateTimeDropdownDates(startOfDay(startDate), {
      arrayLength: DAY_IN_S / (MINUTE_IN_S * TIME_DROPDOWN_OPTIONS_INTERVAL),
    })
  } else {
    dropdownOptions = generateTimeDropdownDates(startOfToday(), {
      arrayLength: DAY_IN_S / (MINUTE_IN_S * TIME_DROPDOWN_OPTIONS_INTERVAL),
    })
  }

  return dropdownOptions.map((option) => ({
    ...option,
    subtitle: generateTimeDropdownOptionSubtitle(option.context!.differenceInMinutes),
  }))
}

function generateTimeDropdownOptionSubtitle(differenceInMinutes: number): string {
  return differenceInMinutes <= HOUR_IN_MINS
    ? `${differenceInMinutes} mins`
    : toHoursAndMinutes(differenceInMinutes)
}

function toHoursAndMinutes(totalMinutes: number) {
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60

  return `${hours}h ${minutes}m`
}

export {
  generateTimeDropdownOptionFromStartTimeDate,
  generateTimeDropdownOptionFromStartTimeString,
  generateTimeDropdownOptionSubtitle,
  generateTimeDropdownOptions,
}
