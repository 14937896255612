import { INTEGRATION_EMBED_PLACEHOLDER } from "@/apps/util/appUtil"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { EmbedConfig } from "@components/editor/plugins/embeds/EmbedNode"
import EmbedReactEditorToolFormLabel from "@components/editor/plugins/embeds/EmbedReactEditorToolFormLabel"
import { DiscoFormControl, DiscoIcon, DiscoInput, DiscoText } from "@disco-ui"

export const GENERICIFRAME_REGEX = /<iframe.*?src=".*?".*?>.*?<\/iframe>/i

export const GENERIC_IFRAME_HELP_TEXT = `Any valid iFrame code is accepted and will be rendered as-is. We recommend you include frameborder="0" and height="100%" properties on the iFrame.`

const EditorGenericIframeEmbed: EmbedConfig = {
  title: "Generic iFrame",
  icon: "iframe",
  keywords: ["embed", "iframe"],
  validateSetup: (data) => {
    if (!GENERICIFRAME_REGEX.test(data.html || ""))
      return new Error("Please provide a valid iFrame code.")
    return null
  },
  resizeData: {
    defaultHeight: "500px",
    width: "100%",
  },
  Component({ data }) {
    const classes = useStyles()

    const matches =
      data.html?.match(GENERICIFRAME_REGEX) || data.url?.match(GENERICIFRAME_REGEX)
    if (!matches) return null

    return (
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: (data.html || data.url)! }}
        className={classes.iframeContainer}
      />
    )
  },
  SetupForm({ data, setData }) {
    return (
      <DiscoFormControl
        label={
          <EmbedReactEditorToolFormLabel
            icon={<DiscoIcon icon={"iframe"} height={18} width={18} />}
            label={"iFrame Code"}
          />
        }
      >
        <DiscoInput
          placeholder={INTEGRATION_EMBED_PLACEHOLDER.custom_embed}
          value={data.html}
          onChange={handleChangeHtml}
          multiline
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </DiscoFormControl>
    )

    function handleChangeHtml(e: React.ChangeEvent<HTMLInputElement>) {
      setData({ html: e.target.value })
    }
  },
  SetupFormHelp() {
    return (
      <DiscoText variant={"body-sm-500"} color={"text.secondary"}>
        {GENERIC_IFRAME_HELP_TEXT}
      </DiscoText>
    )
  },
}

const useStyles = makeUseStyles((theme) => ({
  iframeContainer: {
    width: "100%",
    height: "100%",

    [theme.breakpoints.down("sm")]: {
      "& > iframe": {
        maxWidth: "100%",
      },
    },
  },
}))

export default EditorGenericIframeEmbed
