import { INTEGRATION_URL_PLACEHOLDER } from "@/apps/util/appUtil"
import GoogleDocsLogo from "@/core/ui/images/logos/google-docs.svg"
import { EmbedConfig } from "@components/editor/plugins/embeds/EmbedNode"
import EmbedReactEditorToolFormLabel from "@components/editor/plugins/embeds/EmbedReactEditorToolFormLabel"
import { DiscoFormControl, DiscoInput, DiscoText } from "@disco-ui"

const GOOGLE_DOCS_REGEX = /^https?:\/\/docs\.google\.com\/document\/(.*)$/i
const GOOGLE_DOCS_ID_MATCH_INDEX = 1

const EditorGoogleDocsEmbed: EmbedConfig = {
  title: "Google Docs",
  icon: <GoogleDocsLogo height={18} width={18} />,
  keywords: ["google", "docs", "embed"],
  validateSetup: (data) => {
    if (!GOOGLE_DOCS_REGEX.test(data.url || ""))
      return new Error("Please provide a valid Google Docs URL")
    return null
  },
  resizeData: {
    defaultHeight: "640px",
    width: "100%",
  },
  Component({ data }) {
    const matches = data.url?.match(GOOGLE_DOCS_REGEX)
    const googleDocsId = matches && matches[GOOGLE_DOCS_ID_MATCH_INDEX]

    if (!googleDocsId || !data.url) return null

    const qsParams = new URL(data.url || "").searchParams
    const height = qsParams.get("height") ?? "100%"
    const width = qsParams.get("width") ?? "100%"

    return (
      <iframe
        src={data.url.replace("/edit", "/preview")}
        title={"Google Docs"}
        frameBorder={"0"}
        allow={"autoplay; fullscreen; picture-in-picture"}
        height={height}
        width={width}
      />
    )
  },
  SetupForm({ data, setData }) {
    return (
      <DiscoFormControl
        label={
          <EmbedReactEditorToolFormLabel
            icon={<GoogleDocsLogo height={18} width={18} />}
            label={"Public Google Docs Link"}
          />
        }
      >
        <DiscoInput
          placeholder={INTEGRATION_URL_PLACEHOLDER.google_docs}
          value={data.url}
          onChange={handleChangeUrl}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </DiscoFormControl>
    )

    function handleChangeUrl(e: React.ChangeEvent<HTMLInputElement>) {
      setData({ url: e.target.value })
    }
  },
  SetupFormHelp() {
    return (
      <DiscoText variant={"body-sm-500"} color={"text.secondary"}>
        {
          "Copy the URL of the Google Doc from your browser's address bar and paste it above. In the Share settings, please ensure that Anyone with the link can view the document."
        }
      </DiscoText>
    )
  },
}

export default EditorGoogleDocsEmbed
