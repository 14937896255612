// Reference: https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/plugins/DraggableBlockPlugin/index.tsx
import makeUseStyles from "@assets/style/util/makeUseStyles"
import EditorTooltip from "@components/editor/components/EditorTooltip"
import { useLexicalEditorContext } from "@components/editor/LexicalEditorContext"
import BlockOptionsPopover from "@components/editor/plugins/block-actions/BlockOptionsPopover"
import {
  getBlockElement,
  getCollapsedMargins,
} from "@components/editor/plugins/block-actions/utils"
import { DiscoIcon, DiscoText } from "@disco-ui"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { eventFiles } from "@lexical/rich-text"
import { mergeRegister } from "@lexical/utils"
import { isHTMLElement } from "@utils/dom/domUtils"
import {
  $getNearestNodeFromDOMNode,
  $getNodeByKey,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  DRAGOVER_COMMAND,
  DROP_COMMAND,
  LexicalNode,
} from "lexical"
import { DragEvent as ReactDragEvent, useEffect, useRef } from "react"
import { createPortal } from "react-dom"

const SPACE = 4
const TARGET_LINE_HALF_HEIGHT = 2
const DRAG_DATA_FORMAT = "application/x-lexical-drag-block"
const TEXT_BOX_HORIZONTAL_PADDING = 28

function setDragImage(dataTransfer: DataTransfer, hoveredBlockElement: HTMLElement) {
  const { transform } = hoveredBlockElement.style

  // Remove dragImage borders
  hoveredBlockElement.style.transform = "translateZ(0)"
  dataTransfer.setDragImage(hoveredBlockElement, 0, 0)

  setTimeout(() => {
    hoveredBlockElement.style.transform = transform
  })
}

function setTargetLine(
  targetLineElem: HTMLElement,
  targetBlockElem: HTMLElement,
  mouseY: number,
  anchorElem: HTMLElement
) {
  const { top: targetBlockElemTop, height: targetBlockElemHeight } =
    targetBlockElem.getBoundingClientRect()
  const { top: anchorTop, width: anchorWidth } = anchorElem.getBoundingClientRect()

  const { marginTop, marginBottom } = getCollapsedMargins(targetBlockElem)
  let lineTop = targetBlockElemTop
  if (mouseY >= targetBlockElemTop) {
    lineTop += targetBlockElemHeight + marginBottom / 2
  } else {
    lineTop -= marginTop / 2
  }

  const top = lineTop - anchorTop - TARGET_LINE_HALF_HEIGHT
  const left = TEXT_BOX_HORIZONTAL_PADDING - SPACE

  targetLineElem.style.transform = `translate(${left}px, ${top}px)`
  targetLineElem.style.width = `${
    anchorWidth - (TEXT_BOX_HORIZONTAL_PADDING - SPACE) * 2
  }px`
  targetLineElem.style.opacity = ".4"
}

function hideTargetLine(targetLineElem: HTMLElement | null) {
  if (targetLineElem) {
    targetLineElem.style.opacity = "0"
    targetLineElem.style.transform = "translate(-10000px, -10000px)"
  }
}

interface BlockOptionsActionPluginProps {
  anchorElem: HTMLElement
  hoveredNode: LexicalNode | null
  hoveredBlockElement: HTMLElement | null
  setHoveredBlockElement: (elem: HTMLElement | null) => void
}

function BlockOptionsActionPlugin({
  anchorElem,
  hoveredNode,
  hoveredBlockElement,
  setHoveredBlockElement,
}: BlockOptionsActionPluginProps): JSX.Element {
  const [editor] = useLexicalComposerContext()

  const buttonRef = useRef<HTMLDivElement>(null)

  const { setIsBlockOptionsOpen } = useLexicalEditorContext()

  const targetLineRef = useRef<HTMLDivElement>(null)
  const isDraggingBlockRef = useRef<boolean>(false)

  useEffect(() => {
    function onDragover(event: DragEvent): boolean {
      if (!isDraggingBlockRef.current) {
        return false
      }
      const [isFileTransfer] = eventFiles(event)
      if (isFileTransfer) {
        return false
      }
      const { pageY, target } = event
      if (!isHTMLElement(target)) {
        return false
      }
      const targetBlockElem = getBlockElement(anchorElem, editor, event, true)
      const targetLineElem = targetLineRef.current
      if (targetBlockElem === null || targetLineElem === null) {
        return false
      }
      setTargetLine(targetLineElem, targetBlockElem, pageY, anchorElem)
      // Prevent default event to be able to trigger onDrop events
      event.preventDefault()
      return true
    }

    function onDrop(event: DragEvent): boolean {
      if (!isDraggingBlockRef.current) {
        return false
      }
      const [isFileTransfer] = eventFiles(event)
      if (isFileTransfer) {
        return false
      }
      const { target, dataTransfer, pageY } = event
      const dragData = dataTransfer?.getData(DRAG_DATA_FORMAT) || ""
      const draggedNode = $getNodeByKey(dragData)
      if (!draggedNode) {
        return false
      }
      if (!isHTMLElement(target)) {
        return false
      }
      const targetBlockElem = getBlockElement(anchorElem, editor, event, true)
      if (!targetBlockElem) {
        return false
      }
      const targetNode = $getNearestNodeFromDOMNode(targetBlockElem)
      if (!targetNode) {
        return false
      }
      if (targetNode === draggedNode) {
        return true
      }
      const targetBlockElemTop = targetBlockElem.getBoundingClientRect().top
      if (pageY >= targetBlockElemTop) {
        targetNode.insertAfter(draggedNode)
      } else {
        targetNode.insertBefore(draggedNode)
      }
      setHoveredBlockElement(null)

      return true
    }

    return mergeRegister(
      editor.registerCommand(
        DRAGOVER_COMMAND,
        (event) => {
          return onDragover(event)
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        DROP_COMMAND,
        (event) => {
          return onDrop(event)
        },
        COMMAND_PRIORITY_HIGH
      )
    )
  }, [anchorElem, editor, setHoveredBlockElement])

  function onDragStart(event: ReactDragEvent<HTMLDivElement>): void {
    const { dataTransfer } = event
    if (!dataTransfer || !hoveredBlockElement) {
      return
    }
    setDragImage(dataTransfer, hoveredBlockElement)
    let nodeKey = ""
    editor.update(() => {
      const node = $getNearestNodeFromDOMNode(hoveredBlockElement)
      if (node) {
        nodeKey = node.getKey()
      }
    })
    isDraggingBlockRef.current = true
    dataTransfer.setData(DRAG_DATA_FORMAT, nodeKey)
  }

  function onDragEnd(): void {
    isDraggingBlockRef.current = false
    hideTargetLine(targetLineRef.current)
  }

  const classes = useStyles()

  return (
    <>
      <EditorTooltip content={renderTooltipContent()} placement={"bottom"}>
        <div
          ref={buttonRef}
          tabIndex={-1}
          role={"button"}
          draggable
          className={classes.blockDragAction}
          onMouseUp={() => setIsBlockOptionsOpen(true)}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          data-testid={"BlockOptionsActionPlugin.DraggableBlock"}
        >
          <DiscoIcon icon={"reorder"} width={20} height={12} />
        </div>
      </EditorTooltip>
      {createPortal(
        <div ref={targetLineRef} className={classes.blockTargetLine} />,
        anchorElem
      )}
      {buttonRef.current && hoveredNode && (
        <BlockOptionsPopover anchorEl={buttonRef.current} hoveredNode={hoveredNode} />
      )}
    </>
  )

  function renderTooltipContent() {
    return (
      <>
        <DiscoText variant={"body-xs"}>
          <DiscoText component={"span"} variant={"body-xs-700"}>
            {"Drag"}
          </DiscoText>
          {" to move"}
        </DiscoText>
        <DiscoText variant={"body-xs"}>
          <DiscoText component={"span"} variant={"body-xs-700"}>
            {"Click"}
          </DiscoText>
          {" to open menu"}
        </DiscoText>
      </>
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  blockDragAction: {
    borderRadius: theme.measure.borderRadius.default,
    cursor: "grab",
    display: "flex",
    alignItems: "center",
    height: 28,

    "&:hover": {
      background: theme.palette.groovy.neutral[200],

      "& svg": {
        color: theme.palette.text.primary,
      },
    },
    "&:active": {
      cursor: "grabbing",
    },
  },
  blockTargetLine: {
    pointerEvents: "none",
    background: theme.palette.groovy.blue[300],
    height: "4px",
    position: "absolute",
    left: 0,
    top: 0,
    opacity: 0,
    willChange: "transform",
  },
}))

export default BlockOptionsActionPlugin
