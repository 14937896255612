import WriteWithAI from "@components/ai/AIEditorWrite"
import AIGenerateImage from "@components/ai/AIGenerateImage"
import { LexicalConfig } from "@components/editor/config/LexicalConfig"
import { AttachBlockNode } from "@components/editor/plugins/attach-block/AttachBlockNode"
import { ButtonNode } from "@components/editor/plugins/button/ButtonNode"
import { CalloutNode } from "@components/editor/plugins/callout/CalloutNode"
import { EmbedNode } from "@components/editor/plugins/embeds/EmbedNode"
import { EmbedUploadFileNode } from "@components/editor/plugins/embeds/EmbedUploadFileNode"
import { ImageNode } from "@components/editor/plugins/image/ImageNode"
import { ImageUploadFileNode } from "@components/editor/plugins/image/ImageUploadFileNode"
import { MentionNode } from "@components/editor/plugins/mentions/MentionNode"
import { TextPasteNode } from "@components/editor/plugins/paste/TextPasteNode"
import { PollNode } from "@components/editor/plugins/poll/PollNode"
import { VideoNode } from "@components/editor/plugins/video/VideoNode"
import { VideoUploadFileNode } from "@components/editor/plugins/video/VideoUploadFileNode"
import { DiscoIconKinds } from "@disco-ui"
import { CodeHighlightNode, CodeNode } from "@lexical/code"
import { AutoLinkNode, LinkNode } from "@lexical/link"
import { ListItemNode, ListNode } from "@lexical/list"
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode"
import { HeadingNode, QuoteNode } from "@lexical/rich-text"
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table"
import { ArrayUtils } from "@utils/array/arrayUtils"

type EditorBlockConfig = {
  title: string
  icon?: DiscoIconKinds
  badge?: React.ReactElement
}

export type EditorBlockType =
  | "paragraph"
  | "h1"
  | "h2"
  | "h3"
  | "number"
  | "bullet"
  | "check"
  | "quote"
  | "callout"
  | "code"
  | "divider"
  | "table"
  | "image"
  | "video"
  | "embed"
  | "button"
  | "poll"
  // AI
  | "aiGenerateImage"
  | "aiGenerateText"
  // Attach
  | "attachBlock"

export const EDITOR_BLOCKS: Record<EditorBlockType, EditorBlockConfig> = {
  paragraph: { title: "Text", icon: "text" },
  h1: { title: "Heading 1", icon: "h1" },
  h2: { title: "Heading 2", icon: "h2" },
  h3: { title: "Heading 3", icon: "h3" },
  number: { title: "Numbered List", icon: "numbered-list" },
  bullet: { title: "Bulleted List", icon: "list" },
  check: { title: "Check List", icon: "list" },
  quote: { title: "Quote", icon: "quote" },
  callout: { title: "Callout", icon: "callout" },
  code: { title: "Code Block", icon: "code" },
  divider: { title: "Divider", icon: "divider" },
  table: { title: "Table", icon: "table" },
  image: { title: "Image", icon: "image" },
  video: { title: "Video", icon: "video" },
  embed: { title: "Embed", icon: "code" },
  button: { title: "Button", icon: "cursor-click" },
  poll: { title: "Poll", icon: "chart-bar" },
  // // AI
  aiGenerateImage: {
    title: "Generate Image",
    badge: <AIGenerateImage />,
  },
  aiGenerateText: {
    title: "Write with AI",
    badge: <WriteWithAI />,
  },

  // Attach Blocks
  attachBlock: { title: "Attach", icon: "calendar" },
}

export function getConvertableBlocks(config: LexicalConfig): EditorBlockType[] {
  const { blocks } = config

  return [
    ...ArrayUtils.spreadIf(["paragraph"], blocks.has("paragraph")),
    ...ArrayUtils.spreadIf(["h1", "h2", "h3"], blocks.has("heading")),
    ...ArrayUtils.spreadIf(["number", "bullet"], blocks.has("list")),
    ...ArrayUtils.spreadIf(["code"], blocks.has("code")),
    ...ArrayUtils.spreadIf(["quote"], blocks.has("quote")),
    ...ArrayUtils.spreadIf(["callout"], blocks.has("callout")),
  ] as EditorBlockType[]
}

export function getNodesFromConfig(config: LexicalConfig) {
  const { blocks, inlineTools } = config

  return [
    ...ArrayUtils.spreadIf([HeadingNode], blocks.has("heading")),
    ...ArrayUtils.spreadIf([HorizontalRuleNode], blocks.has("divider")),
    ...ArrayUtils.spreadIf([ListNode, ListItemNode], blocks.has("list")),
    ...ArrayUtils.spreadIf([CodeNode], blocks.has("code")),
    ...ArrayUtils.spreadIf([QuoteNode], blocks.has("quote")),
    ...ArrayUtils.spreadIf([CalloutNode], blocks.has("callout")),
    ...ArrayUtils.spreadIf([TableNode, TableRowNode, TableCellNode], blocks.has("table")),
    ...ArrayUtils.spreadIf([ImageNode, ImageUploadFileNode], blocks.has("image")),
    ...ArrayUtils.spreadIf([VideoNode, VideoUploadFileNode], blocks.has("video")),
    ...ArrayUtils.spreadIf([PollNode], blocks.has("poll")),
    ...ArrayUtils.spreadIf([EmbedNode, EmbedUploadFileNode], blocks.has("embed")),
    ...ArrayUtils.spreadIf([ButtonNode], blocks.has("button")),
    ...ArrayUtils.spreadIf([LinkNode, AutoLinkNode], inlineTools.has("link")),
    ...ArrayUtils.spreadIf([CodeHighlightNode], inlineTools.has("code")),
    ...ArrayUtils.spreadIf(
      [MentionNode],
      inlineTools.has("attachBlockMention") || inlineTools.has("userMention")
    ),
    ...ArrayUtils.spreadIf([TextPasteNode], blocks.has("paragraph")),
    ...ArrayUtils.spreadIf([AttachBlockNode], blocks.has("attachBlock")),
  ]
}
