import LoomLogo from "@/core/ui/images/logos/loom.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { EmbedConfig } from "@components/editor/plugins/embeds/EmbedNode"
import EmbedReactEditorToolFormLabel from "@components/editor/plugins/embeds/EmbedReactEditorToolFormLabel"
import { DiscoFormControl, DiscoInput, DiscoText } from "@disco-ui"

const LOOM_REGEX = /(http|https)?:\/\/(www\.)?loom.com\/(?:share|embed)\/([a-zA-Z\d]+)/i
const VIDEO_ID_MATCH_INDEX = 3

const EditorLoomEmbed: EmbedConfig = {
  title: "Loom",
  icon: <LoomLogo height={18} width={18} />,
  keywords: ["loom", "embed", "video"],
  validateSetup: (data) => {
    if (!LOOM_REGEX.test(data.url || ""))
      return new Error("Please provide a valid Loom share or embed URL")
    return null
  },
  resizeData: null,
  Component({ data }) {
    const classes = useStyles()

    const matches = data.url?.match(LOOM_REGEX)
    const videoId = matches && matches[VIDEO_ID_MATCH_INDEX]
    if (!videoId) return null

    return (
      <div className={classes.videoContainer}>
        <iframe
          className={classes.videoFrame}
          src={`https://www.loom.com/embed/${videoId}`}
          title={"Loom Video"}
          frameBorder={"0"}
          allow={"autoplay; fullscreen; picture-in-picture"}
        />
      </div>
    )
  },
  SetupForm({ data, setData }) {
    return (
      <DiscoFormControl
        label={
          <EmbedReactEditorToolFormLabel
            icon={<LoomLogo height={18} width={18} />}
            label={"Loom Sharing Link"}
          />
        }
      >
        <DiscoInput
          placeholder={"https://www.loom.com/share/XXXXXXXXXXXX"}
          value={data.url}
          onChange={handleChangeUrl}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </DiscoFormControl>
    )

    function handleChangeUrl(e: React.ChangeEvent<HTMLInputElement>) {
      setData({ url: e.target.value })
    }
  },
  SetupFormHelp() {
    return (
      <DiscoText variant={"body-sm-500"} color={"text.secondary"}>
        {
          "Copy the URL of the Loom video from your browser's address bar and paste it above."
        }
      </DiscoText>
    )
  },
}

const useStyles = makeUseStyles({
  videoContainer: {
    position: "relative",
    width: "100%",
    height: 0,
    paddingBottom: "56.25%",
  },
  videoFrame: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    border: 0,
  },
})

export default EditorLoomEmbed
