import { AttachBlockConfig } from "@components/editor/plugins/attach-block/AttachBlockNode"
import EditorContentUsageAttachBlock from "@components/editor/plugins/attach-block/types/content-usage/EditorContentUsageAttachBlock"
import { DiscoIcon } from "@disco-ui"
import React from "react"

const EditorSurveyAttachBlock: AttachBlockConfig = {
  title: "Survey",
  icon: <DiscoIcon icon={"file-check"} height={18} width={18} />,
  keywords: ["survey", "link"],
  Component({ entityId, onClose }) {
    return React.createElement(EditorContentUsageAttachBlock.Component, {
      entityId,
      onClose,
      attachBlockEntity: "survey",
    })
  },
}

export default EditorSurveyAttachBlock
