// Reference: https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/nodes/ImageNode.tsx
import EditorButton, {
  EditorButtonComponent,
  EditorButtonLinkTo,
} from "@components/editor/plugins/button/EditorButton"
import {
  DecoratorBlockNode,
  SerializedDecoratorBlockNode,
} from "@lexical/react/LexicalDecoratorBlockNode"
import { ElementFormatType, LexicalNode, NodeKey, Spread } from "lexical"

export interface ButtonPayload {
  key?: NodeKey
  text: string
  linkTo: EditorButtonLinkTo
  component: EditorButtonComponent
}

export type SerializedButtonNode = Spread<
  {
    text: string
    linkTo: EditorButtonLinkTo
    component: EditorButtonComponent
  },
  SerializedDecoratorBlockNode
>

export class ButtonNode extends DecoratorBlockNode {
  __text: string
  __linkTo: EditorButtonLinkTo
  __component: EditorButtonComponent

  static getType(): string {
    return "button"
  }

  static clone(node: ButtonNode): ButtonNode {
    return new ButtonNode(
      {
        text: node.__text,
        linkTo: node.__linkTo,
        component: node.__component,
      },
      node.__key,
      node.__format
    )
  }

  static importJSON(serializedNode: SerializedButtonNode): ButtonNode {
    const { text, linkTo, component } = serializedNode
    const node = $createButtonNode({ text, linkTo, component })
    return node
  }

  constructor(button: ButtonPayload, key?: NodeKey, format?: ElementFormatType) {
    super(format, key)
    this.__text = button.text
    this.__linkTo = button.linkTo
    this.__component = button.component
  }

  exportJSON(): SerializedButtonNode {
    return {
      ...super.exportJSON(),
      version: 1,
      type: "button",
      text: this.__text,
      linkTo: this.__linkTo,
      component: this.__component,
    }
  }

  setText(text: string): void {
    const writable = this.getWritable()
    writable.__text = text
  }

  setLinkTo(linkTo: EditorButtonLinkTo): void {
    const writable = this.getWritable()
    writable.__linkTo = linkTo
  }

  setComponent(component: EditorButtonComponent): void {
    const writable = this.getWritable()
    writable.__component = component
  }

  // View
  updateDOM(): false {
    return false
  }

  getText(): string {
    return this.__text
  }

  getLinkTo(): EditorButtonLinkTo {
    return this.__linkTo
  }

  getComponent(): EditorButtonComponent {
    return this.__component
  }

  decorate(): JSX.Element {
    return (
      <EditorButton
        nodeKey={this.getKey()}
        text={this.getText()}
        linkTo={this.getLinkTo()}
        component={this.getComponent()}
      />
    )
  }
}

export function $createButtonNode({
  text,
  linkTo,
  component,
  key,
}: ButtonPayload): ButtonNode {
  return new ButtonNode({ text, linkTo, component }, key)
}

export function $isButtonNode(node: LexicalNode | null | undefined): node is ButtonNode {
  return node instanceof ButtonNode
}
