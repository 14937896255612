/* eslint-disable local-rules/disco-unused-classes */
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { EditorThemeClasses as LexicalEditorThemeClasses } from "lexical"

type EditorThemeClasses = LexicalEditorThemeClasses & {
  callout?: string
}

const LexicalTheme: EditorThemeClasses = {
  paragraph: "editor-paragraph",
  paragraphEmpty: "editor-paragraph-empty",
  text: {
    bold: "editor-bold",
    italic: "editor-italic",
    underline: "editor-underline",
    strikethrough: "editor-strikethrough",
    underlineStrikethrough: "editor-underline-strikethrough",
  },
  heading: {
    h1: "editor-heading editor-h1",
    h2: "editor-heading editor-h2",
    h3: "editor-heading editor-h3",
  },
  list: {
    listitem: "editor-list-item",
    listitemChecked: "editor-list-item-checked",
    listitemUnchecked: "editor-list-item-unchecked",
    nested: { listitem: "editor-nested-list-item" },
    ulDepth: [
      "editor-ul-depth-1",
      "editor-ul-depth-2",
      "editor-ul-depth-3",
      "editor-ul-depth-4",
      "editor-ul-depth-5",
      "editor-ul-depth-6",
    ],
    olDepth: [
      "editor-ol-depth-1",
      "editor-ol-depth-2",
      "editor-ol-depth-3",
      "editor-ol-depth-4",
      "editor-ol-depth-5",
      "editor-ol-depth-6",
    ],
  },
  link: "editor-link",
  quote: "editor-quote",
  code: "editor-code",
  codeHighlight: {
    atrule: "editor-token-attr",
    attr: "editor-token-attr",
    boolean: "editor-token-property",
    builtin: "editor-token-selector",
    cdata: "editor-token-comment",
    char: "editor-token-selector",
    class: "editor-token-function",
    "class-name": "editor-token-function",
    comment: "editor-token-comment",
    constant: "editor-token-property",
    deleted: "editor-token-property",
    doctype: "editor-token-comment",
    entity: "editor-token-operator",
    function: "editor-token-function",
    important: "editor-token-variable",
    inserted: "editor-token-selector",
    keyword: "editor-token-attr",
    namespace: "editor-token-cariable",
    number: "editor-token-property",
    operator: "editor-token-operator",
    prolog: "editor-token-comment",
    property: "editor-token-property",
    punctuation: "editor-token-punctuation",
    regex: "editor-token-variable",
    selector: "editor-token-selector",
    string: "editor-token-selector",
    symbol: "editor-token-property",
    tag: "editor-token-property",
    url: "editor-token-operator",
    variable: "editor-token-variable",
  },
  table: "editor-table",
  tableCell: "editor-table-cell",
  tableCellHeader: "editor-table-cell-header",
  callout: "editor-callout",
}

export const useLexicalThemeStyles = makeUseStyles((theme) => ({
  root: {
    // Paragraph block styles
    "& .editor-paragraph": {
      padding: theme.spacing(0.5, 0),
    },
    // Heading block styles
    "& .editor-heading": {
      fontFamily: "inherit",
      padding: theme.spacing(3, 0, 0.5),
    },
    "& .editor-h1, & .editor-h1 *": {
      ...theme.typography["heading-lg"],
    },
    "& .editor-h2, & .editor-h2 *": {
      ...theme.typography["heading-md"],
    },
    "& .editor-h3, & .editor-h3 *": {
      ...theme.typography["heading-sm"],
    },

    // List block styles
    "& .editor-list-item": {},
    "& .editor-nested-list-item": {
      listStyle: "none",
      marginLeft: theme.spacing(2),

      "&:before, &:after": {
        display: "none",
      },
    },
    // Padding applied to the first level of lists
    "& .editor-ol-depth-1, & .editor-ul-depth-1": {
      padding: theme.spacing(0.5, 1.5, 0.5, 3.5),
    },
    // Unordered list styles
    "& .editor-ul-depth-1, & .editor-ul-depth-4": {
      listStyle: "disc",
    },
    "& .editor-ul-depth-2, & .editor-ul-depth-5": {
      listStyle: "circle",
    },
    "& .editor-ul-depth-3, & .editor-ul-depth-6": {
      listStyle: "square",
    },
    // Ordered list styles
    "& .editor-ol-depth-1, & .editor-ol-depth-6": {
      listStyleType: "decimal",
    },
    "& .editor-ol-depth-2": {
      listStyleType: "upper-alpha",
    },
    "& .editor-ol-depth-3": {
      listStyleType: "lower-alpha",
    },
    "& .editor-ol-depth-4": {
      listStyleType: "upper-roman",
    },
    "& .editor-ol-depth-5": {
      listStyleType: "lower-roman",
    },
    // Check list styles
    "& .editor-list-item-checked, & .editor-list-item-unchecked": {
      position: "relative",
      margin: theme.spacing(0, 1),
      padding: theme.spacing(0, 3),
      listStyleType: "none",
      outline: "none",
      "&:before": {
        content: "''",
        width: "18px",
        height: "18px",
        position: "absolute",
        top: "4px",
        left: 0,
        cursor: "pointer",
        display: "block",
        backgroundSize: "cover",
      },
      "&:focus:before": {
        borderRadius: "50%",
        boxShadow: `0 0 0 1px ${theme.palette.groovy.neutral[200]}`,
      },
    },
    "& .editor-list-item-checked": {
      "&:before": {
        borderRadius: "50%",
        backgroundColor: theme.palette.primary.main,
        backgroundRepeat: "no-repeat",
      },
      "&:after": {
        content: "''",
        border: "2px solid #fff",
        cursor: "pointer",
        position: "absolute",
        display: "block",
        top: "10px",
        left: "5px",
        height: "4px",
        width: "9px",
        borderTop: "none",
        borderRight: "none",
        transform: "rotate(-45deg)",
        background: "transparent",
      },
    },
    "& .editor-list-item-unchecked": {
      "&:before": {
        borderRadius: "50%",
        border: `1px solid ${theme.palette.groovy.neutral[300]}`,
      },
    },

    // Link block styles
    "& .editor-link": {
      /**
       * for links we need to break anywhere because we may have very long substrings that are considered as one word
       * overflowWrap "anywhere" isn't supported by Safari, so use workBreak which has a slightly different behaviour.
       * From Moz: `word-break: break-all` will create a break at the exact place where text would otherwise overflow its container (even if putting an entire word on its own line would negate the need for a break)
       * https://developer.mozilla.org/en-US/docs/Web/CSS/word-break
       */
      wordBreak: "break-all",
      color: theme.palette.primary.main,
      cursor: "pointer",
      textDecoration: "underline",
    },

    // Horizontal rule styles
    "& hr": {
      border: "none",
      borderBottom: `1px solid ${theme.palette.groovy.neutral[300]}`,
      paddingTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },

    // Quote block styles
    "& .editor-quote": {
      margin: theme.spacing(0, 0, 1, 1.5),
      borderLeftColor: theme.palette.groovy.neutral[300],
      color: theme.palette.text.secondary,
      borderLeftWidth: 4,
      borderLeftStyle: "solid",
      paddingLeft: theme.spacing(2),
    },

    // Text block styles
    "& .editor-bold": {
      fontWeight: theme.typography.fontWeightBold,
    },
    "& .editor-italic": {
      fontStyle: "italic",
    },
    "& .editor-underline": {
      textDecoration: "underline",
    },
    "& .editor-strikethrough": {
      textDecoration: "line-through",
    },
    "& .editor-underline-strikethrough": {
      textDecoration: "underline line-through",
    },

    // Inline code styles
    "& code": {
      backgroundColor: theme.palette.groovy.neutral[200],
      color: "#b44437",
      padding: theme.spacing(0.25, 0.5),
    },

    // Code block styles
    "& .editor-code": {
      backgroundColor: theme.palette.groovy.neutral[200],
      fontFamily: "Menlo, Consolas, Monaco, monospace",
      display: "block",
      padding: theme.spacing(1, 1, 1, 5),
      margin: theme.spacing(1, 0),
      overflowX: "auto",
      position: "relative",
      tabSize: 2,
      "&, & span, & br": {
        lineHeight: 1.53,
        fontSize: "13px",
      },
      "&:before": {
        content: "attr(data-gutter)",
        position: "absolute",
        backgroundColor: theme.palette.groovy.neutral[200],
        left: 0,
        top: 0,
        borderRight: `1px solid ${theme.palette.groovy.neutral[300]}`,
        padding: theme.spacing(1),
        color: theme.palette.groovy.neutral[500],
        whiteSpace: "pre-wrap",
        textAlign: "right",
        minWidth: "25px",
      },
    },
    // Code highlight styles
    "& .editor-token-comment": {
      color: "slategray",
    },
    "& .editor-token-punctuation": {
      color: "#999",
    },
    "& .editor-token-property": {
      color: "#905",
    },
    "& .editor-token-selector": {
      color: "#690",
    },
    "& .editor-token-operator": {
      color: "#9a6e3a",
    },
    "& .editor-token-attr": {
      color: "#07a",
    },
    "& .editor-token-variable": {
      color: "#e90",
    },
    "& .editor-token-function": {
      color: "#dd4a68",
    },

    // Table block styles
    "& .editor-table": {
      borderCollapse: "collapse",
      borderSpacing: 0,
      overflowY: "scroll",
      overflowX: "scroll",
      tableLayout: "fixed",
      width: "max-content",
      margin: theme.spacing(3, 0),
    },
    "& .editor-table-cell": {
      border: `1px solid ${theme.palette.groovy.neutral[300]}`,
      width: "220px",
      minWidth: "75px",
      verticalAlign: "top",
      textAlign: "start",
      padding: theme.spacing(0.75, 1),
      position: "relative",
      outline: "none",
    },
    "& .editor-table-cell-header": {
      backgroundColor: theme.palette.groovy.neutral[200],
      textAlign: "start",
    },

    // Alert block styles
    "& .editor-callout": {
      padding: theme.spacing(2.5),
      borderRadius: theme.measure.borderRadius.big,
      margin: theme.spacing(1, 0),

      '&[data-callout-color="grey"]': {
        backgroundColor: theme.palette.groovy.neutral[200],
        ...styleIf(theme.palette.type === "dark", {
          backgroundColor: theme.palette.groovy.neutral[300],
        }),
      },
      '&[data-callout-color="orange"]': {
        backgroundColor: theme.palette.groovy.orange[100],
        ...styleIf(theme.palette.type === "dark", {
          backgroundColor: theme.palette.groovy.orange[700],
        }),
      },
      '&[data-callout-color="yellow"]': {
        backgroundColor: theme.palette.groovy.yellow[100],
        ...styleIf(theme.palette.type === "dark", {
          backgroundColor: theme.palette.groovy.yellow[700],
        }),
      },
      '&[data-callout-color="green"]': {
        backgroundColor: theme.palette.groovy.green[100],
        ...styleIf(theme.palette.type === "dark", {
          backgroundColor: theme.palette.groovy.green[700],
        }),
      },
      '&[data-callout-color="cyan"]': {
        backgroundColor: theme.palette.groovy.cyan[100],
        ...styleIf(theme.palette.type === "dark", {
          backgroundColor: theme.palette.groovy.cyan[700],
        }),
      },
      '&[data-callout-color="blue"]': {
        backgroundColor: theme.palette.groovy.blue[100],
        ...styleIf(theme.palette.type === "dark", {
          backgroundColor: theme.palette.groovy.blue[700],
        }),
      },
      '&[data-callout-color="purple"]': {
        backgroundColor: theme.palette.groovy.purple[100],
        ...styleIf(theme.palette.type === "dark", {
          backgroundColor: theme.palette.groovy.purple[700],
        }),
      },
      '&[data-callout-color="pink"]': {
        backgroundColor: theme.palette.groovy.pink[100],
        ...styleIf(theme.palette.type === "dark", {
          backgroundColor: theme.palette.groovy.pink[700],
        }),
      },
      '&[data-callout-color="red"]': {
        backgroundColor: theme.palette.groovy.red[100],
        ...styleIf(theme.palette.type === "dark", {
          backgroundColor: theme.palette.groovy.red[700],
        }),
      },
    },
  },
}))

export default LexicalTheme
