import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"

interface EmbedReactEditorToolFormLabelProps extends TestIDProps {
  icon: React.ReactNode
  label: string
}

function EmbedReactEditorToolFormLabel({
  icon,
  label,
}: EmbedReactEditorToolFormLabelProps) {
  const classes = useStyles()

  return (
    <div className={classes.formLabel}>
      {icon}
      <DiscoText variant={"body-md-500"}>{label}</DiscoText>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  formLabel: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}))

export default EmbedReactEditorToolFormLabel
