import GoogleSheetsLogo from "@/core/ui/images/logos/google-sheets.svg"
import { EmbedConfig } from "@components/editor/plugins/embeds/EmbedNode"
import EmbedReactEditorToolFormLabel from "@components/editor/plugins/embeds/EmbedReactEditorToolFormLabel"
import { DiscoFormControl, DiscoInput, DiscoText } from "@disco-ui"

const GOOGLE_SHEETS_REGEX = /^https?:\/\/docs\.google\.com\/spreadsheets\/(.*)$/i
const GOOGLE_SHEETS_ID_MATCH_INDEX = 1

const EditorGoogleSheetsEmbed: EmbedConfig = {
  title: "Google Sheets",
  icon: <GoogleSheetsLogo height={18} width={18} />,
  keywords: ["google", "sheets", "embed"],
  validateSetup: (data) => {
    if (!GOOGLE_SHEETS_REGEX.test(data.url || ""))
      return new Error("Please provide a valid Google Sheets URL")
    return null
  },
  resizeData: {
    defaultHeight: "640px",
    width: "100%",
  },
  Component({ data }) {
    const matches = data.url?.match(GOOGLE_SHEETS_REGEX)
    const googleSheetsId = matches && matches[GOOGLE_SHEETS_ID_MATCH_INDEX]

    if (!googleSheetsId || !data.url) return null

    const qsParams = new URL(data.url || "").searchParams
    const height = qsParams.get("height") ?? "100%"
    const width = qsParams.get("width") ?? "100%"

    return (
      <iframe
        src={data.url.replace("/edit", "/preview")}
        title={"Google Sheets"}
        frameBorder={"0"}
        allow={"autoplay; fullscreen; picture-in-picture"}
        height={height}
        width={width}
      />
    )
  },
  SetupForm({ data, setData }) {
    return (
      <DiscoFormControl
        label={
          <EmbedReactEditorToolFormLabel
            icon={<GoogleSheetsLogo height={18} width={18} />}
            label={"Public Google Sheets Link"}
          />
        }
      >
        <DiscoInput
          placeholder={"https://docs.google.com/spreadsheets/d/XXXXXXXXXXXX/edit"}
          value={data.url}
          onChange={handleChangeUrl}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </DiscoFormControl>
    )

    function handleChangeUrl(e: React.ChangeEvent<HTMLInputElement>) {
      setData({ url: e.target.value })
    }
  },
  SetupFormHelp() {
    return (
      <DiscoText variant={"body-sm-500"} color={"text.secondary"}>
        {
          "Copy the URL of the Google Sheet from your browser's address bar and paste it above. In the Share settings, please ensure that Anyone with the link can view the document."
        }
      </DiscoText>
    )
  },
}

export default EditorGoogleSheetsEmbed
