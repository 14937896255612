import { useFormStore } from "@/core/form/store/FormStore"
import PaidFeatureChip from "@/organization/entitlements/PaidFeatureChip"
import PaidFeatureTooltip from "@/organization/entitlements/PaidFeatureTooltip"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  EMBED_CONFIG,
  EmbedData,
  EmbedKind,
  EmbedPayload,
} from "@components/editor/plugins/embeds/EmbedNode"
import {
  EditorEmbedSetupModalMutation,
  SanitizeHtmlInput,
} from "@components/editor/plugins/embeds/__generated__/EditorEmbedSetupModalMutation.graphql"
import Form from "@components/form/Form"
import { DiscoButton, DiscoModal, DiscoText } from "@disco-ui"
import useHasEntitlement from "@utils/hook/useHasEntitlement"
import { TestIDProps } from "@utils/typeUtils"
import React, { useState } from "react"
import { graphql } from "relay-runtime"

interface EditorEmbedSetupModalProps extends TestIDProps {
  kind: EmbedKind
  initialData?: EmbedData
  onSubmit: (payload: EmbedPayload) => void
  onClose: () => void
}

function EditorEmbedSetupModal({
  onClose,
  initialData = {},
  kind,
  onSubmit,
}: EditorEmbedSetupModalProps) {
  const config = EMBED_CONFIG[kind]

  const [validationErrorMessage, setValidationErrorMessage] = useState("")
  const hasScormEntitlement = useHasEntitlement("scorm")

  const [data, setData] = useState<EmbedData>(initialData)

  const classes = useStyles()

  const form = useFormStore<EditorEmbedSetupModalMutation, SanitizeHtmlInput>(
    graphql`
      mutation EditorEmbedSetupModalMutation($input: SanitizeHtmlInput!) {
        response: sanitizeHtml(input: $input) {
          data {
            sanitizedHtml
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      html: "",
    },
    { requireChangeToSubmit: false }
  )

  // Unrecognized block kind - show nothing instead of an error
  if (!config) return null

  return (
    <DiscoModal
      isOpen
      onClose={onClose}
      modalContentLabel={`Setup ${config.title}`}
      title={
        <div className={classes.title}>
          <DiscoText variant={"body-lg-600"}>{`Setup ${config.title}`}</DiscoText>
          {kind === "scorm" && (
            <PaidFeatureChip testid={"EditorEmbedSetupModal"} entitlement={"scorm"} />
          )}
        </div>
      }
      testid={"EditorEmbedSetupModal.SetupModal"}
      width={"720px"}
      maxWidth={"90vw"}
      minHeight={"unset"}
      body={
        <>
          {React.createElement(config.SetupForm, { data, setData })}
          {React.createElement(config.SetupFormHelp)}
          <DiscoText variant={"body-sm-500"} marginTop={1} color={"error.main"}>
            {validationErrorMessage}
          </DiscoText>
        </>
      }
      buttons={
        <div className={classes.buttons}>
          <DiscoButton color={"grey"} variant={"outlined"} onClick={onClose}>
            {"Cancel"}
          </DiscoButton>
          {kind === "scorm" ? (
            <PaidFeatureTooltip entitlement={"scorm"}>
              <Form.SubmitButton
                form={form}
                onClick={handleSetup}
                disabled={!hasScormEntitlement}
              >
                {"Embed"}
              </Form.SubmitButton>
            </PaidFeatureTooltip>
          ) : (
            <Form.SubmitButton form={form} onClick={handleSetup}>
              {"Embed"}
            </Form.SubmitButton>
          )}
        </div>
      }
    />
  )

  async function handleSetup() {
    const { didSave, response } = await form.submit({ html: data.html || "" })
    if (!didSave || !response?.data) return

    data.html = response.data.sanitizedHtml

    if (config?.validateSetup) {
      const err = config.validateSetup(data)
      if (err) {
        setValidationErrorMessage(err.message)
        setTimeout(() => {
          setValidationErrorMessage("")
        }, 2000)
        return
      }
    }

    onSubmit({ kind, data })
    onClose()
  }
}

const useStyles = makeUseStyles((theme) => ({
  title: {
    display: "flex",
    gap: theme.spacing(1),
  },
  buttons: {
    display: "flex",
    gap: theme.spacing(2),
    justifyContent: "flex-end",
  },
}))

export default EditorEmbedSetupModal
