import { INTEGRATION_URL_PLACEHOLDER } from "@/apps/util/appUtil"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import TypeformLogo from "@/core/ui/images/logos/typeform.svg"
import {
  TYPEFORM_URL_FORM_ID_MATCH_INDEX,
  TYPEFORM_URL_REGEX,
} from "@/integrations/utils/constants"
import Relay from "@/relay/relayUtils"
import { EmbedConfig } from "@components/editor/plugins/embeds/EmbedNode"
import EmbedReactEditorToolFormLabel from "@components/editor/plugins/embeds/EmbedReactEditorToolFormLabel"
import { DiscoFormControl, DiscoInput, DiscoLink, DiscoText } from "@disco-ui"
import { createWidget } from "@typeform/embed"
import "@typeform/embed/build/css/widget.css"
import { useEffect, useRef } from "react"

const EditorTypeformEmbed: EmbedConfig = {
  title: "Typeform",
  icon: <TypeformLogo height={18} width={18} />,
  keywords: ["typeform", "embed", "form"],
  validateSetup: (data) => {
    if (!TYPEFORM_URL_REGEX.test(data.url || ""))
      return new Error("Please provide a valid Typeform URL")
    return null
  },
  resizeData: {
    defaultHeight: "500px",
    width: "100%",
  },
  Component({ data }) {
    const matches = data.url?.match(TYPEFORM_URL_REGEX)
    const formId = matches && matches[TYPEFORM_URL_FORM_ID_MATCH_INDEX]
    const { authUser } = useAuthUser()
    const activeOrganization = useActiveOrganization()
    const container = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
      if (container.current && formId) {
        createWidget(formId, {
          container: container.current,
          disableAutoFocus: true,
          inlineOnMobile: true,
          hidden: {
            disco_user_id: activeOrganization?.viewerMembership
              ? Relay.rawId(activeOrganization.viewerMembership.id)
              : "",
            disco_global_user_id: authUser ? Relay.rawId(authUser.id) : "",
            disco_global_encoded_user_id: authUser?.id || "",
            disco_user_first_name: authUser?.firstName || "",
            disco_user_last_name: authUser?.lastName || "",
          },
        })
      }
    }, [formId, authUser, activeOrganization])

    if (!formId) return null

    return <div ref={container} style={{ height: "100%" }} />
  },
  SetupForm({ data, setData }) {
    return (
      <DiscoFormControl
        label={
          <EmbedReactEditorToolFormLabel
            icon={<TypeformLogo height={24} width={24} />}
            label={"Typeform Sharing Link"}
          />
        }
      >
        <DiscoInput
          placeholder={INTEGRATION_URL_PLACEHOLDER.typeform}
          value={data.url}
          onChange={handleChangeUrl}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </DiscoFormControl>
    )

    function handleChangeUrl(e: React.ChangeEvent<HTMLInputElement>) {
      setData({ url: e.target.value })
    }
  },
  SetupFormHelp() {
    return (
      <>
        <DiscoText variant={"body-sm-500"} color={"text.secondary"}>
          {"Follow the "}
          <DiscoLink
            textVariant={"body-sm-500"}
            href={"https://www.typeform.com/help/a/embed-your-typeform-360029249212/"}
            target={"_blank"}
            rel={"noreferrer noopener"}
          >
            {"instructions in the Typeform Support Center"}
          </DiscoLink>
          {" to get an share link for your Typeform."}
        </DiscoText>
        <DiscoText variant={"body-sm-500"} color={"text.secondary"} marginTop={1}>
          {
            "Disco passes the following hidden variables to Typeform: disco_user_id, disco_user_first_name, disco_user_last_name. These are used to identify the user who submitted the form. If you want to use these variables, make sure to add them to your Typeform by following "
          }
          <DiscoLink
            textVariant={"body-sm-500"}
            href={"https://www.typeform.com/help/a/using-hidden-fields-360052676612/"}
            target={"_blank"}
            rel={"noreferrer noopener"}
          >
            {"Typeform's instructions"}
          </DiscoLink>
          {"."}
        </DiscoText>
      </>
    )
  },
}

export default EditorTypeformEmbed
