import makeUseStyles from "@assets/style/util/makeUseStyles"
import { useLexicalEditorContext } from "@components/editor/LexicalEditorContext"
import CalloutBlockOptions from "@components/editor/plugins/callout/CalloutBlockOptions"
import { $isCalloutNode } from "@components/editor/plugins/callout/CalloutNode"
import EmbedBlockOptions from "@components/editor/plugins/embeds/EmbedBlockOptions"
import { $isEmbedNode } from "@components/editor/plugins/embeds/EmbedNode"
import PollBlockOptions from "@components/editor/plugins/poll/PollBlockOptions"
import { $isPollNode } from "@components/editor/plugins/poll/PollNode"
import { DiscoIcon } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { Popover } from "@material-ui/core"
import { LexicalNode } from "lexical"
import { useCallback } from "react"

interface BlockOptionsPopoverProps {
  anchorEl: HTMLElement
  hoveredNode: LexicalNode
}

function BlockOptionsPopover({ anchorEl, hoveredNode }: BlockOptionsPopoverProps) {
  const { isBlockOptionsOpen, setIsBlockOptionsOpen } = useLexicalEditorContext()

  const [editor] = useLexicalComposerContext()

  const classes = useStyles()

  const handleDelete = useCallback(() => {
    editor.update(() => {
      hoveredNode.remove()
    })
  }, [hoveredNode, editor])

  return (
    <Popover
      anchorEl={anchorEl}
      open={isBlockOptionsOpen}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      classes={{ paper: classes.popover }}
      onClick={handleClose}
    >
      <ul role={"menu"}>
        {renderNodeSpecificOptions()}
        <DiscoDropdownItem
          title={"Delete"}
          onClick={() => handleDelete()}
          icon={<DiscoIcon icon={"trash"} />}
        />
      </ul>
    </Popover>
  )

  function handleClose() {
    setIsBlockOptionsOpen(false)
  }

  function renderNodeSpecificOptions() {
    if (!hoveredNode) return null

    if ($isCalloutNode(hoveredNode)) {
      return (
        <CalloutBlockOptions
          classes={classes}
          calloutNode={hoveredNode}
          onClose={handleClose}
        />
      )
    }

    if ($isEmbedNode(hoveredNode)) {
      return <EmbedBlockOptions embedNode={hoveredNode} />
    }

    if ($isPollNode(hoveredNode)) {
      return <PollBlockOptions pollNode={hoveredNode} />
    }

    return null
  }
}

const useStyles = makeUseStyles((theme) => ({
  popover: {
    minWidth: "200px",
    marginTop: theme.spacing(1),
    border: `1px solid ${theme.palette.groovy.neutral[300]}`,
    boxShadow: theme.palette.groovyDepths.raisedBoxShadow,
    borderRadius: theme.measure.borderRadius.big,
    padding: `${theme.spacing(0.5)}px !important`,
  },
}))

export default BlockOptionsPopover
