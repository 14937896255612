import { INTEGRATION_EMBED_PLACEHOLDER } from "@/apps/util/appUtil"
import WherebyLogo from "@/core/ui/disco/icons/color-logo/logo-whereby.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { EmbedConfig } from "@components/editor/plugins/embeds/EmbedNode"
import EmbedReactEditorToolFormLabel from "@components/editor/plugins/embeds/EmbedReactEditorToolFormLabel"
import {
  GENERICIFRAME_REGEX,
  GENERIC_IFRAME_HELP_TEXT,
} from "@components/editor/plugins/embeds/types/EditorGenericIframeEmbed"
import { DiscoFormControl, DiscoInput, DiscoText } from "@disco-ui"

const EditorWherebyEmbed: EmbedConfig = {
  title: "Whereby",
  icon: <WherebyLogo height={18} width={18} />,
  keywords: ["whereby", "embed"],
  validateSetup: (data) => {
    if (!GENERICIFRAME_REGEX.test(data.html || ""))
      return new Error("Please provide a valid iFrame code.")
    return null
  },
  resizeData: {
    defaultHeight: "500px",
    width: "100%",
  },
  Component({ data }) {
    const classes = useStyles()

    const matches =
      data.html?.match(GENERICIFRAME_REGEX) || data.url?.match(GENERICIFRAME_REGEX)
    if (!matches) return null

    return (
      <div className={classes.genericIframeContainer}>
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: (data.html || data.url)! }}
          style={{ height: "100%" }}
        />
      </div>
    )
  },
  SetupForm({ data, setData }) {
    return (
      <DiscoFormControl
        label={
          <EmbedReactEditorToolFormLabel
            icon={<WherebyLogo height={18} width={18} />}
            label={"Whereby iFrame Code"}
          />
        }
      >
        <DiscoInput
          placeholder={INTEGRATION_EMBED_PLACEHOLDER.whereby}
          value={data.html}
          onChange={handleChangeHtml}
          multiline
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </DiscoFormControl>
    )

    function handleChangeHtml(e: React.ChangeEvent<HTMLInputElement>) {
      setData({ html: e.target.value })
    }
  },
  SetupFormHelp() {
    return (
      <DiscoText variant={"body-sm-500"} color={"text.secondary"}>
        {GENERIC_IFRAME_HELP_TEXT}
      </DiscoText>
    )
  },
}

const useStyles = makeUseStyles({
  genericIframeContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
})

export default EditorWherebyEmbed
