/**
 * @generated SignedSource<<7f0b26743ed918686a0a9b707210af69>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentType = "assignment" | "assignment_submission" | "post" | "occurrence" | "comment" | "module" | "text" | "video" | "attachment" | "embed" | "quiz" | "custom" | "survey" | "confirmation" | "%future added value";
export type AttachBlockContentUsageSearchBodyQuery$variables = {
  id: string;
  first?: number | null;
  after?: string | null;
  search?: string | null;
  contentLabelIds?: ReadonlyArray<string> | null;
  contentTypes?: ReadonlyArray<ContentType> | null;
  excludeContentTypes?: ReadonlyArray<ContentType> | null;
};
export type AttachBlockContentUsageSearchBodyQuery$data = {
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"AttachBlockContentUsageSearchBody_PaginationFragment">;
  } | null;
};
export type AttachBlockContentUsageSearchBodyQuery = {
  variables: AttachBlockContentUsageSearchBodyQuery$variables;
  response: AttachBlockContentUsageSearchBodyQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contentLabelIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contentTypes"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "excludeContentTypes"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v8 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "contentLabelIds",
    "variableName": "contentLabelIds"
  },
  {
    "kind": "Variable",
    "name": "contentTypes",
    "variableName": "contentTypes"
  },
  {
    "kind": "Variable",
    "name": "excludeContentTypes",
    "variableName": "excludeContentTypes"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = [
  (v11/*: any*/),
  (v10/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customAppTitle",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AttachBlockContentUsageSearchBodyQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v7/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v8/*: any*/),
                "kind": "FragmentSpread",
                "name": "AttachBlockContentUsageSearchBody_PaginationFragment"
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v6/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "AttachBlockContentUsageSearchBodyQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v7/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "ContentUsageNodeConnection",
                "kind": "LinkedField",
                "name": "contentUsages",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContentUsageNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContentUsage",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "entity",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "viewerPermissions",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Content",
                            "kind": "LinkedField",
                            "name": "content",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "coverVideo",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "thumbnailUrl",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "showCoverPlayIcon",
                                "storageKey": null
                              },
                              (v11/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "entityId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Product",
                            "kind": "LinkedField",
                            "name": "product",
                            "plural": false,
                            "selections": (v12/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductApp",
                            "kind": "LinkedField",
                            "name": "productApp",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "kind",
                                "storageKey": null
                              },
                              (v13/*: any*/),
                              (v10/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Collection",
                            "kind": "LinkedField",
                            "name": "collection",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductApp",
                                "kind": "LinkedField",
                                "name": "app",
                                "plural": false,
                                "selections": [
                                  (v13/*: any*/),
                                  (v10/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v10/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Content",
                            "kind": "LinkedField",
                            "name": "module",
                            "plural": false,
                            "selections": (v12/*: any*/),
                            "storageKey": null
                          },
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfoObjectType",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v8/*: any*/),
                "filters": [
                  "search",
                  "contentLabelIds",
                  "contentTypes",
                  "excludeContentTypes"
                ],
                "handle": "connection",
                "key": "AttachBlockContentUsageSearchBody__contentUsages",
                "kind": "LinkedHandle",
                "name": "contentUsages"
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d5e0a72d7e876e2f28c802133a3ef414",
    "id": null,
    "metadata": {},
    "name": "AttachBlockContentUsageSearchBodyQuery",
    "operationKind": "query",
    "text": "query AttachBlockContentUsageSearchBodyQuery(\n  $id: ID!\n  $first: Int\n  $after: String\n  $search: String\n  $contentLabelIds: [ID!]\n  $contentTypes: [ContentType!]\n  $excludeContentTypes: [ContentType!]\n) {\n  organization: node(id: $id) {\n    __typename\n    ... on Organization {\n      ...AttachBlockContentUsageSearchBody_PaginationFragment_ri3hs\n    }\n    id\n  }\n}\n\nfragment AttachBlockContentUsageModalListItem_ContentUsageFragment on ContentUsage {\n  id\n  entity\n  viewerPermissions\n  content {\n    ...ContentThumbnail_ContentFragment\n    name\n    id\n  }\n  ...ContentUsageUtils_useContentUsageLocationLabelContentUsageFragment\n}\n\nfragment AttachBlockContentUsageSearchBody_PaginationFragment_ri3hs on Organization {\n  id\n  contentUsages(first: $first, after: $after, search: $search, contentLabelIds: $contentLabelIds, contentTypes: $contentTypes, excludeContentTypes: $excludeContentTypes) {\n    totalCount\n    edges {\n      node {\n        ...AttachBlockContentUsageModalListItem_ContentUsageFragment\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment ContentThumbnail_ContentFragment on Content {\n  id\n  type\n  coverVideo\n  thumbnailUrl\n  showCoverPlayIcon\n}\n\nfragment ContentUsageUtils_useContentUsageLocationLabelContentUsageFragment on ContentUsage {\n  id\n  entity\n  entityId\n  product {\n    name\n    id\n  }\n  productApp {\n    kind\n    customAppTitle\n    id\n  }\n  collection {\n    app {\n      customAppTitle\n      id\n    }\n    id\n  }\n  module {\n    name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "74c5ecb9434e5c41716f3375760b0b90";

export default node;
