import makeUseStyles from "@assets/style/util/makeUseStyles"
import EditorTooltip from "@components/editor/components/EditorTooltip"
import { DiscoIcon, DiscoText } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import {
  $createParagraphNode,
  $createTextNode,
  $getNearestNodeFromDOMNode,
  $isParagraphNode,
} from "lexical"
import { useCallback } from "react"

interface BlockAddActionPluginProps {
  hoveredBlockElement: HTMLElement | null
}

function BlockAddActionPlugin({ hoveredBlockElement }: BlockAddActionPluginProps) {
  const classes = useStyles()

  const [editor] = useLexicalComposerContext()

  const onClick = useCallback(() => {
    if (!hoveredBlockElement) return

    editor.update(() => {
      const node = $getNearestNodeFromDOMNode(hoveredBlockElement)
      if (!node) return

      const textNode = $createTextNode("/")

      if ($isParagraphNode(node) && node.isEmpty()) {
        node.append(textNode)
      } else {
        const newParagraph = $createParagraphNode()
        newParagraph.append(textNode)
        node.insertAfter(newParagraph)
      }

      textNode.select()
    })
  }, [editor, hoveredBlockElement])

  return (
    <EditorTooltip content={renderTooltipContent()} placement={"bottom"}>
      <DiscoContainerButton
        testid={`block-add-action-button`}
        className={classes.blockAddAction}
        onClick={onClick}
      >
        <DiscoIcon icon={"add"} width={28} height={20} />
      </DiscoContainerButton>
    </EditorTooltip>
  )

  function renderTooltipContent() {
    return (
      <DiscoText variant={"body-xs"}>
        <DiscoText component={"span"} variant={"body-xs-700"}>
          {"Click"}
        </DiscoText>
        {" to add block"}
      </DiscoText>
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  blockAddAction: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    borderRadius: theme.measure.borderRadius.default,
    height: 28,

    "&:hover": {
      background: theme.palette.groovy.neutral[200],

      "& svg": {
        color: theme.palette.text.primary,
      },
    },
  },
}))

export default BlockAddActionPlugin
