import AiApi, { AiApiGeneratePostArgs, AiApiGenerateTextArgs } from "@/common/AiApi"
import RestfulUtil from "@/core/restful/RestfulUtil"
import { EDITOR_CONFIG } from "@components/editor/config/LexicalConfig"
import { getNodesFromConfig } from "@components/editor/config/LexicalNodes"
import { EditorInstance } from "@components/editor/LexicalEditor"
import {
  displayErrorToast,
  displayRestfulErrorToast,
} from "@components/toast/ToastProvider"
import { createHeadlessEditor } from "@lexical/headless"
import { $convertFromMarkdownString, TRANSFORMERS } from "@lexical/markdown"
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $getSelection,
  $isElementNode,
  CreateEditorArgs,
  ElementFormatType,
  RootNode,
  SerializedEditorState,
  SerializedParagraphNode,
  SerializedTextNode,
  TextFormatType,
} from "lexical"

/**
 * Catch any errors that occur during Lexical updates and log them
 * or throw them as needed. If you don't throw them, Lexical will
 * try to recover gracefully without losing user data.
 */
function onError(error: any) {
  console.error(error)
}

namespace EditorUtils {
  /**
   * Create a new headless editor instance with the Disco theme and nodes.
   */
  export function createEditor(initialData?: SerializedEditorState | null) {
    const nodes = getNodesFromConfig(EDITOR_CONFIG.headless)

    const config: CreateEditorArgs = { namespace: "DiscoEditor", onError }
    const editor = createHeadlessEditor({ ...config, nodes })
    if (initialData) editor.setEditorState(editor.parseEditorState(initialData))
    return editor
  }

  type CreateParagraphInput = {
    text: string
    format?: TextFormatType
    align?: ElementFormatType
  }

  /**
   * Create new editor state with the given text in separate paragraphs.
   */
  export function createParagraphs(
    paragraphs: (string | CreateParagraphInput | CreateParagraphInput[])[],
    isMarkdown?: boolean
  ): SerializedEditorState {
    const editor = createEditor()

    editor.update(
      () => {
        const root = $getRoot()

        for (const paragraph of paragraphs) {
          // Standarize inputs to array of CreateParagraphInput
          let input: CreateParagraphInput[] = []
          if (typeof paragraph === "string") {
            input = [{ text: paragraph }]
          } else if (Array.isArray(paragraph)) {
            input = paragraph
          } else {
            input = [paragraph]
          }
          // Create the paragraph

          const paragraphNode = $createParagraphNode()
          root.append(paragraphNode)
          for (const text of input) {
            if (isMarkdown) {
              $convertFromMarkdownString(text.text, TRANSFORMERS)
            } else {
              const textNode = $createTextNode(text.text)
              if (text.format) {
                textNode.setFormat(text.format)
              }
              if (text.align) {
                paragraphNode.setFormat(text.align)
              }

              paragraphNode.append(textNode)
            }
          }
        }
      },
      { discrete: true }
    )

    return editor.getEditorState().toJSON()
  }

  /**
   * Helper to create more complex editor states
   */
  export function createData(constructData: (root: RootNode) => void) {
    const editor = createEditor()
    editor.update(
      () => {
        const root = $getRoot()
        constructData(root)
      },
      { discrete: true }
    )
    return editor.getEditorState().toJSON()
  }

  /**
   * Backend stores Lexical editor state JSON with keys in a different order due
   * to JSONB column behaviour, so we need to reorder the keys to match the
   * expected order to avoid unnecessary diffs.
   */
  export function getInitialEditorState(data: string | null | undefined) {
    if (!data) return data

    const parsed = JSON.parse(data)

    return JSON.stringify(
      createEditor(parsed as SerializedEditorState)
        .getEditorState()
        .toJSON()
    )
  }

  /**
   * Convert the editor state to plain text.
   */
  export function convertToPlainText(
    data: SerializedEditorState | null | undefined,
    opts: { trim?: boolean } = {}
  ) {
    if (!data) return ""

    // Trim any leading or trailing whitespace or new lines by default
    const { trim = true } = opts
    const editor = createEditor(data)

    let text = editor.getEditorState().read(() => {
      const root = $getRoot()

      /**
       * Not relying on root.getTextContent() because it adds two new lines between block
       * elements and we want to add only one new line.
       */
      let textContent = ""
      const children = root.getChildren()
      const childrenLength = children.length

      for (let i = 0; i < childrenLength; i++) {
        const child = children[i]
        textContent += child.getTextContent()
        // Add a new line between block elements
        if ($isElementNode(child) && i !== childrenLength - 1 && !child.isInline()) {
          textContent += "\n"
        }
      }
      return textContent
    })

    if (trim) text = text.trim()
    return text
  }

  /**
   * Check if the editor state is empty
   */
  export function isEmpty(richEditorContent: string | null | undefined) {
    if (!richEditorContent) return true
    const editorState = JSON.parse(richEditorContent) as SerializedEditorState

    for (const node of editorState.root.children) {
      // Check if there is a node that is not a paragraph
      if (node.type !== "paragraph") return false

      // If paragraph, check that it has any non-text nodes or non-whitespace text
      for (const child of (node as SerializedParagraphNode).children) {
        if (child.type !== "text") return false
        if ((child as SerializedTextNode).text.trim().length > 0) return false
      }
    }

    return true
  }

  export async function aiStreamToEditor(
    editor: EditorInstance,
    args: AiApiGenerateTextArgs | AiApiGeneratePostArgs,
    cancelledRef?: { current: boolean },
    setStatus?: (status: "loading" | "error" | "success" | null) => void
  ) {
    const controller = new AbortController()
    setStatus?.("loading")

    const response =
      "feedId" in args
        ? await AiApi.generatePost(args, {
            signal: controller.signal,
          })
        : await AiApi.generateText(args, {
            signal: controller.signal,
          })

    const hasError = RestfulUtil.hasError(response)
    if (hasError) {
      setStatus?.("error")
      await displayRestfulErrorToast(response)
      return
    }
    if (!response.ok || !response.body) {
      setStatus?.("error")
      displayErrorToast("An unexpected error occurred, please try again.")
      return
    }

    // Pipe response into the editor
    editor.focus(() => {
      editor.update(async () => {
        const selection = $getSelection()
        if (!selection) {
          setStatus?.("error")
          controller.abort()
          return
        }

        const paragraphNode = $createParagraphNode()
        selection.insertNodes([paragraphNode])

        let generatedText = ""
        await RestfulUtil.handleStream(response.body!, (decodedChunk) => {
          if (cancelledRef?.current === true) return false
          generatedText += decodedChunk
          editor.update(() => {
            $convertFromMarkdownString(generatedText, TRANSFORMERS, paragraphNode)
          })
          return true
        })

        if (generatedText.trim()) {
          setStatus?.("success")
        } else {
          displayErrorToast("Unable to generate anything based on the prompt")
          setStatus?.("error")
          controller.abort()
        }
      })
    })
  }
}

export default EditorUtils
