import VimeoLogo from "@/core/ui/images/logos/vimeo.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { EmbedConfig } from "@components/editor/plugins/embeds/EmbedNode"
import EmbedReactEditorToolFormLabel from "@components/editor/plugins/embeds/EmbedReactEditorToolFormLabel"
import { DiscoFormControl, DiscoInput, DiscoLink, DiscoText } from "@disco-ui"

const VIMEO_REGEX =
  /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|)(\d+)(?:|\/\?)/i
const VIDEO_ID_MATCH_INDEX = 4

const EditorVimeoEmbed: EmbedConfig = {
  title: "Vimeo",
  icon: <VimeoLogo height={18} width={18} />,
  keywords: ["vimeo", "embed", "video"],
  validateSetup: (data) => {
    if (!VIMEO_REGEX.test(data.url || ""))
      return new Error("Please provide a valid Vimeo video URL")
    return null
  },
  resizeData: null,
  Component({ data }) {
    const classes = useStyles()

    const matches = data.url?.match(VIMEO_REGEX)
    const videoId = matches && matches[VIDEO_ID_MATCH_INDEX]
    if (!videoId) return null

    return (
      <div className={classes.videoContainer}>
        <iframe
          className={classes.videoFrame}
          src={`https://player.vimeo.com/video/${videoId}?byline=0`}
          title={"Vimeo Video"}
          frameBorder={"0"}
          allow={"autoplay; fullscreen; picture-in-picture"}
        />
      </div>
    )
  },
  SetupForm({ data, setData }) {
    return (
      <DiscoFormControl
        label={
          <EmbedReactEditorToolFormLabel
            icon={<VimeoLogo height={18} width={18} />}
            label={"Vimeo Link"}
          />
        }
      >
        <DiscoInput
          placeholder={"https://vimeo.com/XXXXXXXXX"}
          value={data.url}
          onChange={handleChangeUrl}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </DiscoFormControl>
    )

    function handleChangeUrl(e: React.ChangeEvent<HTMLInputElement>) {
      setData({ url: e.target.value })
    }
  },
  SetupFormHelp() {
    return (
      <DiscoText variant={"body-sm-500"} color={"text.secondary"}>
        {"Follow the "}
        <DiscoLink
          textVariant={"body-sm-500"}
          href={
            "https://vimeo.zendesk.com/hc/en-us/articles/229326167-How-to-share-your-videos#:~:text=You%20can%20click%20the%20link,password%20to%20your%20recipients%20directly."
          }
          target={"_blank"}
          rel={"noreferrer noopener"}
        >
          {"instructions in the Vimeo Support Center"}
        </DiscoLink>
        {" to get a share link for your Vimeo video."}
      </DiscoText>
    )
  },
}

const useStyles = makeUseStyles({
  videoContainer: {
    position: "relative",
    width: "100%",
    height: 0,
    paddingBottom: "56.25%",
  },
  videoFrame: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    border: 0,
  },
})

export default EditorVimeoEmbed
