import makeUseStyles from "@assets/style/util/makeUseStyles"
import CircularProgressWithLabel from "@components/circular-progress-with-label/CircularProgressWithLabel"
import { $createImageNode } from "@components/editor/plugins/image/ImageNode"
import { $isImageUploadFileNode } from "@components/editor/plugins/image/ImageUploadFileNode"
import useMultipartUploadMediaToS3 from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { DiscoText } from "@disco-ui"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { $getNodeByKey, NodeKey } from "lexical"
import { useEffect } from "react"

interface EditorImageUploadFileProps extends TestIDProps {
  nodeKey: NodeKey
  file: File
}

function EditorImageUploadFile({ nodeKey, file }: EditorImageUploadFileProps) {
  const theme = useTheme()

  const [editor] = useLexicalComposerContext()

  // When a file is pasted/dropped in we need to upload it then replace the node with an image node
  const { uploadMediaToS3, uploadProgress } = useMultipartUploadMediaToS3()

  useEffect(() => {
    if (file) {
      uploadMediaToS3({ mediaFile: file }).then((result) => {
        editor.update(() => {
          const node = $getNodeByKey(nodeKey)
          if ($isImageUploadFileNode(node)) {
            const imageNode = $createImageNode({
              src: result.url,
              altText: result.name,
            })
            node.replace(imageNode)
          }
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const classes = useStyles()

  return (
    <div className={classes.processingContainer}>
      <CircularProgressWithLabel
        color={theme.palette.primary.main}
        value={uploadProgress ?? 0}
      />
      <div>
        <DiscoText variant={"body-md-600"}>{"Uploading Image..."}</DiscoText>
        <DiscoText variant={"body-sm"}>
          {"Your image is currently being uploaded"}
        </DiscoText>
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  processingContainer: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr",
    gap: theme.spacing(3),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.groovy.grey[100],
    borderRadius: theme.measure.borderRadius.big,
    alignItems: "center",
  },
}))

export default EditorImageUploadFile
