import AttachmentListItem, {
  AttachmentListItemProps,
} from "@/content/attachment/list/item/AttachmentListItem"
import PdfLogo from "@/core/ui/images/logos/pdf.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import FileDropzone, { PDF_FILE_TYPES } from "@components/dropzone/FileDropzone"
import { EmbedConfig } from "@components/editor/plugins/embeds/EmbedNode"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { DiscoFormControl, DiscoText } from "@disco-ui"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { useEffect, useState } from "react"

const EditorPdfEmbed: EmbedConfig = {
  title: "PDF Embed",
  icon: <PdfLogo height={18} width={18} />,
  keywords: ["pdf", "embed"],
  validateSetup: (data) => {
    if (!data.url) return new Error("Please choose a valid PDF file")
    return null
  },
  resizeData: {
    defaultHeight: "500px",
    width: "100%",
  },
  noResizeOnMobile: true,
  Component({ data }) {
    const isMobile = useIsMobile()
    if (!data.url || !data.fileName) return null

    // on iOS devices, viewing a PDF inside Safari is extremely broken,
    // so just link out to the PDF on smaller devices
    return isMobile ? (
      <AttachmentListItem
        testid={"EditorPdfEmbed.pdf-file-sm-down-screen"}
        attachment={{ name: data.fileName, mediaUrl: data.url }}
        isCompact
      />
    ) : (
      <embed src={data.url} type={"application/pdf"} width={"100%"} height={"100%"} />
    )
  },
  SetupForm({ data, setData }) {
    const [attachment, setAttachment] = useState<
      AttachmentListItemProps["attachment"] | null
    >(null)
    useEffect(() => {
      if (data.url && data.fileName) {
        setAttachment({ name: data.fileName, mediaUrl: data.url })
      } else {
        setAttachment(null)
      }
    }, [data.fileName, data.url])
    const classes = useStyles()

    return (
      <DiscoFormControl>
        {attachment ? (
          <AttachmentListItem
            testid={"EditorPdfEmbed.pdf-file"}
            attachment={attachment}
            onClickDelete={handleDelete}
            isCompact
          />
        ) : (
          <FileDropzone
            className={classes.dropzone}
            dropzoneOptions={{ accept: PDF_FILE_TYPES }}
            allowedFileTypes={["document"]}
            onUpload={handleUpload}
            onMediaSelect={handleUpload}
            message={"Upload or select a PDF file"}
            testid={"EditorPdfEmbed.drop-zone"}
            allowedMimeTypes={["application/pdf"]}
          />
        )}
      </DiscoFormControl>
    )

    function handleUpload(result: MediaResult) {
      setData({ url: result.url, fileName: result.name })
    }

    function handleDelete() {
      setData({ url: undefined, fileName: undefined })
    }
  },
  SetupFormHelp() {
    return (
      <DiscoText variant={"body-sm-500"} color={"text.secondary"}>
        {
          "Upload a PDF file or select one from your media library. The PDF will be embedded directly within the content."
        }
      </DiscoText>
    )
  },
}

const useStyles = makeUseStyles({
  dropzone: {
    width: "100%",
  },
})

export default EditorPdfEmbed
