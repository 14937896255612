import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { AttachBlockEntity } from "@components/editor/plugins/attach-block/AttachBlockNode"
import { AttachBlockToolbarState } from "@components/editor/plugins/attach-block/AttachBlockSelectModal"
import AttachBlockContentUsageModalListItem from "@components/editor/plugins/attach-block/types/content-usage/AttachBlockContentUsageModalListItem"
import { AttachBlockContentUsageSearchBodyPaginationQuery } from "@components/editor/plugins/attach-block/types/content-usage/__generated__/AttachBlockContentUsageSearchBodyPaginationQuery.graphql"
import { AttachBlockContentUsageSearchBodyQuery } from "@components/editor/plugins/attach-block/types/content-usage/__generated__/AttachBlockContentUsageSearchBodyQuery.graphql"
import { AttachBlockContentUsageSearchBody_PaginationFragment$key } from "@components/editor/plugins/attach-block/types/content-usage/__generated__/AttachBlockContentUsageSearchBody_PaginationFragment.graphql"
import { DiscoButton, DiscoSection, DiscoText, DiscoTooltip } from "@disco-ui"
import { Skeleton } from "@material-ui/lab"
import { TestIDProps } from "@utils/typeUtils"
import pluralize from "pluralize"
import { useEffect } from "react"
import { graphql, useLazyLoadQuery, usePaginationFragment } from "react-relay"

interface AttachBlockContentUsageSearchBodyProps extends TestIDProps {
  onClose: VoidFunction
  toolbarState: AttachBlockToolbarState
  onContentUsageSelect?: (attachBlockEntity: AttachBlockEntity, entityId: string) => void
  inline: boolean
  attachBlockEntity: AttachBlockEntity
}

function AttachBlockContentUsageSearchBody({
  onClose,
  toolbarState,
  onContentUsageSelect,
  inline,
  attachBlockEntity,
}: AttachBlockContentUsageSearchBodyProps) {
  const activeOrganization = useActiveOrganization()!
  const numberOfSearchResults = 5
  const { organization } = useLazyLoadQuery<AttachBlockContentUsageSearchBodyQuery>(
    graphql`
      query AttachBlockContentUsageSearchBodyQuery(
        $id: ID!
        $first: Int
        $after: String
        $search: String
        $contentLabelIds: [ID!]
        $contentTypes: [ContentType!]
        $excludeContentTypes: [ContentType!]
      ) {
        organization: node(id: $id) {
          ... on Organization {
            ...AttachBlockContentUsageSearchBody_PaginationFragment
              @arguments(
                first: $first
                after: $after
                search: $search
                contentLabelIds: $contentLabelIds
                contentTypes: $contentTypes
                excludeContentTypes: $excludeContentTypes
              )
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
      search: toolbarState.search,
      contentLabelIds: toolbarState.contentLabelId?.value
        ? [toolbarState.contentLabelId.value]
        : undefined,
      first: numberOfSearchResults,
      contentTypes: attachBlockEntity === "survey" ? ["survey"] : null,
      excludeContentTypes: attachBlockEntity === "survey" ? null : ["survey"],
    }
  )

  const { data, loadNext, hasNext, isLoadingNext, refetch } = usePaginationFragment<
    AttachBlockContentUsageSearchBodyPaginationQuery,
    AttachBlockContentUsageSearchBody_PaginationFragment$key
  >(
    graphql`
      fragment AttachBlockContentUsageSearchBody_PaginationFragment on Organization
      @refetchable(queryName: "AttachBlockContentUsageSearchBodyPaginationQuery")
      @argumentDefinitions(
        first: { type: "Int" }
        after: { type: "String" }
        contentLabelIds: { type: "[ID!]" }
        search: { type: "String" }
        contentTypes: { type: "[ContentType!]" }
        excludeContentTypes: { type: "[ContentType!]" }
      ) {
        id
        contentUsages(
          first: $first
          after: $after
          search: $search
          contentLabelIds: $contentLabelIds
          contentTypes: $contentTypes
          excludeContentTypes: $excludeContentTypes
        ) @connection(key: "AttachBlockContentUsageSearchBody__contentUsages") {
          __id
          totalCount
          edges {
            node {
              ...AttachBlockContentUsageModalListItem_ContentUsageFragment
              id
            }
          }
        }
      }
    `,
    organization
  )
  const contentUsages = Relay.connectionToArray(data?.contentUsages)

  const classes = useStyles()

  useEffect(() => {
    refetch(
      {
        first: numberOfSearchResults,
        search: toolbarState.search,
        ...toolbarState,
      },
      { fetchPolicy: "store-and-network" }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolbarState, toolbarState.search])

  function renderMoreCount() {
    if (!data?.contentUsages.totalCount) return null
    if (data.contentUsages.totalCount <= contentUsages.length) return null

    const totalMoreResults = data.contentUsages.totalCount - contentUsages.length

    const handleLoadMore = () => {
      if (hasNext && !isLoadingNext) {
        loadNext(numberOfSearchResults)
      }
    }
    if (isLoadingNext) return <AttachBlockContentUsageSearchBodySkeleton />

    return (
      <DiscoTooltip
        content={`(${totalMoreResults} more ${pluralize("items", totalMoreResults)})`}
      >
        <div className={classes.loadMoreContainer}>
          <DiscoButton color={"grey"} variant={"outlined"} onClick={handleLoadMore}>
            {"Load more"}
          </DiscoButton>
        </div>
      </DiscoTooltip>
    )
  }

  return (
    <DiscoSection className={classes.searchHits}>
      {contentUsages && contentUsages.length > 0 ? (
        contentUsages.map((usage) => (
          <AttachBlockContentUsageModalListItem
            key={usage.id}
            inline={inline}
            contentUsageKey={usage}
            onClose={handleClose}
            onContentUsageSelect={onContentUsageSelect}
            attachBlockEntity={attachBlockEntity}
          />
        ))
      ) : (
        <DiscoText className={classes.empty} variant={"body-sm"} color={"text.secondary"}>
          {"No contents"}
        </DiscoText>
      )}
      {renderMoreCount()}
    </DiscoSection>
  )
  function handleClose() {
    onClose()
  }
}

function AttachBlockContentUsageSearchBodySkeleton() {
  const classes = useStyles()

  return (
    <DiscoSection className={classes.searchHits}>
      <Skeleton
        className={classes.searchHit}
        variant={"text"}
        width={"95%"}
        height={80}
      />
      <Skeleton
        className={classes.searchHit}
        variant={"text"}
        width={"95%"}
        height={80}
      />
      <Skeleton
        className={classes.searchHit}
        variant={"text"}
        width={"95%"}
        height={80}
      />
      <Skeleton
        className={classes.searchHit}
        variant={"text"}
        width={"95%"}
        height={80}
      />
      <Skeleton
        className={classes.searchHit}
        variant={"text"}
        width={"95%"}
        height={80}
      />
    </DiscoSection>
  )
}

const useStyles = makeUseStyles((theme) => ({
  empty: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(1),
  },
  searchHits: {
    maxHeight: "250px",
    overflowY: "auto",
    padding: 0,
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
  },
  loadMoreContainer: {
    cursor: "pointer",
    textAlign: "center",
    padding: theme.spacing(1),
  },
  searchHit: {
    margin: theme.spacing(1),
  },
}))

export default Relay.withSkeleton({
  component: AttachBlockContentUsageSearchBody,
  skeleton: AttachBlockContentUsageSearchBodySkeleton,
})
