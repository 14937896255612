import makeUseStyles from "@assets/style/util/makeUseStyles"
import { useLexicalEditorContext } from "@components/editor/LexicalEditorContext"
import { $isImageNode } from "@components/editor/plugins/image/ImageNode"
import {
  RESIZE_END_COMMAND,
  RESIZE_START_COMMAND,
} from "@components/editor/plugins/text-placeholder/TextPlaceholderPlugin"
import EditorToolbar, {
  EditorToolbarGroup,
} from "@components/editor/plugins/toolbar/EditorToolbar"
import EditorToolbarTextAlignButton from "@components/editor/plugins/toolbar/EditorToolbarTextAlignButton"
import ResizableContainer from "@components/resizable/ResizableContainer"
import DiscoMediaModal from "@disco-ui/modal/DiscoMediaModal"
import DiscoImage from "@disco-ui/image/DiscoImage"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { Theme, useMediaQuery } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import {
  $getNodeByKey,
  COMMAND_PRIORITY_HIGH,
  DRAGSTART_COMMAND,
  ElementFormatType,
  NodeKey,
} from "lexical"
import { useEffect, useRef, useState } from "react"

interface EditorImageProps extends TestIDProps {
  nodeKey: NodeKey
  src: string
  altText: string
  alignment?: ElementFormatType
  widthPercentage?: number
}

function EditorImage({
  nodeKey,
  src,
  altText,
  alignment = "left",
  widthPercentage,
}: EditorImageProps) {
  const [isToolbarOpen, setIsToolbarOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const classes = useStyles({ widthPercentage, alignment, isToolbarOpen })
  const isXsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"))

  const imageRef = useRef<HTMLImageElement | null>(null)

  const { config, disableMediaModal } = useLexicalEditorContext()

  const [editor] = useLexicalComposerContext()
  const isEditable = editor.isEditable()

  useEffect(() => {
    return editor.registerCommand<DragEvent>(
      DRAGSTART_COMMAND,
      (event) => {
        if (event.target === imageRef.current) {
          return onDragStart(event, nodeKey)
        }
        return false
      },
      COMMAND_PRIORITY_HIGH
    )
  }, [editor, nodeKey])

  const setWidthPercentage = (percentage: number) => {
    editor.update(() => {
      const node = $getNodeByKey(nodeKey)
      if ($isImageNode(node)) {
        node.setWidthPercentage(percentage)
      }
    })
  }

  const setAlignment = (option: ElementFormatType) => {
    editor.update(() => {
      const node = $getNodeByKey(nodeKey)
      if ($isImageNode(node)) {
        node.setAlignment(option)
      }
    })
  }

  function handleAttachmentClick() {
    setIsModalOpen(true)
  }

  if (!isEditable) {
    return (
      <>
        <div className={classes.container}>
          <DiscoImage
            src={src}
            alt={altText}
            className={classes.image}
            onClick={disableMediaModal ? undefined : handleAttachmentClick}
          />
        </div>
        <DiscoMediaModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          src={src}
        />
      </>
    )
  }

  return (
    <div className={classes.container}>
      <ResizableContainer
        lockAspectRatio
        maxWidth={"100%"}
        minWidth={"25%"}
        size={
          widthPercentage
            ? {
                height: "auto",
                width: isXsDown ? "100%" : `${widthPercentage}%`,
              }
            : {
                height: "auto",
                width: "100%",
              }
        }
        onResizeStart={() => {
          editor.dispatchCommand(RESIZE_START_COMMAND, undefined)
        }}
        onResizeStop={(_e, _direction, ref) => {
          editor.dispatchCommand(RESIZE_END_COMMAND, undefined)
          const widthPercent = Math.round(
            (ref.clientWidth / ref!.parentElement!.clientWidth) * 100
          )
          setWidthPercentage(widthPercent)
        }}
      >
        <DiscoImage ref={imageRef} src={src} alt={altText} />
        {renderOptions()}
      </ResizableContainer>
    </div>
  )

  function renderOptions() {
    if (!config.inlineTools.has("alignment")) return null

    return (
      <EditorToolbar
        className={classes.toolbar}
        onDropdownOpen={() => setIsToolbarOpen(true)}
        onDropdownClose={() => setIsToolbarOpen(false)}
      >
        <EditorToolbarGroup>
          <EditorToolbarTextAlignButton alignment={alignment} onChange={setAlignment} />
        </EditorToolbarGroup>
      </EditorToolbar>
    )
  }
}

type StyleProps = {
  widthPercentage?: number
  alignment: ElementFormatType
  isToolbarOpen: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: ({ alignment }: StyleProps) => {
      switch (alignment) {
        case "center":
          return "center"
        case "right":
          return "flex-end"
        default:
          return "flex-start"
      }
    },

    // hide toolbar when not hovered or when toolbar is closed
    "& $toolbar": {
      opacity: ({ isToolbarOpen }: StyleProps) => (isToolbarOpen ? 1 : 0),
    },
    "&:hover $toolbar": {
      opacity: 1,
    },
  },
  toolbar: {
    position: "absolute",
    top: 4,
    right: 4,
  },
  image: ({ widthPercentage }: StyleProps) => ({
    height: "auto",
    width: widthPercentage ? `${widthPercentage}%` : "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  }),
}))

const TRANSPARENT_IMAGE =
  "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
const img = document.createElement("img")
img.src = TRANSPARENT_IMAGE

function onDragStart(event: DragEvent, nodeKey: NodeKey): boolean {
  const node = $getNodeByKey(nodeKey)
  if (!node) {
    return false
  }
  const { dataTransfer } = event
  if (!dataTransfer) {
    return false
  }
  dataTransfer.setData("text/plain", "_")
  dataTransfer.setDragImage(img, 0, 0)
  dataTransfer.setData(
    "application/x-lexical-drag",
    JSON.stringify({
      data: {
        key: node.getKey(),
        src: node.__src,
        altText: node.__altText,
        alignment: node.__alignment,
        widthPercentage: node.__widthPercentage,
      },
      type: "image",
    })
  )

  return true
}

export default EditorImage
