import CalendlyLogo from "@/core/ui/images/logos/calendly.svg"
import { EmbedConfig } from "@components/editor/plugins/embeds/EmbedNode"
import EmbedReactEditorToolFormLabel from "@components/editor/plugins/embeds/EmbedReactEditorToolFormLabel"
import { DiscoFormControl, DiscoInput, DiscoText } from "@disco-ui"
import { InlineWidget } from "react-calendly"

const CALENDLY_URL_REGEX = /https:\/\/([w.-]+\.)?calendly.com\/.+\/.+$/i

const EditorCalendlyEmbed: EmbedConfig = {
  title: "Calendly",
  icon: <CalendlyLogo height={18} width={18} />,
  keywords: ["calendly", "embed", "calendar"],
  validateSetup: (data) => {
    if (!CALENDLY_URL_REGEX.test(data.url || ""))
      return new Error("Please provide a valid Calendly event URL")
    return null
  },
  resizeData: {
    defaultHeight: "660px",
    width: "100%",
  },
  Component({ data }) {
    return <InlineWidget url={data.url!} styles={{ height: "100%", width: "100%" }} />
  },
  SetupForm({ data, setData }) {
    return (
      <DiscoFormControl
        label={
          <EmbedReactEditorToolFormLabel
            icon={<CalendlyLogo height={18} width={18} />}
            label={"Calendly Event Link"}
          />
        }
      >
        <DiscoInput
          placeholder={"https://calendly.com/XXXXXXXX/XXXXXXXX"}
          value={data.url}
          onChange={handleChangeUrl}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </DiscoFormControl>
    )

    function handleChangeUrl(e: React.ChangeEvent<HTMLInputElement>) {
      setData({ url: e.target.value })
    }
  },
  SetupFormHelp() {
    return (
      <DiscoText variant={"body-sm-500"} color={"text.secondary"}>
        {
          "To get the Calendly Event Link, open your Calendly account and click the 'Copy Link' next to the event you want to share on Disco."
        }
      </DiscoText>
    )
  },
}

export default EditorCalendlyEmbed
