import { INTEGRATION_URL_PLACEHOLDER } from "@/apps/util/appUtil"
import GoogleFormsIcon from "@/core/ui/disco/icons/color-logo/logo-google-form.svg"
import { EmbedConfig } from "@components/editor/plugins/embeds/EmbedNode"
import EmbedReactEditorToolFormLabel from "@components/editor/plugins/embeds/EmbedReactEditorToolFormLabel"
import { DiscoFormControl, DiscoInput, DiscoText } from "@disco-ui"

const GOOGLE_FORMS_REGEX = /^https?:\/\/docs\.google\.com\/forms\/(.*)$/i
const GOOGLE_FORMS_ID_MATCH_INDEX = 1

const EditorGoogleFormsEmbed: EmbedConfig = {
  title: "Google Forms",
  icon: <GoogleFormsIcon height={18} width={18} />,
  keywords: ["google", "forms", "embed"],
  validateSetup: (data) => {
    if (!GOOGLE_FORMS_REGEX.test(data.url || ""))
      return new Error("Please provide a valid Google Forms URL")
    return null
  },
  resizeData: {
    defaultHeight: "640px",
    width: "100%",
  },
  Component({ data }) {
    const matches = data.url?.match(GOOGLE_FORMS_REGEX)
    const formId = matches && matches[GOOGLE_FORMS_ID_MATCH_INDEX]

    if (!formId || !data.url) return null

    const qsParams = new URL(data.url || "").searchParams
    const height = qsParams.get("height") ?? "100%"
    const width = qsParams.get("width") ?? "100%"

    return (
      <iframe
        src={data.url.replace("/edit", "/viewform")}
        title={"Google Forms"}
        frameBorder={"0"}
        allow={"autoplay; fullscreen; picture-in-picture"}
        height={height}
        width={width}
      />
    )
  },
  SetupForm({ data, setData }) {
    return (
      <DiscoFormControl
        label={
          <EmbedReactEditorToolFormLabel
            icon={<GoogleFormsIcon height={18} width={18} />}
            label={"Public Google Forms Link"}
          />
        }
      >
        <DiscoInput
          placeholder={INTEGRATION_URL_PLACEHOLDER.google_forms}
          value={data.url}
          onChange={handleChangeUrl}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </DiscoFormControl>
    )

    function handleChangeUrl(e: React.ChangeEvent<HTMLInputElement>) {
      setData({ url: e.target.value })
    }
  },
  SetupFormHelp() {
    return (
      <DiscoText variant={"body-sm-500"} color={"text.secondary"}>
        {
          "Copy the URL of the Google Form from your browser's address bar and paste it above."
        }
      </DiscoText>
    )
  },
}

export default EditorGoogleFormsEmbed
