import YouTubeLogo from "@/core/ui/images/logos/youtube.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { EmbedConfig } from "@components/editor/plugins/embeds/EmbedNode"
import EmbedReactEditorToolFormLabel from "@components/editor/plugins/embeds/EmbedReactEditorToolFormLabel"
import { DiscoFormControl, DiscoInput, DiscoText } from "@disco-ui"

const YOUTUBE_REGEX =
  /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([a-zA-Z0-9_-]{11}).*$/i
const VIDEO_ID_MATCH_INDEX = 1

const EditorYouTubeEmbed: EmbedConfig = {
  title: "YouTube",
  icon: <YouTubeLogo height={18} width={18} />,
  keywords: ["youtube", "embed", "video"],
  validateSetup: ({ url }) => {
    if (!YOUTUBE_REGEX.test(url || ""))
      return new Error("Please provide a valid YouTube video URL")
    return null
  },
  resizeData: null,
  Component({ data }) {
    const classes = useStyles()

    const matches = data.url?.match(YOUTUBE_REGEX)
    const videoId = matches && matches[VIDEO_ID_MATCH_INDEX]
    if (!videoId) return null

    return (
      <div className={classes.videoContainer}>
        <iframe
          className={classes.videoFrame}
          src={`https://www.youtube.com/embed/${videoId}?modestbranding=1`}
          title={"Youtube Video"}
          frameBorder={"0"}
          allow={"fullscreen"}
        />
      </div>
    )
  },
  SetupForm({ data, setData }) {
    return (
      <DiscoFormControl
        label={
          <EmbedReactEditorToolFormLabel
            icon={<YouTubeLogo height={18} width={18} />}
            label={"Youtube Link"}
          />
        }
      >
        <DiscoInput
          placeholder={"https://www.youtube.com/watch?v=XXXXXXXXXXX"}
          value={data.url}
          onChange={handleChangeUrl}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </DiscoFormControl>
    )

    function handleChangeUrl(e: React.ChangeEvent<HTMLInputElement>) {
      setData({ url: e.target.value })
    }
  },
  SetupFormHelp() {
    return (
      <DiscoText variant={"body-sm-500"} color={"text.secondary"}>
        {
          "Copy the URL of the YouTube video from your browser's address bar and paste it above."
        }
      </DiscoText>
    )
  },
}

const useStyles = makeUseStyles({
  videoContainer: {
    position: "relative",
    width: "100%",
    height: 0,
    paddingBottom: "56.25%",
  },
  videoFrame: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    border: 0,
  },
})

export default EditorYouTubeEmbed
