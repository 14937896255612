// Reference: https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/plugins/DraggableBlockPlugin/index.tsx
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { useLexicalEditorContext } from "@components/editor/LexicalEditorContext"
import BlockAddActionPlugin from "@components/editor/plugins/block-actions/BlockAddActionPlugin"
import BlockOptionsActionPlugin from "@components/editor/plugins/block-actions/BlockOptionsActionPlugin"
import { getBlockElement } from "@components/editor/plugins/block-actions/utils"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { isHTMLElement } from "@utils/dom/domUtils"
import { $getNearestNodeFromDOMNode, LexicalEditor, LexicalNode } from "lexical"
import { useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"

const SPACE = 4
const BLOCK_ACTIONS_ID = "block-actions"

function isOnMenu(element: HTMLElement): boolean {
  return !!element.closest(`#${BLOCK_ACTIONS_ID}`)
}

function getBlockOffset(targetElem: HTMLElement) {
  if (targetElem instanceof HTMLHeadingElement) {
    return 16
  }
  if (targetElem instanceof HTMLQuoteElement) {
    return -6
  }
  if (targetElem instanceof HTMLHRElement) {
    return -4
  }

  return 0
}

function setMenuPosition(
  targetElem: HTMLElement | null,
  floatingElem: HTMLElement,
  anchorElem: HTMLElement
) {
  if (!targetElem) {
    floatingElem.style.opacity = "0"
    floatingElem.style.transform = "translate(-10000px, -10000px)"
    return
  }

  const blockOffset = getBlockOffset(targetElem)

  const targetRect = targetElem.getBoundingClientRect()
  const targetStyle = window.getComputedStyle(targetElem)
  const floatingElemRect = floatingElem.getBoundingClientRect()
  const anchorElementRect = anchorElem.getBoundingClientRect()

  const top =
    targetRect.top +
    (parseInt(targetStyle.lineHeight, 10) - floatingElemRect.height) / 2 -
    anchorElementRect.top +
    blockOffset

  const left = SPACE

  floatingElem.style.opacity = "1"
  floatingElem.style.transform = `translate(${left}px, ${top}px)`
}

function useBlockActionsMenu(
  editor: LexicalEditor,
  anchorElem: HTMLElement,
  isEditable: boolean
): JSX.Element {
  const scrollerElem = anchorElem.parentElement

  const { isBlockListOpen, isBlockOptionsOpen } = useLexicalEditorContext()

  const menuRef = useRef<HTMLDivElement>(null)

  const [hoveredNode, setHoveredNode] = useState<LexicalNode | null>(null)
  const [hoveredBlockElement, setHoveredBlockElement] = useState<HTMLElement | null>(null)

  useEffect(() => {
    function onMouseMove(event: MouseEvent) {
      const { target } = event

      if (isBlockOptionsOpen) return

      if (!isHTMLElement(target)) {
        setHoveredBlockElement(null)
        return
      }

      if (isOnMenu(target)) return

      const _draggableBlockElem = getBlockElement(anchorElem, editor, event)

      setHoveredBlockElement(_draggableBlockElem)
    }

    function onMouseLeave() {
      if (isBlockOptionsOpen) return

      setHoveredBlockElement(null)
    }

    scrollerElem?.addEventListener("mousemove", onMouseMove)
    scrollerElem?.addEventListener("mouseleave", onMouseLeave)

    return () => {
      scrollerElem?.removeEventListener("mousemove", onMouseMove)
      scrollerElem?.removeEventListener("mouseleave", onMouseLeave)
    }
  }, [scrollerElem, anchorElem, editor, isBlockOptionsOpen])

  useEffect(() => {
    editor.update(() => {
      if (!hoveredBlockElement) {
        setHoveredNode(null)
        return
      }
      const node = $getNearestNodeFromDOMNode(hoveredBlockElement)
      if (node?.getKey() === hoveredNode?.getKey()) return
      setHoveredNode(node)
    })
  }, [hoveredBlockElement, editor, hoveredNode, setHoveredNode])

  useEffect(() => {
    if (menuRef.current) {
      setMenuPosition(hoveredBlockElement, menuRef.current, anchorElem)
    }
  }, [anchorElem, hoveredBlockElement])

  const classes = useStyles()

  // If the editor is not editable, don't render the block actions menu
  if (!isEditable || isBlockListOpen) return <></>

  return createPortal(
    <div ref={menuRef} id={BLOCK_ACTIONS_ID} className={classes.blockActionsMenu}>
      <BlockAddActionPlugin hoveredBlockElement={hoveredBlockElement} />
      <BlockOptionsActionPlugin
        anchorElem={anchorElem}
        hoveredNode={hoveredNode}
        hoveredBlockElement={hoveredBlockElement}
        setHoveredBlockElement={setHoveredBlockElement}
      />
    </div>,
    anchorElem
  )
}

export default function BlockActionsPlugin({
  anchorElem = document.body,
}: {
  anchorElem?: HTMLElement
}): JSX.Element {
  const [editor] = useLexicalComposerContext()
  return useBlockActionsMenu(editor, anchorElem, editor._editable)
}

const useStyles = makeUseStyles((theme) => ({
  blockActionsMenu: {
    opacity: 0,
    position: "absolute",
    left: 0,
    top: theme.spacing(0.5),
    willChange: "transform",
    display: "flex",
    alignItems: "center",

    [theme.breakpoints.down("xs")]: {
      left: "-16px",
    },
  },
}))
