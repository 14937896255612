// Reference: https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/plugins/ComponentPickerPlugin/index.tsx
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import AIMagicWandIcon from "@components/ai/AIMagicWand"
import AITag from "@components/ai/AITag"
import { EditorBlockType } from "@components/editor/config/LexicalNodes"
import { useLexicalEditorContext } from "@components/editor/LexicalEditorContext"
import EditorGenerateAITextPopover from "@components/editor/plugins/ai/EditorGenerateAITextPopover"
import {
  AttachBlockEntity,
  ATTACH_BLOCK_CONFIG,
} from "@components/editor/plugins/attach-block/AttachBlockNode"
import AttachBlockSelectModal from "@components/editor/plugins/attach-block/AttachBlockSelectModal"
import BlockToolboxItem, {
  BlockToolboxOption,
} from "@components/editor/plugins/block-toolbox/BlockToolboxItem"
import EditorAddBlockButtons from "@components/editor/plugins/block-toolbox/EditorAddBlockButtons"
import { INSERT_BUTTON_COMMAND } from "@components/editor/plugins/button/ButtonPlugin"
import { EditorButtonLinkTo } from "@components/editor/plugins/button/EditorButton"
import { INSERT_CALLOUT_COMMAND } from "@components/editor/plugins/callout/CalloutPlugin"
import EditorEmbedSetupModal from "@components/editor/plugins/embeds/EditorEmbedSetupModal"
import { EmbedKind, EMBED_CONFIG } from "@components/editor/plugins/embeds/EmbedNode"
import { INSERT_EMBED_COMMAND } from "@components/editor/plugins/embeds/EmbedPlugin"
import EditorImageUploadModal from "@components/editor/plugins/image/EditorImageUploadModal"
import { MENTION_TRIGER } from "@components/editor/plugins/mentions/MentionsPlugin"
import EditorPollSetupModal from "@components/editor/plugins/poll/EditorPollSetupModal"
import EditorVideoUploadModal from "@components/editor/plugins/video/EditorVideoUploadModal"
import { DiscoText } from "@disco-ui"
import { $createCodeNode } from "@lexical/code"
import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND } from "@lexical/list"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { INSERT_HORIZONTAL_RULE_COMMAND } from "@lexical/react/LexicalHorizontalRuleNode"
import {
  LexicalTypeaheadMenuPlugin,
  useBasicTypeaheadTriggerMatch,
} from "@lexical/react/LexicalTypeaheadMenuPlugin"
import { $createHeadingNode, $createQuoteNode, HeadingTagType } from "@lexical/rich-text"
import { $setBlocksType } from "@lexical/selection"
import { INSERT_TABLE_COMMAND } from "@lexical/table"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { isE2ETest } from "@utils/e2e"
import useDisclosure from "@utils/hook/useDisclosure"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import classNames from "classnames"
import {
  $createParagraphNode,
  $createTextNode,
  $getNodeByKey,
  $getSelection,
  $isRangeSelection,
  $isTextNode,
  LexicalNode,
  TextNode,
} from "lexical"
import React, { MutableRefObject, useCallback, useMemo, useRef, useState } from "react"
import ReactDOM from "react-dom"

interface BlockToolboxPluginProps {
  anchorElem: HTMLDivElement
  defaultEmbedKind?: EmbedKind | null
  buttonLinkTo?: EditorButtonLinkTo
  addBlockButtonsAnchorRef?: MutableRefObject<HTMLDivElement | null>
}

function BlockToolboxPlugin({
  anchorElem,
  buttonLinkTo = "dashboard",
  defaultEmbedKind = null,
  addBlockButtonsAnchorRef,
}: BlockToolboxPluginProps): JSX.Element {
  const [editor] = useLexicalComposerContext()

  const [queryString, setQueryString] = useState<string | null>(null)
  const [raiseMenuHeight, setRaiseMenuHeight] = useState(false)
  const classes = usePopoverStyles()
  const activeProduct = useActiveProduct()
  const activeOrganization = useActiveOrganization()!

  const isManager =
    (activeProduct?.viewerIsManagerOrInstructor ||
      activeOrganization?.viewerIsOwnerOrAdmin) &&
    !(activeProduct?.isAdminViewingAsMember || activeOrganization?.isAdminViewingAsMember)

  const checkForTriggerMatch = useBasicTypeaheadTriggerMatch("/", {
    minLength: 0,
  })

  const { setIsBlockListOpen, config } = useLexicalEditorContext()
  const [targetNode, setTargetNode] = useState<LexicalNode | null>(null)
  const [attachBlockEntity, setAttachBlockEntity] = useState<AttachBlockEntity>("product")
  const [attachBlockInline, setAttachBlockInline] = useState(false)

  const {
    isOpen: isAttachBlockSelectOpen,
    onOpen: onAttachBlockSelectOpen,
    onClose: onAttachBlockSelectClose,
  } = useDisclosure()
  const {
    isOpen: isImageUploadOpen,
    onOpen: onImageUploadOpen,
    onClose: onImageUploadClose,
  } = useDisclosure()
  const {
    isOpen: isVideoUploadOpen,
    onOpen: onVideoUploadOpen,
    onClose: onVideoUploadClose,
  } = useDisclosure()
  const {
    isOpen: isPollSetupOpen,
    onOpen: onPollSetupOpen,
    onClose: onPollSetupClose,
  } = useDisclosure()
  const {
    isOpen: isGenerateImageOpen,
    onOpen: onGenerateImageOpen,
    onClose: onGenerateImageClose,
  } = useDisclosure()
  const {
    isOpen: isGenerateTextOpen,
    onOpen: onGenerateTextOpen,
    onClose: onGenerateTextClose,
  } = useDisclosure()

  const [setupEmbedKind, setSetupEmbedKind] = useState<EmbedKind | null>(defaultEmbedKind)

  const onMenuClose = useCallback(() => {
    if (!targetNode) return
    setRaiseMenuHeight(false)

    editor.update(() => {
      const node = $getNodeByKey(targetNode.getKey())
      if ($isTextNode(node) && node.__text === "/") {
        node.setTextContent("")
      }
    })

    setTargetNode(null)
  }, [editor, targetNode])

  const onMenuOpen = useCallback(
    (popoverRef) => {
      setIsBlockListOpen(true)
      setTimeout(() => {
        if (popoverRef?.current) {
          const anchorRect = popoverRef.current.getBoundingClientRect()
          const spaceBelow = window.innerHeight - anchorRect.bottom
          setRaiseMenuHeight(spaceBelow < 0) // Determine if the popoverRef is too close to the bottom
        }
      }, 20)
    },
    [setIsBlockListOpen]
  )

  // Paragraph Block
  const paragraph = new BlockToolboxOption("paragraph", {
    keywords: ["normal", "paragraph", "p", "text"],
    onSelect: () =>
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
          $setBlocksType(selection, () => $createParagraphNode())
        }
      }),
  })

  // Heading Blocks
  const headings = ["h1", "h2", "h3"].map(
    (headingSize) =>
      new BlockToolboxOption(headingSize as EditorBlockType, {
        keywords: ["heading", "header", `heading${headingSize[1]}`, headingSize],
        onSelect: () =>
          editor.update(() => {
            const selection = $getSelection()
            if ($isRangeSelection(selection)) {
              $setBlocksType(selection, () =>
                $createHeadingNode(headingSize as HeadingTagType)
              )
            }
          }),
      })
  )

  // List Blocks
  const lists = [
    new BlockToolboxOption("number", {
      keywords: ["numbered list", "ordered list", "ol"],
      onSelect: () => editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined),
    }),
    new BlockToolboxOption("bullet", {
      keywords: ["bulleted list", "unordered list", "ul"],
      onSelect: () => editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined),
    }),
  ]

  // Table Block
  const table = new BlockToolboxOption("table", {
    keywords: ["table", "grid", "spreadsheet", "rows", "columns"],
    onSelect: () =>
      editor.dispatchCommand(INSERT_TABLE_COMMAND, {
        columns: "2",
        rows: "2",
        includeHeaders: false,
      }),
  })

  // Quote Block
  const quote = new BlockToolboxOption("quote", {
    keywords: ["block quote"],
    onSelect: () =>
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
          $setBlocksType(selection, () => $createQuoteNode())
        }
      }),
  })

  // Divider Block
  const divider = new BlockToolboxOption("divider", {
    keywords: ["horizontal rule", "divider", "hr", "delimiter"],
    onSelect: () => editor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, undefined),
  })

  // Callout Block
  const callout = new BlockToolboxOption("callout", {
    keywords: ["alert", "callout", "info"],
    onSelect: () => editor.dispatchCommand(INSERT_CALLOUT_COMMAND, undefined),
  })

  // Code Block
  const code = new BlockToolboxOption("code", {
    keywords: ["javascript", "python", "js", "codeblock", "code"],
    onSelect: () => {
      editor.update(() => {
        const selection = $getSelection()

        if ($isRangeSelection(selection)) {
          if (selection.isCollapsed()) {
            $setBlocksType(selection, () => $createCodeNode())
          } else {
            const textContent = selection.getTextContent()
            const codeNode = $createCodeNode()
            selection.insertNodes([codeNode])
            selection.insertRawText(textContent)
          }
        }
      })
    },
  })

  // Image Block
  const image = new BlockToolboxOption("image", {
    keywords: ["image", "photo", "picture"],
    onSelect: () => onImageUploadOpen(),
  })

  // Video Block
  const video = new BlockToolboxOption("video", {
    keywords: ["video", "movie", "film"],
    onSelect: () => onVideoUploadOpen(),
  })

  // Poll Block
  const poll = new BlockToolboxOption("poll", {
    keywords: ["poll", "survey", "vote"],
    onSelect: () => onPollSetupOpen(),
  })

  // Button Block
  const button = new BlockToolboxOption("button", {
    keywords: ["button", "link"],
    onSelect: () => {
      switch (buttonLinkTo) {
        case "profile":
          editor.dispatchCommand(INSERT_BUTTON_COMMAND, {
            text: "View on Profile",
            linkTo: "profile",
            component: "cta_button",
          })
          return
        default:
          editor.dispatchCommand(INSERT_BUTTON_COMMAND, {
            text: "See More",
            linkTo: "dashboard",
            component: "cta_button",
          })
      }
    },
  })

  // Embed Blocks
  const embeds = (Object.keys(EMBED_CONFIG) as EmbedKind[]).map((kind) => {
    const embedConfig = EMBED_CONFIG[kind]
    return new BlockToolboxOption("embed", {
      title: embedConfig.title,
      icon: embedConfig.icon,
      keywords: embedConfig.keywords,
      onSelect: () => setSetupEmbedKind(kind),
    })
  })

  // AI blocks
  // Generate image Block
  const aiGenerateImage = new BlockToolboxOption("aiGenerateImage", {
    keywords: ["imagine", "ai", "photo", "picture"],
    onSelect: () => onGenerateImageOpen(),
  })
  // Generate text Block
  const aiGenerateText = new BlockToolboxOption("aiGenerateText", {
    keywords: ["generate", "ai"],
    onSelect: () => onGenerateTextOpen(),
  })

  const { surveys } = useFeatureFlags()
  const attachBlockEntities = useMemo(() => {
    const entities = Object.keys(ATTACH_BLOCK_CONFIG) as AttachBlockEntity[]
    if (surveys) return entities
    return entities.filter((e) => e !== "survey")
  }, [surveys])

  // Attach Blocks
  const attachBlocks = attachBlockEntities.map((entity) => {
    const attachBlockConfig = ATTACH_BLOCK_CONFIG[entity]
    return new BlockToolboxOption("attachBlock", {
      title: `Attach ${attachBlockConfig.title}`,
      icon: attachBlockConfig.icon,
      keywords: [...attachBlockConfig.keywords, "attach"],
      onSelect: () => {
        setAttachBlockEntity(entity)
        setAttachBlockInline(false)
        if (anchorElem) {
          onAttachBlockSelectOpen()
        }
      },
    })
  })
  // Inline content/product/event mention
  const attachMentionInline = attachBlockEntities.map((entity) => {
    const attachBlockConfig = ATTACH_BLOCK_CONFIG[entity]
    return new BlockToolboxOption("attachBlock", {
      title: `Mention ${attachBlockConfig.title}`,
      icon: attachBlockConfig.icon,
      keywords: [...attachBlockConfig.keywords, "mention"],
      onSelect: () => {
        let mentionTrigger = MENTION_TRIGER.content
        switch (entity) {
          case "occurrence":
            mentionTrigger = MENTION_TRIGER.event
            break
          case "product":
            mentionTrigger = MENTION_TRIGER.product
            break
        }
        editor.update(() => {
          const textNode = $createTextNode(mentionTrigger)
          const selection = $getSelection()
          if (selection) selection.insertText(" ")
          selection?.insertNodes([textNode])
          textNode.select()
        })
      },
    })
  })

  const options = useMemo(() => {
    const basicBlocks = [
      ...ArrayUtils.spreadIf([paragraph], config.blocks.has("paragraph")),
      ...ArrayUtils.spreadIf(headings, config.blocks.has("heading")),
      ...ArrayUtils.spreadIf(lists, config.blocks.has("list")),
      ...ArrayUtils.spreadIf([table], config.blocks.has("table")),
      ...ArrayUtils.spreadIf([quote], config.blocks.has("quote")),
      ...ArrayUtils.spreadIf([divider], config.blocks.has("divider")),
      ...ArrayUtils.spreadIf([callout], config.blocks.has("callout")),
      ...ArrayUtils.spreadIf([code], config.blocks.has("code")),
      ...ArrayUtils.spreadIf([image], config.blocks.has("image")),
      ...ArrayUtils.spreadIf([video], config.blocks.has("video")),
      ...ArrayUtils.spreadIf([poll], config.blocks.has("poll")),
      ...ArrayUtils.spreadIf([button], config.blocks.has("button")),
    ]

    return [
      { title: "Basic Blocks", blocks: basicBlocks.filter(filterBlockOption) },
      ...ArrayUtils.spreadIf(
        [{ title: "Attach", blocks: attachBlocks.filter(filterBlockOption) }],
        config.blocks.has("attachBlock") && isManager
      ),
      ...ArrayUtils.spreadIf(
        [{ title: "Inline", blocks: attachMentionInline.filter(filterBlockOption) }],
        config.blocks.has("attachBlock") && isManager
      ),
      ...ArrayUtils.spreadIf(
        {
          title: "AI Copilot",
          blocks: [
            ...ArrayUtils.spreadIf([aiGenerateImage], config.blocks.has("image")),
            ...ArrayUtils.spreadIf([aiGenerateText], config.blocks.has("paragraph")),
          ].filter(filterBlockOption),
        },
        config.blocks.has("ai")
      ),
      ...ArrayUtils.spreadIf(
        [{ title: "Embeds", blocks: embeds.filter(filterBlockOption) }],
        config.blocks.has("embed") && !isE2ETest() // embed options blocks will break e2e testcafe hammerhead when the block toolbox is opened
      ),
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, queryString])

  const onSelectOption = useCallback(
    (
      selectedOption: BlockToolboxOption,
      nodeToRemove: TextNode | null,
      closeMenu: () => void,
      matchingString: string
    ) => {
      editor.update(() => {
        if (nodeToRemove) nodeToRemove.remove()
        selectedOption.onSelect(matchingString)
        closeMenu()
      })
    },
    [editor]
  )

  const filteredOptions = options.flatMap((option) => option.blocks)
  const popoverRef = useRef<HTMLUListElement>(null)

  return (
    <>
      {setupEmbedKind && (
        <EditorEmbedSetupModal
          onClose={() => setSetupEmbedKind(null)}
          kind={setupEmbedKind}
          onSubmit={(data) => editor.dispatchCommand(INSERT_EMBED_COMMAND, data)}
        />
      )}
      <EditorImageUploadModal
        isOpen={isImageUploadOpen}
        onClose={onImageUploadClose}
        anchorElem={anchorElem}
      />
      <EditorImageUploadModal
        isOpen={isGenerateImageOpen}
        onClose={onGenerateImageClose}
        defaultTab={"ai-image"}
        anchorElem={anchorElem}
      />
      <EditorGenerateAITextPopover
        isOpen={isGenerateTextOpen}
        onClose={onGenerateTextClose}
        anchorElem={anchorElem}
      />
      <EditorVideoUploadModal
        isOpen={isVideoUploadOpen}
        onClose={onVideoUploadClose}
        anchorElem={anchorElem}
      />
      <EditorPollSetupModal isOpen={isPollSetupOpen} onClose={onPollSetupClose} />
      <AttachBlockSelectModal
        isOpen={isAttachBlockSelectOpen}
        onClose={onAttachBlockSelectClose}
        anchorElem={anchorElem}
        attachBlockEntity={attachBlockEntity}
        inline={attachBlockInline}
      />
      <EditorAddBlockButtons
        anchorRef={addBlockButtonsAnchorRef}
        onImageUploadOpen={onImageUploadOpen}
        onVideoUploadOpen={onVideoUploadOpen}
        onPollSetupOpen={onPollSetupOpen}
      />
      <LexicalTypeaheadMenuPlugin<BlockToolboxOption>
        onQueryChange={setQueryString}
        onSelectOption={onSelectOption}
        triggerFn={checkForTriggerMatch}
        anchorClassName={classes.typeaheadAnchor}
        options={filteredOptions}
        onClose={() => {
          setIsBlockListOpen(false)
          onMenuClose()
        }}
        onOpen={() => {
          onMenuOpen(popoverRef)
        }}
        menuRenderFn={(
          anchorElementRef,
          { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }
        ) => {
          if (!anchorElementRef.current) return null

          editor.update(() => {
            const selection = $getSelection()
            const nodes = selection?.getNodes() ?? []
            if (nodes[0]) setTargetNode(nodes[0])
          })

          return ReactDOM.createPortal(
            <ul
              ref={popoverRef}
              data-testid={"BlockToolboxPlugin.popover"}
              className={classNames(classes.popover, {
                [classes.popoverReposition]: raiseMenuHeight,
              })}
            >
              {options.map((group) => {
                if (group.blocks.length === 0) return null
                const isAICopilot = group.title === "AI Copilot"

                return (
                  <React.Fragment key={group.title}>
                    <li className={isAICopilot ? classes.flexContainer : ""}>
                      <span className={classes.magicWandIcon}>
                        {group.title === "AI Copilot" && <AIMagicWandIcon />}
                      </span>
                      <DiscoText
                        className={isAICopilot ? classes.aiCopilotText : ""}
                        color={"text.secondary"}
                        variant={"body-xs-500"}
                        marginLeft={1}
                      >
                        {group.title}
                      </DiscoText>
                      {isAICopilot && <AITag name={"BETA"} />}
                    </li>
                    {group.blocks.map((option) => {
                      const index = filteredOptions.indexOf(option)

                      return (
                        <BlockToolboxItem
                          key={option.key}
                          index={index}
                          option={option}
                          isSelected={selectedIndex === index}
                          onMouseEnter={() => setHighlightedIndex(index)}
                          onClick={() => {
                            setHighlightedIndex(index)
                            selectOptionAndCleanUp(option)
                          }}
                        />
                      )
                    })}
                  </React.Fragment>
                )
              })}
              {filteredOptions.length === 0 && (
                <DiscoText
                  variant={"body-sm-500"}
                  color={"text.secondary"}
                  margin={0.75}
                  marginLeft={3}
                >
                  {"No results"}
                </DiscoText>
              )}
            </ul>,
            anchorElementRef.current
          )
        }}
      />
    </>
  )

  function filterBlockOption(option: BlockToolboxOption) {
    if (!queryString) return true
    if (new RegExp(queryString, "gi").exec(option.title)) return true
    if (option.keywords === null) return false
    return option.keywords.some((keyword) => new RegExp(queryString, "gi").exec(keyword))
  }
}

const usePopoverStyles = makeUseStyles((theme) => ({
  typeaheadAnchor: {
    zIndex: 100,
  },
  popover: {
    width: "220px",
    maxHeight: "250px",
    overflowY: "scroll",
    overflowX: "hidden",
    border: `1px solid ${theme.palette.groovy.neutral[300]}`,
    background: theme.palette.background.paper,
    boxShadow: theme.palette.groovyDepths.raisedBoxShadow,
    borderRadius: theme.measure.borderRadius.big,
    padding: theme.spacing(0.5, 0),
    marginTop: theme.spacing(3.5),
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  popoverReposition: {
    bottom: `24px !important`,
    position: "absolute",
  },
  aiCopilotText: {
    background: theme.palette.aiGradient.aiDark,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    paddingLeft: 0,
    marginRight: theme.spacing(1),
    marginLeft: 0,
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  magicWandIcon: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}))

export default BlockToolboxPlugin
