import {
  $createParagraphNode,
  ElementNode,
  SerializedElementNode,
  Spread,
  type EditorConfig,
  type LexicalNode,
  type NodeKey,
  type RangeSelection,
} from "lexical"

export enum CalloutNodeColor {
  grey = "grey",
  orange = "orange",
  yellow = "yellow",
  green = "green",
  cyan = "cyan",
  blue = "blue",
  purple = "purple",
  pink = "pink",
  red = "red",
}

export type SerializedCalloutNode = Spread<
  {
    color: CalloutNodeColor
  },
  SerializedElementNode
>

export class CalloutNode extends ElementNode {
  __color: CalloutNodeColor

  constructor(color?: CalloutNodeColor, key?: NodeKey) {
    super(key)
    this.__color = color ?? CalloutNodeColor.blue
  }

  static getType(): string {
    return "callout"
  }

  static clone(node: CalloutNode): CalloutNode {
    return new CalloutNode(node.__color, node.__key)
  }

  createDOM(config: EditorConfig): HTMLElement {
    const element = document.createElement("p")

    if (config.theme.callout) {
      element.classList.add(config.theme.callout)
    }

    element.setAttribute("data-callout-color", this.__color)

    return element
  }

  updateDOM(): boolean {
    return true
  }

  setColor(color: CalloutNodeColor): void {
    this.getWritable().__color = color
  }

  getColor(): CalloutNodeColor {
    return this.getLatest().__color
  }

  insertNewAfter(
    _selection: RangeSelection,
    restoreSelection?: boolean | undefined
  ): LexicalNode | null {
    const newBlock = $createParagraphNode()
    const direction = this.getDirection()
    newBlock.setDirection(direction)
    this.insertAfter(newBlock, restoreSelection)
    return newBlock
  }

  collapseAtStart(): boolean {
    const paragraph = $createParagraphNode()
    const children = this.getChildren()
    children.forEach((child) => paragraph.append(child))
    this.replace(paragraph)
    return true
  }

  static importJSON(serializedNode: SerializedCalloutNode): CalloutNode {
    const node = $createCalloutNode(serializedNode.color)
    node.setFormat(serializedNode.format)
    node.setIndent(serializedNode.indent)
    node.setDirection(serializedNode.direction)
    return node
  }

  exportJSON(): SerializedCalloutNode {
    return {
      ...super.exportJSON(),
      type: "callout",
      color: this.__color,
    }
  }
}

export function $createCalloutNode(color?: CalloutNodeColor): CalloutNode {
  return new CalloutNode(color)
}

export function $isCalloutNode(node: LexicalNode): node is CalloutNode {
  return node instanceof CalloutNode
}
