import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  CalloutNode,
  CalloutNodeColor,
} from "@components/editor/plugins/callout/CalloutNode"
import { DiscoDropdown, DiscoIcon } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { capitalize } from "lodash"

interface CalloutBlockOptionsProps extends TestIDProps {
  calloutNode: CalloutNode
  classes: { popover: string }
  onClose: () => void
}

function CalloutBlockOptions({
  calloutNode,
  classes: propClasses,
  onClose,
}: CalloutBlockOptionsProps) {
  const [editor] = useLexicalComposerContext()

  const classes = useStyles()
  const theme = useTheme()

  return (
    <DiscoDropdown
      vertical={"top"}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      menuClasses={{ paper: propClasses.popover }}
      menuButton={(props) => (
        <DiscoDropdownItem
          title={"Select Color"}
          icon={"palette"}
          rightButton={<DiscoIcon className={classes.chevron} icon={"chevron"} />}
          {...props}
        />
      )}
    >
      {Object.values(CalloutNodeColor).map((color) => (
        <DiscoDropdownItem
          key={color}
          title={capitalize(color)}
          onClick={() => handleColorChange(color)}
          classes={{ icon: classes.icon }}
          icon={
            <div
              style={{ backgroundColor: getBackgroundColor(color) }}
              className={classes.colorIcon}
            />
          }
        />
      ))}
    </DiscoDropdown>
  )

  function handleColorChange(color: CalloutNodeColor) {
    editor.update(() => {
      calloutNode.setColor(color)
    })
    onClose()
  }

  function getBackgroundColor(color: CalloutNodeColor) {
    const isDarkMode = theme.palette.type === "dark"

    switch (color) {
      case CalloutNodeColor.grey:
        return theme.palette.groovy.neutral[isDarkMode ? 300 : 200]
      default:
        return theme.palette.groovy[color][isDarkMode ? 700 : 200]
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  icon: {
    margin: 0,
  },
  colorIcon: {
    height: "20px",
    width: "20px",
    borderRadius: theme.measure.borderRadius.default,
  },
  chevron: {
    transform: "rotate(90deg)",
    marginLeft: theme.spacing(3),
  },
}))

export default CalloutBlockOptions
