import {
  $createAttachBlockNode,
  AttachBlockNode,
  AttachBlockPayload,
} from "@components/editor/plugins/attach-block/AttachBlockNode"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $insertNodeToNearestRoot } from "@lexical/utils"
import { COMMAND_PRIORITY_EDITOR, LexicalCommand, NodeKey, createCommand } from "lexical"
import { useEffect } from "react"

export type InsertAttachBlockPayload = Readonly<AttachBlockPayload>

export const INSERT_ATTACH_BLOCK_COMMAND: LexicalCommand<InsertAttachBlockPayload> =
  createCommand("INSERT_ATTACH_BLOCK_COMMAND")

export type UpdateAttachBlockPayload = Readonly<{
  key: NodeKey
}>

export default function AttachBlockPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (!editor.hasNodes([AttachBlockNode])) {
      throw new Error("AttachBlockPlugin: AttachBlockNode not registered on editor")
    }

    return editor.registerCommand<InsertAttachBlockPayload>(
      INSERT_ATTACH_BLOCK_COMMAND,
      (payload) => {
        const attachBlockNode = $createAttachBlockNode(payload)
        $insertNodeToNearestRoot(attachBlockNode)
        return true
      },
      COMMAND_PRIORITY_EDITOR
    )
  }, [editor])

  return null
}
