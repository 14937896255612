import Badge from "@/admin/experiences/badges/Badge"
import { useLabel } from "@/core/context/LabelsContext"
import { useUnsavedChangesModalContext } from "@/core/context/UnsavedChangesModalProvider"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import DefaultThumbnail from "@/core/ui/images/public-pages/default-thumbnail.png"
import useIsWebView from "@/product/util/hook/useIsWebView"
import BoxIcon from "@/core/ui/iconsax/linear/box-1.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import CoverPhoto from "@components/cover-photo/CoverPhoto"
import { AttachBlockConfig } from "@components/editor/plugins/attach-block/AttachBlockNode"
import {
  MissingAccessAttachBlock,
  PlaceholderAttachBlock,
} from "@components/editor/plugins/attach-block/EditorAttachBlock"
import { EditorProductAttachBlockQuery } from "@components/editor/plugins/attach-block/types/product/__generated__/EditorProductAttachBlockQuery.graphql"
import { DiscoButton, DiscoChip, DiscoIcon, DiscoText, DiscoTooltip } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import useActiveProductOrOrganizationPermissions from "@utils/hook/useActiveProductOrOrganizationPermissions"
import classNames from "classnames"
import { graphql, useLazyLoadQuery } from "react-relay"
import { generatePath, useHistory } from "react-router-dom"

const EditorProductAttachBlock: AttachBlockConfig = {
  title: "Product",
  icon: <BoxIcon height={18} width={18} />,
  keywords: ["product", "experience", "link", "community"],

  Component({ entityId, onClose }) {
    const classes = useStyles()
    const { handleLeave } = useUnsavedChangesModalContext()
    const permissions = useActiveProductOrOrganizationPermissions()
    const testid = "attach-block-editor.product"
    const experienceLabel = useLabel("admin_experience")
    const membersLabel = useLabel("admin_member")
    const history = useHistory()
    const isMobile = useIsMobile()
    const isWebView = useIsWebView()

    const { product } = useLazyLoadQuery<EditorProductAttachBlockQuery>(
      graphql`
        query EditorProductAttachBlockQuery($id: ID!) {
          product: node(id: $id) {
            id
            ... on Product {
              id
              viewerMembership {
                id
              }
              name
              endsAt
              type
              slug
              status
              badge {
                ...BadgeFragment
              }
              cover
            }
          }
        }
      `,
      { id: entityId }
    )

    const isProductInDraftMode = product?.status === "draft"

    if (isProductInDraftMode && !permissions.has("content.manage")) {
      return <MissingAccessAttachBlock attachBlockEntity={"product"} />
    }

    if (!product) {
      return <PlaceholderAttachBlock attachBlockEntity={"product"} />
    }

    const userIsPartOfProduct = !!product.viewerMembership

    function getCover() {
      if (!product) return
      switch (product.type) {
        case "course":
          return <Badge badgeKey={product.badge!} size={48} />
        default:
          return (
            <CoverPhoto
              coverPhoto={product.cover || DefaultThumbnail}
              customClassName={classes.cover}
            />
          )
      }
    }

    return (
      <div className={classes.root}>
        <DiscoContainerButton
          onClick={(e) => handleClick(e)}
          testid={`${testid}.${product.name}`}
          className={classes.attachBlockLink}
        >
          <div className={classes.infoWrapper}>
            <div
              className={classNames(classes.iconWrapper, {
                [classes.membershipPlanWrapper]: product.type !== "course",
              })}
            >
              {getCover()}
            </div>
            <div className={classes.details}>
              <div className={classes.header}>
                <DiscoText
                  truncateText={1}
                  variant={"body-xs-600-uppercase"}
                  color={"primary.main"}
                  className={classes.headerTitle}
                >
                  {`${experienceLabel.singular}`}
                </DiscoText>

                {isProductInDraftMode && (
                  <DiscoTooltip
                    content={`This ${experienceLabel.singular} is currently in draft mode and not visible to ${membersLabel.plural}`}
                  >
                    <div data-testid={`${testid}.${product.name}.draftButton`}>
                      <DiscoChip
                        leftIcon={<DiscoIcon icon={"eye-off"} height={16} width={16} />}
                        color={"yellow"}
                        label={"Draft"}
                      />
                    </div>
                  </DiscoTooltip>
                )}
              </div>
              <DiscoText
                testid={`${testid}.${product.name}.text`}
                truncateText={1}
                variant={"body-lg-600"}
              >
                {product.name}
              </DiscoText>
            </div>
          </div>

          <div className={classes.actionButtonWrapper}>
            <DiscoButton
              data-testid={`${testid}.${product.name}.CTAButton`}
              color={"grey"}
              variant={"outlined"}
              onClick={handleClick}
              width={isMobile || isWebView ? "100%" : undefined}
            >
              {userIsPartOfProduct ? `View ${experienceLabel.singular}` : "Register"}
            </DiscoButton>
          </div>
        </DiscoContainerButton>
      </div>
    )

    function handleClick(e: React.MouseEvent<Element, MouseEvent>) {
      e.stopPropagation()
      if (onClose) onClose()
      handleLeave({
        onLeave: () => {
          if (!product?.slug) return

          if (userIsPartOfProduct) {
            history.push(
              generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
                productSlug: product.slug,
              })
            )
          } else {
            history.push(
              generatePath(ROUTE_NAMES.PRODUCT.REGISTRATION.ROOT, {
                productSlug: product.slug,
              })
            )
          }
        },
      })
    }
  },
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.big,
    border: theme.palette.constants.borderSmallDarkOnly,
    position: "relative",
    margin: theme.spacing(2, 0.5, 2, 0.5),
    boxShadow: theme.palette.groovyDepths.insideCard,
    "&:hover": {
      boxShadow: theme.palette.groovyDepths.boxShadow,
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  headerTitle: {
    marginRight: theme.spacing(1),
  },
  attachBlockLink: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    height: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "0%",
    overflow: "hidden",
  },
  iconWrapper: {
    width: "48px",
    height: "48px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: theme.measure.borderRadius.big,
    backgroundColor: theme.palette.groovy.neutral[200],
    margin: theme.spacing(2, 0, 2, 2),
  },
  membershipPlanWrapper: {
    width: 100,
  },
  details: {
    padding: theme.spacing(2),
    textAlign: "start",
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    maxWidth: "100%",
    textOverflow: "ellipsis",
  },
  cover: {
    borderRadius: theme.measure.borderRadius.default,
  },
  actionButtonWrapper: {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flexShrink: 0,
    flexBasis: "auto",
  },
}))
export default EditorProductAttachBlock
